var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "supplier_info mb50" },
    [
      _c("h1", { staticClass: "title" }, [_vm._v(" 关联供应商 ")]),
      _c(
        "el-card",
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              staticClass: "demo-ruleForm",
              attrs: {
                model: _vm.ruleForm,
                rules: _vm.rules,
                "label-width": "110px",
              },
            },
            [
              _vm.people === "gys"
                ? _c(
                    "el-form-item",
                    { attrs: { label: "供应商货品：", prop: "radio" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          attrs: {
                            disabled: _vm.disabled || _vm.supply.boolean,
                          },
                          on: {
                            change: function ($event) {
                              _vm.ruleForm.name = ""
                            },
                          },
                          model: {
                            value: _vm.ruleForm.radio,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "radio", $$v)
                            },
                            expression: "ruleForm.radio",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: 0 } }, [
                            _vm._v(" 否 "),
                          ]),
                          _c("el-radio", { attrs: { label: 1 } }, [
                            _vm._v(" 是 "),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "所属供应商：",
                    prop: "name",
                    rules: [
                      {
                        required: _vm.ruleForm.radio === 1,
                        message: "请输入供应商名称",
                        trigger: "change",
                      },
                    ],
                  },
                },
                [
                  _c("el-input", {
                    staticClass: "width360",
                    attrs: {
                      size: "mini",
                      maxlength: "30",
                      "show-word-limit": "",
                      placeholder: "请输入所属供应商",
                      readonly:
                        _vm.disabled ||
                        _vm.people === "admin" ||
                        _vm.supply.boolean,
                    },
                    model: {
                      value: _vm.ruleForm.name,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.ruleForm,
                          "name",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "ruleForm.name",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }