<template>
  <el-dialog
    title="审核商品"
    :visible.sync="showAudit"
    width="700px"
    :before-close="handleClose"
    @open="openFun"
  >
    <div>
      <el-form
        label-width="110px"
        class="demo-ruleForm"
      >
        <el-form-item label="商品标题：">
          <div>{{ goodsName }}</div>
        </el-form-item>
        <el-form-item
          required
          label="审核结果："
        >
          <el-radio-group v-model="auditType">
            <el-radio :label="1">
              通过
            </el-radio>
            <el-radio :label="0">
              不通过
            </el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          v-if="auditType === 1"
          label="平台标签："
        >
          <el-checkbox-group v-model="label">
            <el-checkbox
              v-for="(item, i) in labelList"
              :key="i"
              :label="item"
            >
              {{ item.tagName }}
            </el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item
          v-if="auditType === 1"
          label="选择品牌："
        >
          <el-select
            v-model="brand"
            placeholder="请选择"
            size="mini"
            clearable
          >
            <el-option
              v-for="(item, i) in brandList"
              :key="i"
              :label="item.brandName"
              :value="item.brandId"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          v-if="auditType === 0"
          required
          label="不通过原因："
        >
          <el-input
            v-model.trim="refuse"
            class="width360"
            size="mini"
            type="textarea"
            show-word-limit
            maxlength="200"
            placeholder="请输入审核不通过原因，原因包含不通过的项目以及修改建议。"
            :autosize="{minRows: 4, maxRows: 8}"
          ></el-input>
        </el-form-item>
      </el-form>
    </div>
    <span
      slot="footer"
      class="dialog-footer"
    >
      <el-button
        size="small"
        @click="handleClose"
      >取 消</el-button>
      <el-button
        type="primary"
        size="small"
        @click="submitAudit"
      >提 交</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  props: ['showAudit', 'name'],
  data() {
    return {
      auditType: 1,
      label: [],
      labelList: [],
      brand: null,
      brandList: [],
      refuse: '',
      goodsName: ''
    }
  },
  watch: {
    name(data) {
      this.goodsName = data
    }
  },
  methods: {
    submitAudit() {
      if (this.auditType === 0 && !refuse) {
        this.$message({ type: 'warning', message: '请填写拒绝理由' })
        return
      }
      this.$confirm('是否确认提交?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.$axios({
            method: 'post',
            url: this.$api.goodsmanage.trade_goods_audit,
            data: {
              brandId: this.brand,
              detail: this.refuse,
              id: this.$route.query.goodsId ? this.$route.query.goodsId : null,
              name: this.goodsName,
              status: this.auditType,
              tags: this.label
            }
          }).then((res) => {
            if (res.code === 0) {
              this.$message({ type: 'success', message: '操作成功！' })
              this.$router.push({
                path: '/nb/goods/index'
              })
            }
          })
        })
        .catch(() => {})
    },
    handleClose() {
      this.$emit('closeModel')
    },
    openFun() {
      this.$axios({
        method: 'post',
        url: this.$api.Tagcontroller.Tagcontroller_SearchTagList,
        data: {
          tag_scene: 5,
          tag_type: 3,
          tag_state: 1
        }
      }).then((res) => {
        if (res.code === 0) {
          this.labelList = res.data
        }
      })
      this.$axios({
        method: 'post',
        url: this.$api.BrandController.SearchBrand,
        data: {
          page_index: 1,
          page_size: 50,
          status: 1
        }
      }).then((res) => {
        if (res.code === 0) {
          this.brandList = res.data.records
        }
      })
    }
  }
}
</script>

<style>
</style>
