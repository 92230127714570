<template>
  <div
    v-loading="loading"
    class="sku"
  >
    <h1 class="title">
      {{ goodsType == '2'? '' : '设置商品规格' }}
      <el-link
        v-if="goodsType !='2'"
        class="margin_right_10"
        type="primary"
        @click="showBigSkuImg"
      >
        点击查看示例图
      </el-link>
      <el-image
        id="bigSkuImg"
        ref="bigSkuImg"
        style="width: 0px; height: 0px"
        :src="goodsType=='2'?skuImage[1]:skuImage[0]"
        :preview-src-list="[goodsType=='2'?skuImage[1]:skuImage[0]]"
      >
      </el-image>
    </h1>

    <el-card
      v-if="!disabled && !isAssembly && isShow"
      class="box-card"
    >
      <div
        v-for="(item, i) in skuData"
        :key="i"
      >
        <div class="sku-header">
          <span class="margin_right_30">规格 {{ i + 1 }} :</span>
          <span v-if="!item.name" style="color: red;">请选择规格项</span>
          <span class="margin_right_30" style="font-weight: bold;">{{ item.name }}</span>
          <el-button
            class="margin_right_30"
            type="primary"
            size="mini"
            @click="showAddSkuGroup(i)"
          >
            选择规格项
          </el-button>
          <el-button
            class="margin_right_30"
            type="danger"
            size="mini"
            :disabled="skuData.length <= 1"
            @click="deleteSku(i,item)"
          >
            删除规格
          </el-button>
        </div>

        <div class="sku-content"
          v-if="item.id"
        >
          <span style="font-size: 14px;">规格值：</span>
          <span v-if="!item.tradeSpecValueDtos" style="color: red;font-size: 14px;">请设置规格值</span>
          <el-tag
            style="margin-right: 10px"
            v-for="(value, index) in item.tradeSpecValueDtos"
            size="medium"
            :key="index"
            closable
            @close="onCloseVal(item, index, i)"
          >
            {{value.name}}
          </el-tag>
            <!-- 单位 {{item.specDetail && item.specDetail.tradeUnitDto ? item.specDetail.tradeUnitDto.unitName : ''}} -->
          <div>
            <el-button
              class="margin_left_10"
              type="primary"
              size="mini"
              @click="addSkuVal(i, item)"
            >
              设置规格值
            </el-button>
          </div>
        </div>

        <el-divider></el-divider>
      </div>
      <el-button
        :disabled="skuData.length >= 2"
        style="width: 100%"
        size="small"
        type="primary"
        plain
        @click="addSku"
      >
        +新增规格
      </el-button>
    </el-card>
    <el-card class="box-card box-card-tabl">
      <el-button
        v-if="!disabled && isAssembly"
        class="margin_bottom_10"
        size="small"
        type="primary"
        @click="showAssSku = true">
        新增组合规格
      </el-button>
      <el-button
        v-if="!disabled && skuTable.filter( item=> item.isDelete != 1).length > 0"
        class="margin_bottom_10"
        size="small"
        type="primary"
        @click="setBathSkuList">
        批量设置
      </el-button>
      <el-table
        ref="skuTable"
        :data="skuTable.filter( item=> item.isDelete != 1)"
        border
        size="mini"
        row-key="specValue"
      >
        <el-table-column
          type="selection"
          reserve-selection
          key="selection"
          width="55"/>
        <el-table-column
          prop="specValue"
          label="规格"
          key="specValue"
        />
        <el-table-column
          v-if="isSupplyGoods()"
          label="订货价（元）"
          :render-header="renderHeader"
          key="supplyPrice"
        >
          <template slot-scope="scope">
            <el-input
              v-model.trim="scope.row.supplyPrice"
              :readonly="disabled"
              size="mini"
              @input="onChangeNum(scope.row, 'supplyPrice')"
              @blur="blurTable(scope.row)"
            ></el-input>
          </template>
        </el-table-column>
        <el-table-column
          v-if="isShowMarketPrice()"
          label="市场价（元）"
          :render-header="renderHeader"
          key="marketPrice"
        >
          <template slot-scope="scope">
            <el-input
              v-model.trim="scope.row.marketPrice"
              :readonly="disabled"
              size="mini"
              @input="onChangeNum(scope.row, 'marketPrice')"
              @blur="blurTable(scope.row)"
            ></el-input>
          </template>
        </el-table-column>
        <el-table-column
          v-if="isShowSellPrice()"
          label="销售价（元）"
          :render-header="renderHeader"
          key="sellPrice"
        >
          <template slot-scope="scope">
            <el-input
              v-model.trim="scope.row.sellPrice"
              :readonly="disabled"
              size="mini"
              @input="onChangeNum(scope.row, 'sellPrice')"
              @blur="blurTable(scope.row)"
            ></el-input>
          </template>
        </el-table-column>
        <el-table-column
          v-if="isShowMinBuy()"
          label="起购量（件）"
          :render-header="renderHeader"
          key="minBuy"
        >
          <template slot-scope="scope">
            <el-input
              v-model.trim="scope.row.minBuy"
              :readonly="disabled"
              :disabled="isDisabledMinBuy()"
              size="mini"
              @input="onChangeNum(scope.row, 'minBuy')"
              @blur="blurTable(scope.row)"
            ></el-input>
          </template>
        </el-table-column>
        <el-table-column
          label="库存（件）"
          :render-header="renderHeader"
          key="stockQuantity"
        >
          <template slot-scope="scope">
            <el-input
              v-model.trim="scope.row.stockQuantity"
              :readonly="disabled"
              size="mini"
              maxlength="6"
              @input="onChangeNum(scope.row, 'stockQuantity')"
              @blur="blurTable(scope.row)"
            ></el-input>
          </template>
        </el-table-column>
        <el-table-column
          label="预警库存（件）"
          :render-header="renderHeader"
          key="stockQuantityWarning"
        >
          <template slot-scope="scope">
            <el-input
              v-model.trim="scope.row.stockQuantityWarning"
              :readonly="disabled"
              size="mini"
              maxlength="6"
              @input="onChangeNum(scope.row, 'stockQuantityWarning')"
              @blur="blurTable(scope.row)"
            ></el-input>
          </template>
        </el-table-column>
        <el-table-column
          v-if="isShowSkuImage()"
          label="规格图"
          :render-header="renderHeader"
          key="imageList"
        >
          <template slot-scope="scope">
            <CutImgUp
              ref="cutImgUp"
              :disabled="disabled"
              :url="scope.row.imageList"
              :auto-crop-width="2000"
              :auto-crop-height="2000"
              :fixed="true"
              :max-num="1"
              @change="
                (e) => {
                  changeAvatar(e, scope.row);
                }
              "
            ></CutImgUp>
          </template>
        </el-table-column>
        <el-table-column
          v-if="+goodsType !== 8"
          label="规格编号"
          key="skuNo"
        >
          <template slot-scope="scope">
            <el-input
              v-model="scope.row.skuNo"
              :readonly="disabled"
              size="mini"
              @input="scope.row.skuNo=scope.row.skuNo.replace(/[\W]/g,'')"
            ></el-input>
          </template>
        </el-table-column>
        <el-table-column
          v-if="+goodsType === 8 && pageType !== 'add'"
          label="权益"
          key="equity"
        >
          <template slot-scope="{row}">
            <el-link :type="'row.showspec' ? 'primary' : 'danger'" :underline="false" @click="toShowEquity(row)">
              {{ 'row.showspec' ? '查看' : '未设置'}}
            </el-link>
          </template>
        </el-table-column>
        <el-table-column
          label="操作"
          key="option"
          v-if="isAssembly && !disabled"
        >
          <template slot-scope="{row}">
            <el-button type="text" size="mini" @click="operation(row, 'edit')">编辑</el-button>
            <el-button type="text" size="mini" @click="operation(row, 'delete')">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-card>

    <!-- 选择规格项 -->
    <AddSkuGroup
      :dialogVisible.sync="showSkuGroup"
      :specData.sync="skuData[setSkuGroupIndex]"
      :skuData="skuData"
      />
    <!-- 选择规格 -->
    <AddSkuValue
      ref="skuValRef"
      :dialogVisible.sync="showSkuValue"
      :id="setSkuGroupId"
      :value.sync="skuData[setSkuGroupIndex].tradeSpecValueDtos"
      />
    <!-- 批量设置sku弹窗 -->
    <SetAllSku
      :dialogVisible.sync="showSetAllSku"
      :skuTable="skuTable"
      :bathSetList="bathSetList"
      :isShowMarketPrice="isShowMarketPrice()"
      :isShowSellPrice="isShowSellPrice()"
      :isShowMinBuy="isShowMinBuy() && !isDisabledMinBuy()"
     />
    <!-- 组合规格弹窗 -->
    <AddSkuList :dialogVisible.sync="showAssSku" :assSpecId="assSpecId" :specValueList.sync="skuData[0].tradeSpecValueDtos" :specValueId.sync="specValueId" @changeGoods="clearTag" />
    <!-- 查看权益弹窗 -->
    <EquityListDialog
      :show="equityListShow"
      sku-type="show"
      :sku-id="skuId"
      @hide="equityListShow = false"
    />
  </div>
</template>

<script>
import CutImgUp from '@/components/common/cutImgUp'
import AddSkuGroup from './components/sku/AddSkuGroup.vue'
import AddSkuValue from './components/sku/AddSkuValue'
import SetAllSku from './components/sku/SetAllSku'
import AddSkuList from './components/sku/AddSkuList';
import EquityListDialog from '../../equityCardSet/EquityListDialog';
import { projectConfigDetail } from '@/utils/projectConfig';

export default {
  name: 'skuInfoEdit',
  components: {
    CutImgUp,
    AddSkuGroup,
    AddSkuValue,
    SetAllSku,
    AddSkuList,
    EquityListDialog
  },
  props: {
    projectCode: {
      type: Number,
      default: 3300
    },
    disabled: {
      type: Boolean,
      default: false
    },
    tradeSpecDtos: {
      type: Array,
      default: () => []
    },
    tradeGoodsSkuDtos: {
      type: Array,
      default: () => []
    },
    tradeServiceTagDtos: {
      type: Array,
      default: () => []
    },
    supply: {
      type: Object,
      default: () => ({})
    },
    // 是否组合商品
    isAssembly: {
      type: Boolean,
      default: false
    },
    // 是否非卖品
    isNotSale: {
      type: Boolean,
      default: false
    }
  },
  data() {
    this.userInfo = JSON.parse(localStorage.getItem('userInfo') || '{}');
    return {
      query: {},
      dialogVisible: false,
      goodsId: '',
      loading: false,
      skuData: [
        {
          id: '',
          name: '',
          tradeSpecValueDtos: [{ name: '' }]
        },
      ],
      defaultSku: [],
      skuTable: [],
      skuTableData: {},
      pageType: 'edit',
      goodsType: 1,
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 1000 * 60 * 60 * 24
        }
      },
      dialogVisible: false,
      allDays: [],

      showSkuGroup: false,//添加规格组弹窗
      showSkuValue: false,//添加规格值弹窗
      setSkuGroupId: null,//选择设置规格值id
      setSkuGroupIndex: 0,//选择设置规格组index
      showSetAllSku: false,//批量设置窗口
      bathSetList:[], //批量设置选中的数据
      showAssSku: false,//组合规格弹窗

      skuImage: [
        'https://wsnbh-img.hzanchu.com/acimg/8c6d0818947f055d331adde27c316652.jpeg',
        'https://wsnbh-img.hzanchu.com/test/52070571377262592.jpg'
      ],
      specValueId: '',
      isFirst: true,//是否首次编辑sku列表

      assSpecId: projectConfigDetail.assSpecId,

      equityListShow: false, //展示权益弹窗
      skuId: 0,

    }
  },
  computed: {
    isShow() {
      if (this.supply.boolean) {
        if (this.userInfo?.isMemberStore) return true;
        else return false;
      }
      return true;
    }
  },
  watch: {
    skuData: {
      async handler(val) {
        console.log(val,'监听数据skuData============')
        this.setSkuGroupIndex = 0;
        //重复停止
        if(val[1] && val[1].name === val[0].name) {
          this.skuData.splice(1, 1);
          this.$message.error('规格组不能重复');
          return
        }
        //获取数据
        for(let item of val) {
          if(item.id && !item.specDetail) {
            //获取单位
            let detail = await this.getSpecValueDetailApi(item.id);
            item.specDetail = detail;
          }
        }
        //名称不完整停止
        // for(let item of val) {
        //   if(!item.tradeSpecValueDtos || !item.tradeSpecValueDtos[0] || !item.tradeSpecValueDtos[0].name) {
        //     return
        //   }
        // }
        this.setTable();
      },
      deep: true
    },
    tradeSpecDtos: {
      handler(val) {
        if(val && val.length > 0) {
          this.skuData = val;
        }
      },
      deep: true
    },
    tradeGoodsSkuDtos(data) {
      if (!data) {
        // this.setTable();
        return
      }
      this.defaultSku = []
      data && data.forEach(item => {
        this.defaultSku.push(Object.freeze({ ...item }))
      })
      data && data.forEach((item, index) => {
        this.$set(this.skuTableData, item.specValue, item)
        this.$set(item, 'imageList', item.imageIds ? item.imageIds.split(',') : [])
        this.$set(item, 'marketPrice', item.marketPrice == 0 ? '' : item.marketPrice)
        if(item.assemblySkuList) {
          this.$set(this.skuData[0].tradeSpecValueDtos[index], 'assemblySkuList', item.assemblySkuList)
        }
      })
      this.skuTable = data;
    },
  },
  created() {
  },
  mounted() {
    this.query = this.$route.query;
    this.pageType = this.$route.query.type;
    this.goodsType = this.$route.query.goodsType;
    this.goodsId = this.$route.query.goodsId;
    if(this.isAssembly && this.pageType == 'add') {
      this.skuData = [
        {id: this.assSpecId, name: '组合规格'}
      ]
    }
    //默认选中第一个规格项
    if(!this.isAssembly && this.pageType == 'add') {
      this.$axios.post(this.$api.goods_spec.page, {specName: "", backendCategoryId: this.query.backendCategoryId}).then((res) => {
        if (res.code === 0 && res.data.records.length > 0) {
          this.skuData = [res.data.records[0]];
        }
      })
    }
  },
  methods: {
    //获取规格项详情
    getSpecValueDetailApi(id) {
      return new Promise((resolve, reject)=> {
        this.$axios
        .get(this.$api.goods_spec.detail, {
          params: {specId: id}
        })
        .then((res) => {
          if (res.code === 0) {
            resolve({
              ...res.data,
              tradeBackendCategoryDtoList: undefined,
            })
          }
          this.loading = false
        })
      })
    },
    renderHeader(h, { column, $index }) {
      return (
        <div>
          {
            column.label !== '市场价（元）' && <span style="color: red;margin-right: 5px">*</span>
          }
          <span>{column.label}</span>
          {
            column.label === '订货价（元）' ? <el-tooltip class="item" effect="dark" content="订货价为向供应商实际订货的价格，如需修改请认真填写" placement="top">
              <i class="el-icon-warning-outline" style="color: #1890ff"></i>
            </el-tooltip> : null
          }
          {
            column.label === '市场价（元）' ? <el-tooltip class="item" effect="dark" content="市场价未来会用于营销建议，利润分析等" placement="top">
              <i class="el-icon-warning-outline" style="color: #1890ff"></i>
            </el-tooltip> : null
          }
          {
            column.label === '销售价（元）' ? <el-tooltip class="item" effect="dark" content="销售价为商品正常销售时的价格，可以编辑修改，修改后该商品将重新进行审核" placement="top">
              <i class="el-icon-warning-outline" style="color: #1890ff"></i>
            </el-tooltip> : null
          }
          {
            column.label === '起购量（件）' ? <el-tooltip class="item" effect="dark" content="商品最低购买件数" placement="top">
              <i class="el-icon-warning-outline" style="color: #1890ff"></i>
            </el-tooltip> : null
          }
          {
            column.label === '库存（件）' ? <el-tooltip class="item" effect="dark" content="该库存表示此规格在系统中实时的可售卖库存数量，可以直接编辑修改，修改保存成功，系统中的库存数量会更新为最新库存值" placement="top">
              <i class="el-icon-warning-outline" style="color: #1890ff"></i>
            </el-tooltip> : null
          }
        </div>
      )
    },
    setTable() {
      let newval = [...this.skuData];
      let newArray = [];
      if (newval.length === 1) {
        newval[0].tradeSpecValueDtos && newval[0].tradeSpecValueDtos.forEach((value, index) => {
          if(!value.name) return
          // if (value.isDelete != 1) {
            if (value.id) {
              let findSku = this.defaultSku.find(find => find.specKey == value.id)
              // console.log(findSku,this.defaultSku, '=================')
              newArray.push({
                minBuy: this.isDisabledMinBuy() ? 1 : '',
                ...findSku,
                specValue: value.name,
                isDelete: 0,
                goodsId: this.$route.query.goodsId ? this.$route.query.goodsId : '',
                assemblySkuList: value.assemblySkuList, //加入组合规格数据
                specKey: value.id,
              });
            } else {
              newArray.push({
                minBuy: this.isDisabledMinBuy() ? 1 : '',
                specValue: value.name,
                goodsId: this.$route.query.goodsId ? this.$route.query.goodsId : '',
                isDelete: 0,
                assemblySkuList: value.assemblySkuList, //加入组合规格数据
                id: value.id ? value.id : '',//组合商品插入id
                specKey: value.id,
              });
            }
          // }
        });
      } else if (newval.length === 2) {
        newval[0].tradeSpecValueDtos && newval[0].tradeSpecValueDtos.forEach((value, index) => {
          newval[1].tradeSpecValueDtos.forEach((item, i) => {
            // if (value.isDelete !== 1 && item.isDelete !== 1) {
              if(!value.name || !item.name) return
              if (value.id && item.id) {
                let findSku = this.defaultSku.find(find => find.specKey == value.id + '-' + item.id)
                newArray.push({
                  minBuy: this.isDisabledMinBuy() ? 1 : '',
                  ...findSku,
                  specValue: value.name + '-' + item.name,
                  isDelete: 0,
                  goodsId: this.$route.query.goodsId ? this.$route.query.goodsId : ''
                });
              } else {
                newArray.push({
                  minBuy: this.isDisabledMinBuy() ? 1 : '',
                  specValue: value.name + '-' + item.name,
                  goodsId: this.$route.query.goodsId ? this.$route.query.goodsId : '',
                  isDelete: 0
                });
              }
            // }
          });
        });
      }

      this.skuTable = newArray;
      // this.defaultSku.forEach((item, index) => {
      //   let findId = this.skuTable.find(find => find.id == item.id);
      //   if (!findId) {
      //     this.skuTable.push({ ...item, isDelete: 1 })
      //   }
      // })

    },

    //如果是组合商品，设置规格值后需要清除选择的标签数据
    clearTag() {
      if(this.isAssembly && this.tradeServiceTagDtos && this.tradeServiceTagDtos.length > 0) {
        this.$emit('update:tradeServiceTagDtos', []);
        this.$message.warning('规格已更新，请重选标签。')
      }
    },
    deleteSku(i, item) {
      this.$confirm('删除后需要重新填写所有规格的规格值数据，请谨慎操作，是否确认删除此条规格吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async() => {
          this.skuData.splice(i, 1)
          this.setTable();
        })
        .catch(() => {})
    },
    showAddSkuGroup(i) {
      this.setSkuGroupIndex = i;
      this.showSkuGroup = true;
    },
    addSku(i) {
      this.skuData.push({
        id: '',
        name: '',
        goodsId: this.goodsId,
        tradeSpecValueDtos: [{ name: '', goodsId: this.goodsId }]
      })
    },
    addSkuVal(i, row) {
      this.setSkuGroupId = Number(row.id);
      this.setSkuGroupIndex = i;
      this.showSkuValue = true;
    },

    getForm() {
      return new Promise((resolve) => {
        for (let item of this.skuData) {
          if (!item.name) {
            this.$message({
              type: 'error',
              message: '请完善规格名称（设置商品规格）'
            })
            return
          }
          if (!item.tradeSpecValueDtos) return;
          for (let value of item.tradeSpecValueDtos) {
            if (!value.name) {
              this.$message({
                type: 'error',
                message: '请完善规格值（设置商品规格）'
              })
              return
            }
          }
        }
        if (this.skuTable.length == 0) {
          this.$message({
            type: 'error',
            message: '请先生成sku规格表格（设置商品规格）'
          })
          return
        }
        for (let item of this.skuTable) {
          if (item.isDelete == 1) {
            // 结束本次循环
            continue;
          }
          if (this.isShowSellPrice() && !item.sellPrice) {
            this.$message({
              type: 'error',
              message: '请完善销售价（设置商品规格）'
            })
            return
          }
          if (item.marketPrice && +item.marketPrice < +item.sellPrice) {
            this.$message({
              type: 'error',
              message: '销售价不能大于市场价（设置商品规格）'
            })
            return
          }
          if (!item.supplyPrice && this.isSupplyGoods()) {
            this.$message({
              type: 'error',
              message: '请完善订货价（设置商品规格）'
            })
            return
          }
          if (!item.minBuy && this.isShowMinBuy()) {
            this.$message({
              type: 'error',
              message: '请完善起购量（设置商品规格）'
            })
            return
          }
          if (!item.imageIds && this.isShowSkuImage()) {
            this.$message({
              type: 'error',
              message: '请完善sku图片'
            })
            return
          }
          if (!item.stockQuantity) {
            this.$message({
              type: 'error',
              message: '请完善库存'
            })
            return
          }
          if (!item.stockQuantityWarning) {
            this.$message({
              type: 'error',
              message: '请完善预警库存'
            })
            return
          }
        }
        console.log(this.skuTable, '===skuTable===')
        // 筛选出有修改的商品
        let editSkuList = [];
        this.defaultSku.forEach(item => {
          let findObj = this.skuTable.find(find => find.id === item.id);
          console.log(findObj)
          if(!findObj || (findObj && (findObj.isDelete == 1 || findObj.sellPrice != item.sellPrice || findObj.stockQuantity != item.stockQuantity))) {
            editSkuList.push({...item, skuId: item.id})
          }
          //找出被删除的sku列表
          if (!findObj) {
            this.skuTable.push({ ...item, isDelete: 1 })
          }
        })


        if (this.skuData.length) {
          this.skuData.forEach(item => {
            item.belongType = item.specDetail.belongType;
            item.isDelete = 0;
          });
        }
        resolve({ skuTable: this.skuTable, skuData: this.skuData, editSkuList })
      })
    },
    blurTable(row) {
      this.skuTableData[row.specValue] = row
    },
    onChangeNum(row, name) {
      if(['supplyPrice', 'marketPrice', 'sellPrice'].includes(name)) {
        row[name] = row[name].replace(/[^\d.]/g, '').toString().match(/^\d+(?:\.\d{0,2})?/)[0]
      }else {
        row[name] = row[name].replace(/[^\d]/g, '')
      }
    },
    // 图片
    changeAvatar(data, row) {
      row.imageList = data
      row.imageIds = data.join(',')
      this.skuTableData[row.specValue] = row
    },
    showBigSkuImg() {
      document.getElementById('bigSkuImg').click()
    },
    //批量设置
    setBathSkuList() {
      let checkList = this.$refs['skuTable'].selection;
      if(!checkList || checkList.length === 0) {
        this.$message.warning('请选择要设置的规格')
        return
      }
      this.bathSetList = checkList;
      this.showSetAllSku = true;
    },
    operation(row, type) {
      console.log(row)
      if(type === 'edit') {
        this.specValueId = row.specKey.toString();
        this.showAssSku = true;
      }else if(type === 'delete') {
        this.$confirm('是否确认删除规格?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          // this.$set(row,'isDelete',1);
          // this.skuTable.splice(this.skuTable.indexOf(row), 1);
          let index = this.skuData[0].tradeSpecValueDtos.findIndex( find => find.id == row.specKey);
          this.skuData[0].tradeSpecValueDtos.splice(index, 1);
          //清除标签
          this.clearTag();
        }).catch();
      }
    },
    //关闭属性组
    onCloseVal(item, index, i) {
      /**
       * item: 规格对象
       * i：规格对象索引
       * index: 规格值索引
       */
      // console.log(item, index, i)
      // this.setSkuGroupIndex = i;
      item.tradeSpecValueDtos.splice(index, 1);
      // if(item.specDetail.valueType === 1) {
      //   //输入
      //   this.$refs['skuValRef'].clearInputSpec(item.tradeSpecValueDtos[index], index);
      // }else if(item.specDetail.valueType === 2) {
      //   //多选
      //   item.tradeSpecValueDtos.splice(index, 1);
      // }
    },
    // 查看权益
    toShowEquity(row) {
      this.skuId = +row.id;
      this.equityListShow = true;
      // this.$router.push({
      //   path: '/nb/goods/equityCardSet?',
      //   query: {
      //     goodsId: this.goodsId,
      //     type: 'show'
      //   }
      // })
    },
    // 是否供货商品
    isSupplyGoods() {
      return !!this.supply.name;
    },
    // 是否展示市场价
    isShowMarketPrice() {
      return !this.isNotSale;
    },
    // 是否展示销售价
    isShowSellPrice() {
      return !this.isNotSale;
    },
    isShowSkuImage() {
      return  ![2,3,4].includes(+this.goodsType);
    },
    // 是否展示起购量
    isShowMinBuy() {
      return [1,5,6,8].includes(+this.goodsType);
    },
    // 是否禁用起购量
    isDisabledMinBuy() {
      return this.isNotSale
    }
  }
}
</script>

<style lang="scss" scoped>
.sku-header {
  background: #f2f2f2;
  padding: 8px 13px;
  margin-bottom: 25px;
  > span {
    font-size: 16px;
  }
}
.sku-content {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  .el-icon-delete {
    font-size: 16px;
  }
}
.sku {
  .box-card {
    margin-bottom: 20px;
  }
  .box-card-tabl {
    max-height: 630px;
    overflow-y: auto;
  }
}
</style>
