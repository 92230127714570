<template>
  <div class="">
    <h1 class="title">
        售卖时间
        <el-tooltip class="item" effect="dark" content="售卖时间：商品上架期间仅允许消费者在售卖时间期间购买。" placement="right">
          <i class="el-icon-warning-outline" style="font-size: 14px;margin-left: 5px;"></i>
        </el-tooltip>
    </h1>
    <el-card class="box-card" >
      <el-radio-group v-model="radio">
        <div class="box-card_radio">
          <el-radio :class="radio === 0 ? 'goods-sell-time-model-box-card' : ''" :label="0" :disabled="disabled && radio !== 0">
            上架期间均正常售卖
          </el-radio>
        </div>
        <div class="box-card_radio">
          <el-radio :class="radio === 1 ? 'goods-sell-time-model-box-card' : ''" :label="1" :disabled="disabled && radio !== 1">
            指定时间区间售卖
            <el-tooltip class="item" effect="dark" content="例如需要在 1月1日 10点 至 2月1日20点 可售，日期设置：1月1日 10:00 -2月1日 20:00" placement="right">
              <i class="el-icon-warning-outline" style="font-size: 14px;margin-left: 5px;"></i>
            </el-tooltip>
          </el-radio>

          <div style="margin-top: 10px;" v-if="radio === 1">
            <el-date-picker
              :readonly="disabled"
              v-model="dayTimeSection"
              type="datetimerange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              size="small"
              format="yyyy-MM-dd HH:mm"
              value-format="yyyy-MM-dd HH:mm"
              >
            </el-date-picker>
          </div>
          
        </div>
        <div class="">
          <el-radio :class="radio === 2 ? 'goods-sell-time-model-box-card' : ''" :label="2" :disabled="disabled && radio !== 2">
            指定时间段内固定时间区间售卖
            <el-tooltip class="item" effect="dark" content="例如需要在1月1日至2月1日的每天10点至20点可售，日期设置：1月1日-2月1日，时间设置：10:00-20:00" placement="right">
              <i class="el-icon-warning-outline" style="font-size: 14px;margin-left: 5px;"></i>
            </el-tooltip>
          </el-radio>
          <div style="margin-top: 10px;" v-if="radio === 2">
            <span style="font-size: 16px;">日期： </span>
            <el-date-picker
              style="margin-right: 10px;"
              :readonly="disabled"
              v-model="daySection"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              size="small"
              value-format="yyyy-MM-dd">
            </el-date-picker>
            <span style="font-size: 16px;">时间： </span>
            <el-time-picker
              is-range
              :readonly="disabled"
              v-model="timeSection"
              range-separator="至"
              start-placeholder="开始时间"
              end-placeholder="结束时间"
              placeholder="选择时间范围"
              size="small"
              format="HH:mm"
              value-format="HH:mm">
            </el-time-picker>
          </div>
        </div>
      </el-radio-group>
    </el-card>
  </div>
</template>

<script>
import dayjs from 'dayjs';
export default {
  name: '',
  components: {},
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    tradeGoodsDto: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      pageType: '',
      radio: 0,
      dayTimeSection: null,
      daySection: null,
      timeSection: null,
    }
  },
  computed: {},
  watch: {
    tradeGoodsDto(item) {
      // item.saleStatus = 2,
      // item.saleTimeDto = {rangeDay: "2022-05-19,2022-06-20",rangeTime: "11:32:00,12:32:00"}
      if(item && item.saleTimeDto){
        this.radio = item.saleStatus;
        if(this.radio == 1) {
          this.dayTimeSection = item.saleTimeDto.rangeDay.split(',').map(value => value.split(':')[2] ? value.split(':')[0] + ':' + value.split(':')[1] : value);
        }else if(this.radio == 2) {
          this.daySection = item.saleTimeDto.rangeDay.split(',');
          this.timeSection = item.saleTimeDto.rangeTime.split(',').map(value => value.split(':')[2] ? value.split(':')[0] + ':' + value.split(':')[1] : value);
        }
      }
    }
  },
  created () {},
  mounted () {
    this.pageType = this.$route.query.type;
  },
  methods: {
    getForm() {
      return new Promise((resolve, reject)=>{
        if((this.radio === 1 && !this.dayTimeSection) || (this.radio === 2 && (!this.daySection || !this.timeSection))) {
          this.$message({ type: 'error', message: '请完善售卖时间（售卖时间）' })
        }else {
          let newObj = {
            saleStatus: this.radio,
            saleTimeDto: {
              rangeDay: this.radio === 0 ? 
              '' : 
              this.radio == 1 ? this.dayTimeSection.map(item => item.split(':')[2] ? item : item + ':00').join(',') : this.daySection.join(','),
              rangeTime: this.radio == 2 ? this.timeSection.map(item => item.split(':')[2] ? item : item + ':00').join(',') : '',
            }
          }
          resolve({...newObj})
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.box-card {
  margin-bottom: 30px;
  .box-card_radio {
    margin-bottom: 30px;
  }
}
</style>
<style lang="scss">
.goods-sell-time-model-box-card {
  .el-radio__label {
    color: #000!important;
  }
}
</style>