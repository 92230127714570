<template>
  <el-dialog
    title="证明管理"
    :visible.sync="show"
    width="600px"
    :before-close="clearDialog"
    append-to-body
  >
    <el-form
      ref="ruleFormRef"
      :model="ruleForm"
      label-width="100px"
    >
      <el-form-item
        label="品牌信息"
      >
        <el-image
          v-if="ruleForm.brandLogo"
          class="image"
          :src="ruleForm.brandLogo"
          :preview-src-list="[ruleForm.brandLogo]"
          fit="contain"
        />
        <span>{{ ruleForm.brandName }}</span>
      </el-form-item>
      <el-form-item
        label="认证编号"
        prop="authNo"
        :rules="[{ required: true, message: '请输入认证编号', trigger: 'blur' },]"
      >
        <el-input
          v-model="ruleForm.authNo"
          :disabled="disabled"
          class="w400"
          maxlength="20"
          show-word-limit
          placeholder="请输入认证编号"
          size="small"
        />
      </el-form-item>
      <el-form-item
        label="有效期"
        prop="timeList"
        :rules="[{
          type: 'array', required: true, message: '请选择有效时间', trigger: 'change',
        }]"
      >
        <el-date-picker
          v-model="ruleForm.timeList"
          :disabled="disabled"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="timestamp"
          size="small"
          :default-time="['00:00:00', '23:59:59']"
        />
      </el-form-item>
      <el-form-item
        label="证明图片"
        prop="imageList"
        :rules="[{
          type: 'array', required: true, message: '请上传图片', trigger: 'change',
        }]"
      >
        <UpImage
          :disabled="disabled"
          :image-list="ruleForm.imageList"
          :num="5"
        />
        <div>
          建议：800px*800px，2M以内；图片可拖动排序；限5张。
        </div>
      </el-form-item>
    </el-form>
    <span
      slot="footer"
    >
      <el-button
        size="small"
        @click="clearDialog"
      >取 消</el-button>
      <el-button
        v-if="!disabled"
        type="primary"
        size="small"
        @click="onSubmit"
      >确 定</el-button>
    </span>
  </el-dialog>
</template>

<script setup>
import { ref, getCurrentInstance, watch } from 'vue';
import UpImage from '@/components/common/upImage/index';
import CommonForm from './CommonForm.vue';

const { proxy } = getCurrentInstance();

const props = defineProps({
  show: {
    type: Boolean,
    default: false,
  },
  prove: {
    type: Object,
    default: () => {},
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(['clear', 'submit']);

const ruleFormRef = ref(null);
const ruleForm = ref({});

const clearDialog = () => {
  emit('clear');
};
const onSubmit = () => {
  ruleFormRef.value.validate((v) => {
    if (!v) return;
    emit('submit', {
      ...ruleForm.value,
      authBeginTime: ruleForm.value.timeList[0],
      authEndTime: ruleForm.value.timeList[1],
      authPic: ruleForm.value.imageList,
      brandName: undefined,
      brandLogo: undefined,
      timeList: undefined,
      imageList: undefined,
    });
    emit('clear');
  });
};

watch(() => props.prove, () => {
  // console.log(props.prove, 999999999);
  if (props.prove) {
    ruleForm.value = {
      brandName: props.prove.brandName,
      brandLogo: props.prove.brandLogo,
      authNo: props.prove.authNo,
      timeList: props.prove.authBeginTime ? [props.prove.authBeginTime, props.prove.authEndTime] : [],
      imageList: props.prove.authPic || [],
      brandId: props.prove.brandId,
    };
  } else {
    ruleForm.value = {};
  }
  // 清空表单校验
  Promise.resolve().then(() => {
    ruleFormRef.value.clearValidate();
  });
}, {
  deep: true,
});
</script>

<style scoped lang="scss">
.image {
  --w: 50px;
  width: var(--w);
  height: var(--w);
  margin-right: 10px;
}
</style>
