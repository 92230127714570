var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "attrInfo_form" },
    [
      _vm.query.goodsType != "4" &&
      _vm.query.goodsType != "5" &&
      _vm.query.goodsType != "6"
        ? _c("h1", { staticClass: "title" }, [_vm._v(" 商品属性 ")])
        : _vm._e(),
      _vm.query.goodsType == "4"
        ? _c("h1", { staticClass: "title" }, [_vm._v(" 日程规划 ")])
        : _vm._e(),
      _c(
        "el-card",
        { staticClass: "attr-header" },
        [
          !_vm.disabled && _vm.people === "gys"
            ? _c(
                "el-button",
                {
                  attrs: { type: "text" },
                  on: {
                    click: function ($event) {
                      return _vm.addAttr(1)
                    },
                  },
                },
                [_vm._v("+添加属性")]
              )
            : _vm._e(),
          _c(
            "el-form",
            { attrs: { "label-width": "110px" } },
            [
              _c("Attr", {
                attrs: {
                  disabled: _vm.disabled,
                  "my-trade-attribute-dtos": _vm.myTradeAttributeDtos,
                  "rule-form": _vm.ruleForm,
                  "page-type": _vm.pageType,
                },
                on: {
                  changeRuleForm: (item) => {
                    this.ruleForm = item
                  },
                  editAttr: (row, value) => {
                    _vm.editAttr(row, 1)
                  },
                  deteleAttr: (row) => {
                    _vm.deteleAttr(row, 1)
                  },
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "添加属性",
            visible: _vm.showAddAttr,
            width: "400px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showAddAttr = $event
            },
            open: _vm.getCommonAttrList,
            clear: function ($event) {
              _vm.attrId = ""
            },
          },
        },
        [
          _c(
            "div",
            [
              _c("el-autocomplete", {
                staticStyle: { width: "100%" },
                attrs: {
                  "popper-class": "my-autocomplete",
                  "fetch-suggestions": _vm.querySearch,
                  placeholder: "请输入属性",
                  size: "small",
                  maxlength: "10",
                  "show-word-limit": "",
                },
                on: { select: _vm.handleSelectAttr },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ item }) {
                      return [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              display: "flex",
                              "justify-content": "space-between",
                              "align-items": "center",
                            },
                          },
                          [
                            _c("span", { staticClass: "name" }, [
                              _vm._v(_vm._s(item.name)),
                            ]),
                            _c("i", {
                              staticClass: "el-icon-circle-close",
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.clearCommonAttr(item)
                                },
                              },
                            }),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
                model: {
                  value: _vm.attrName,
                  callback: function ($$v) {
                    _vm.attrName = $$v
                  },
                  expression: "attrName",
                },
              }),
              _c(
                "el-checkbox",
                {
                  staticStyle: { "margin-bottom": "10px" },
                  model: {
                    value: _vm.isSaveName,
                    callback: function ($$v) {
                      _vm.isSaveName = $$v
                    },
                    expression: "isSaveName",
                  },
                },
                [_vm._v("存为常用属性名")]
              ),
              _c("el-input", {
                attrs: {
                  type: "textarea",
                  placeholder: "请输入200字以内属性值",
                  size: "small",
                  maxlength: "200",
                  "show-word-limit": "",
                },
                model: {
                  value: _vm.attrValue,
                  callback: function ($$v) {
                    _vm.attrValue = $$v
                  },
                  expression: "attrValue",
                },
              }),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small" },
                  on: {
                    click: function ($event) {
                      _vm.showAddAttr = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "small" },
                  on: { click: _vm.onSubmit },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }