<template>
  <!-- 基础保障 -->
  <div class="mb50">
    <h1 class="title">
      基础保障
    </h1>
    <el-form
      ref="freightForm"
      label-width="110px"
      class="demo-ruleForm"
    >
      <el-form-item label="保障：">
        <el-checkbox-group
          v-if="!disabled"
          v-model="checkList"
        >
          <div
            v-for="(item,i) in list"
            :key="i"
          >
            <el-checkbox
              :label="item"
            >
              {{ item.label }}：{{ item.value }}
            </el-checkbox>
          </div>
        </el-checkbox-group>
        <el-checkbox-group
          v-if="disabled"
          :value="checkList"
        >
          <div
            v-for="(item,i) in list"
            :key="i"
          >
            <el-checkbox
              :label="item"
            >
              {{ item.label }}：{{ item.value }}
            </el-checkbox>
          </div>
        </el-checkbox-group>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  name: '',
  components: {},
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    goodsBaseGuarantees: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      checkList: [],
      list: [],
      timeLate: false,
    };
  },
  computed: {},
  watch: {
    goodsBaseGuarantees: {
      handler(val) {
        if (val && val.length > 0) {
          if (this.list.length === 0) {
            this.timeLate = true;
            return;
          }
          val.forEach((item) => {
            let find = this.list.find((find) => +find.id === +item.id);
            this.checkList.push(find);
          });
        }
      },
    },
  },
  created() {},
  mounted() {
    this.getList();
  },
  methods: {
    getList() {
      this.$axios.post(this.$api.goodsmanage.trade_goods_baseGuarantee).then((res) => {
        if (res.code === 0) {
          this.list = res.data;
          if (this.timeLate) {
            this.goodsBaseGuarantees.forEach((item) => {
              let find = this.list.find((find) => +find.id === +item.id);
              this.checkList.push(find);
            });
          }
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">

</style>
