<template>
  <div class="mb50">
    <h1 class="title">
      销售对象
    </h1>
    <el-form
      label-width="110px"
    >
      <el-form-item
        v-if="people === 'gys'"
        label="销售对象："
        required
      >
        <el-radio-group
          v-model="radio"
          :disabled="disabled"
        >
          <el-radio :label="0">
            个人
          </el-radio>
          <el-radio :label="1">
            企业
          </el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item
        v-if="people === 'admin' && radio === 1"
        label="销售对象："
        required
      >
        <el-radio-group v-model="radio">
          <el-radio :label="1">
            企业
          </el-radio>
        </el-radio-group>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  name: '',
  components: {},
  props: {
    people: {
      type: String,
      default: 'admin',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    tradeGoodsDto: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      radio: 0,
    };
  },
  computed: {},
  watch: {
    tradeGoodsDto: {
      handler(val) {
        if (val && val.saleTarget === 1) {
          this.radio = val.saleTarget;
        }
      },
      immediate: true,
    },
  },
  created() {},
  mounted() {

  },
  methods: {

  },
};
</script>

<style scoped lang="scss">

</style>
