<template>
  <div class="attrInfo_form">
    <h1
      v-if="query.goodsType != '4' && query.goodsType != '5' && query.goodsType != '6' "
      class="title"
    >
      商品属性
    </h1>
    <h1
      v-if="query.goodsType == '4'"
      class="title"
    >
      日程规划
    </h1>
    <el-card class="attr-header">
      <el-button type="text" v-if="!disabled && people === 'gys'" @click="addAttr(1)">+添加属性</el-button>
      <el-form
        label-width="110px"
      >
        <Attr
          :disabled="disabled"
          :my-trade-attribute-dtos="myTradeAttributeDtos"
          :rule-form="ruleForm"
          :page-type="pageType"
          @changeRuleForm="(item) => {
            this.ruleForm=item
          }"
          @editAttr="(row, value)=> {editAttr(row, 1)}"
          @deteleAttr="(row)=> {deteleAttr(row, 1)}"
        ></Attr>
      </el-form>
    </el-card>

    <el-dialog
      title="添加属性"
      :visible.sync="showAddAttr"
      width="400px"
      @open="getCommonAttrList"
      @clear="attrId = ''"
      >
      <div>
        <!-- <el-input v-model="attrName" placeholder="请输入" size="small"></el-input> -->
        <!-- 自定义属性 -->
        <el-autocomplete
          style="width: 100%;"
          popper-class="my-autocomplete"
          v-model="attrName"
          :fetch-suggestions="querySearch"
          placeholder="请输入属性"
          size="small"
          maxlength="10" 
          show-word-limit
          @select="handleSelectAttr">
          <template slot-scope="{ item }">
            <div style="display: flex;justify-content: space-between;align-items: center;">
              <span class="name">{{ item.name }}</span>
              <i class="el-icon-circle-close" @click.stop="clearCommonAttr(item)"></i>
            </div>
          </template>
        </el-autocomplete>
        <el-checkbox v-model="isSaveName" style="margin-bottom: 10px">存为常用属性名</el-checkbox>
        <el-input type="textarea" v-model="attrValue" placeholder="请输入200字以内属性值" size="small" maxlength="200" show-word-limit></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showAddAttr = false" size="small">取 消</el-button>
        <el-button type="primary" @click="onSubmit" size="small">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Attr from './components/attr.vue'
export default {
  components: {
    Attr
  },
  props: ['people', 'disabled', 'tradeAttributeDtos', 'tradeGoodsAttributeRelationDtos'],
  data() {
    return {
      ruleForm: {},
      pageType: '',
      query: {},
      myTradeAttributeDtos: [],
      showTradeAttributeDtos: [],

      // 添加属性
      showAddAttr: false,
      isSaveName: false,//是否存为常用属性名
      attrName: '',
      attrValue: '',
      addAttrType: 0,//0:基础属性 1:描述属性
      commonAttrList: [],//常用属性
      attrId: '',//编辑属性id
    }
  },
  watch: {
    tradeAttributeDtos(data) {
      console.log(444, data)
        data && data.forEach((item) => {
          if (item.valueType == '3') {
            !this.ruleForm[item.id] && this.$set(this.ruleForm, item.id, [])
          } else if (item.valueType == '5') {
            this.$set(this.ruleForm, item.id, '一天/12小时')
          }
        })
        this.myTradeAttributeDtos = data
        console.log(9999, this.ruleForm)
    },
    tradeGoodsAttributeRelationDtos(data) {
        setTimeout(() => {
          data &&
            data.forEach((item) => {
              for (let value of this.myTradeAttributeDtos) {
                if (item.attributeId == value.id) {
                  if (value.valueType == '1' || value.valueType == '5' || value.valueType == '6') {
                    this.$set(this.ruleForm, value.id, item.attributeValue)
                  } else if (value.valueType == '2' || value.valueType == '4') {
                    this.$set(
                      this.ruleForm,
                      value.id,
                      item.attributeValue
                    )
                  } else if (value.valueType == '3') {
                    this.$set(
                      this.ruleForm,
                      value.id,
                      item.attributeValue.split(',')
                    )
                  }
                }
              }
            })
            console.log(989898, this.ruleForm)
        }, 100)
    }
  },
  created() {
    this.query = this.$route.query
    this.pageType = this.$route.query.type
  },
  mounted() {

  },
  methods: {
    getForm(rank) {
      return new Promise((resolve) => {
        let tradeGoodsAttributeRelationDtos = []
        if (!this.myTradeAttributeDtos) {
          return
        }
        // { type: 1, name: "文本框" },  { type: 2, name: "单选框" },  { type: 3, name: "复选框" }, { type: 4, name: "下拉框" }, { type: 5, name: "时间格式" },
        for (let item of this.myTradeAttributeDtos) {
          // if (Object.keys(this.ruleForm).indexOf(item.id.toString()) == -1) {
          //   this.$message({ type: "error", message: "请填写完整商品属性" });
          //   return;
          // }
          for (let key in this.ruleForm) {
            if (item.id == key) {
              if (item.valueType == '1' || item.valueType == '5' || item.valueType == '6') {
                tradeGoodsAttributeRelationDtos.push({
                  ...item,
                  attributeValueType: item.valueType,
                  isRequire: item.isRequire,
                  attributeName: item.name,
                  attributeId: Number(key),
                  attributeValue: this.ruleForm[key],
                  attributeType: item.type,
                  valueType: item.valueType,
                  attributeGroupId: item.attributeGroupId,
                  goodsId: this.$route.query.goodsId
                    ? this.$route.query.goodsId
                    : null,
                  id:
                    this.tradeGoodsAttributeRelationDtos &&
                    this.tradeGoodsAttributeRelationDtos.find(
                      (find) => find.attributeId == item.id
                    )
                      ? this.tradeGoodsAttributeRelationDtos.find(
                        (find) => find.attributeId == item.id
                      ).id
                      : null,
                  type: item.type
                })
              } else if (item.valueType == '2' || item.valueType == '4') {
                // let checkData = item.tradeAttributeValueVoList.find(
                //   (value) => value.id == this.ruleForm[key]
                // )
                //   ? item.tradeAttributeValueVoList.find(
                //     (value) => value.id == this.ruleForm[key]
                //   ).value
                //   : ''
                tradeGoodsAttributeRelationDtos.push({
                  ...item,
                  attributeValueType: item.valueType,
                  isRequire: item.isRequire,
                  attributeName: item.name,
                  attributeId: Number(key),
                  attributeValueId: '',
                  attributeValue: this.ruleForm[key].toString(),
                  attributeType: item.type,
                  valueType: item.valueType,
                  attributeGroupId: item.attributeGroupId,
                  goodsId: this.$route.query.goodsId
                    ? this.$route.query.goodsId
                    : null,
                  id:
                    this.tradeGoodsAttributeRelationDtos &&
                    this.tradeGoodsAttributeRelationDtos.find(
                      (find) => find.attributeId == item.id
                    )
                      ? this.tradeGoodsAttributeRelationDtos.find(
                        (find) => find.attributeId == item.id
                      ).id
                      : null,
                  type: item.type
                })
              } else if (item.valueType == '3') {
                // if(this.ruleForm[key].length===0 || !this.ruleForm[key]){
                //   this.$message({ type: "error", message: "请选择完整商品属性" });
                //   return;
                // }
                // let checkList = this.ruleForm[key].map((checkid, i) =>
                //   item.tradeAttributeValueVoList.find(
                //     (value) => value.id == checkid
                //   )
                //     ? item.tradeAttributeValueVoList.find(
                //       (value) => value.id == checkid
                //     ).value
                //     : []
                // )
                tradeGoodsAttributeRelationDtos.push({
                  ...item,
                  attributeValueType: item.valueType,
                  attributeName: item.name,
                  attributeId: Number(key),
                  attributeValueId: '',
                  attributeValue: this.ruleForm[key].join(','),
                  attributeType: item.type,
                  valueType: item.valueType,
                  attributeGroupId: item.attributeGroupId,
                  goodsId: this.$route.query.goodsId
                    ? this.$route.query.goodsId
                    : null,
                  id:
                    this.tradeGoodsAttributeRelationDtos &&
                    this.tradeGoodsAttributeRelationDtos.find(
                      (find) => find.attributeId == item.id
                    )
                      ? this.tradeGoodsAttributeRelationDtos.find(
                        (find) => find.attributeId == item.id
                      ).id
                      : null,
                  type: item.type
                })
              }
            }
          }
        }

        let newArray = tradeGoodsAttributeRelationDtos.filter(
          item => (item.valueType == 2 || item.valueType == 3 || item.valueType == 4) || ((item.valueType == 1 || item.valueType == 5 || item.valueType == 6) && item.attributeValue)
        )
        console.log(newArray, 7777)
        if (rank == 'easy') {
          resolve(newArray)
        } else {
          // console.log(newArray)
          // console.log(this.myTradeAttributeDtos,'myTradeAttributeDtos==========')
          for (let myattr of this.myTradeAttributeDtos) {
            if (myattr.isRequire == 1) {
              let sameAttr = newArray.find(myfind => myfind.attributeId == myattr.id)
              if (!sameAttr) {
                this.$message({ type: 'error', message: '请完善' + myattr.name + '（商品属性）' })
                return
              }
            }
          }
          resolve(newArray)
        }
      })
    },
    addAttr(type) {
      this.showAddAttr = true;
      this.addAttrType = type;
    },
    editAttr(row, type) {
      console.log(row, type)
      this.attrId = row.id;
      this.showAddAttr = true;
      this.addAttrType = type;
      this.attrName = row.name;
      this.attrValue = row.value;
      this.isSaveName = row.isCommon == 1 ? true : false;
    },
    deteleAttrOnId(id) {
      let obj = this.myTradeAttributeDtos.find( find=> find.id == id);
      this.myTradeAttributeDtos.splice(this.myTradeAttributeDtos.indexOf(obj), 1);
    },
    //删除商品属性
    deteleAttr(row, type) {
      this.$confirm('是否确认删除此属性', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
      .then(() => {
        this.deteleAttrOnId(row.id)
      })
      .catch(() => {});
    },
    //删除常用属性
    clearCommonAttr(row) {
      this.$confirm('是否确认删除常用属性', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
      .then(() => {
        this.$axios
        .get(this.$api.attribute.delete, {
          params: {id: row.id}
        })
        .then((res) => {
          if (res.code === 0) {
            this.$message.success('操作成功');
            this.getCommonAttrList();
            if(this.myTradeAttributeDtos.find( item=> item.id == row.id)) {
              this.deteleAttrOnId(row.id)
            }
          }
        })
      })
      .catch(() => {});
      console.log(row)
    },
    handleSelectAttr(row) {
      this.attrName = row.name
    },
    querySearch(queryString, cb) {
      var restaurants = this.commonAttrList;
      var results = queryString ? restaurants.filter( item=> {
        console.log(item.name)
        return item.name.indexOf(queryString) != -1
      }) : restaurants;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    onSubmit() {
      if(!this.attrName) {
        this.$message.error('请输入属性名');
        return;
      }
      let findAttr = this.commonAttrList.find( item=> item.name === this.attrName);
      if(findAttr) {
        if(this.myTradeAttributeDtos.find( item=> item.id == findAttr.id)) {
          this.$message.warning('该属性已存在')
          return
        }
        if(this.attrId) {
          this.deteleAttrOnId(this.attrId);
        }
        this.tradeAttributeDtos.push({
          id: findAttr.id,
          name: this.attrName,
          type: this.addAttrType,
          valueType: "1",
          belongType: 2,
          isCommon: this.isSaveName
        });
        this.$set(this.ruleForm, findAttr.id, this.attrValue);

        this.showAddAttr = false;
        this.isSaveName= false,//是否存为常用属性名
        this.attrName= '';
        this.attrValue= '';
        return
      }
      this.$axios
      .post(this.$api.attribute.add, {
        name: this.attrName,
        belongType: 2,
        isCommon: this.isSaveName ? 1 : 0,
        backendCategoryVoList: [{id: this.$route.query.backendCategoryId}]
      })
      .then((res) => {
        if (res.code === 0) {
          if(this.attrId) {
            this.deteleAttrOnId(this.attrId);
          }
          this.tradeAttributeDtos.push({
            id: res.data,
            name: this.attrName,
            type: this.addAttrType,
            valueType: "1",
            belongType: 2,
            isCommon: this.isSaveName
          });
          this.$set(this.ruleForm, res.data, this.attrValue);
          this.$message.success('操作成功！')
          this.showAddAttr = false;
          this.isSaveName= false,//是否存为常用属性名
          this.attrName= '';
          this.attrValue= '';
        }
      })
    },
    getCommonAttrList() {
      this.$axios
      .post(this.$api.attribute.list, {
        belongType: 2,
      })
      .then((res) => {
        if (res.code === 0) {
          this.commonAttrList = res.data.records;
        }
      })
    }
  }
}
</script>

<style lang="scss">
.attrInfo_form {
  .el-form {
    display: flex;
    flex-wrap: wrap;
    .el-form-item {
      min-width: 400px;
    }
  }
}
.attr-header {
  margin-bottom: 10px;
}
.my_textarea {
  .el-input__count{
    height: 20px;
    line-height: 20px;
    bottom: -20px;
    right: 0px;
  }
}
</style>
