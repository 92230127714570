var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "component" }, [
    _c("h1", { ref: "scrollIntoView", staticClass: "title" }, [
      _vm._v(" 特殊认证 "),
    ]),
    _c(
      "div",
      { staticClass: "goods_authentication_parent" },
      [
        _c(
          "el-card",
          { staticClass: "wd-display-inblock" },
          [
            _c(
              "el-form",
              {
                ref: "ruleForm",
                attrs: { model: _vm.ruleForm, "label-width": "100px" },
              },
              _vm._l(_vm.ruleForm.authList, function (item, index) {
                return _c("div", { key: item.type }, [
                  _c("div", { staticClass: "line_box wd-flex" }, [
                    _c(
                      "div",
                      {
                        staticClass: "line_check wd-flex wd-flex-align-center",
                      },
                      [
                        _c("el-checkbox", {
                          attrs: { disabled: _vm.disabled },
                          model: {
                            value: item.isShowAuth,
                            callback: function ($$v) {
                              _vm.$set(item, "isShowAuth", $$v)
                            },
                            expression: "item.isShowAuth",
                          },
                        }),
                        _c("img", {
                          staticClass: "logo",
                          attrs: { src: item.image, alt: "" },
                        }),
                        _vm._v(" " + _vm._s(item.title) + " "),
                      ],
                      1
                    ),
                    item.isShowAuth
                      ? _c(
                          "div",
                          { staticClass: "line_box_right wd-flex" },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "认证名称",
                                  prop: `authList[${index}].name`,
                                  rules: [
                                    {
                                      required: true,
                                      message: "请输入认证名称",
                                      trigger: "blur",
                                    },
                                  ],
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: {
                                    placeholder: "请输入认证名称",
                                    size: "mini",
                                    maxlength: "15",
                                    "show-word-limit": "",
                                    readonly: _vm.disabled,
                                  },
                                  model: {
                                    value: item.name,
                                    callback: function ($$v) {
                                      _vm.$set(item, "name", $$v)
                                    },
                                    expression: "item.name",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "认证编号",
                                  prop: `authList[${index}].code`,
                                  rules: [
                                    {
                                      required: true,
                                      message: "请输入认证编号",
                                      trigger: "blur",
                                    },
                                  ],
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: {
                                    placeholder: "请输入认证编号",
                                    size: "mini",
                                    maxlength: "20",
                                    "show-word-limit": "",
                                    readonly: _vm.disabled,
                                  },
                                  model: {
                                    value: item.code,
                                    callback: function ($$v) {
                                      _vm.$set(item, "code", $$v)
                                    },
                                    expression: "item.code",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "有效期",
                                  prop: `authList[${index}].daysList`,
                                  rules: [
                                    {
                                      required: true,
                                      type: "array",
                                      message: "请选择有效期",
                                      trigger: "change",
                                    },
                                  ],
                                },
                              },
                              [
                                _c("el-date-picker", {
                                  staticStyle: { width: "350px" },
                                  attrs: {
                                    type: "daterange",
                                    "range-separator": "至",
                                    "start-placeholder": "开始日期",
                                    "end-placeholder": "结束日期",
                                    "value-format": "timestamp",
                                    size: "mini",
                                    readonly: _vm.disabled,
                                  },
                                  model: {
                                    value: item.daysList,
                                    callback: function ($$v) {
                                      _vm.$set(item, "daysList", $$v)
                                    },
                                    expression: "item.daysList",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "认证证书",
                                  prop: `authList[${index}].imageList`,
                                  rules: [
                                    {
                                      required: true,
                                      type: "array",
                                      message: "请上传认证证书",
                                      trigger: "change",
                                    },
                                  ],
                                },
                              },
                              [
                                _c("UpImage", {
                                  attrs: {
                                    disabled: _vm.disabled,
                                    "image-list": item.imageList,
                                    num: 5,
                                  },
                                  on: {
                                    "update:imageList": function ($event) {
                                      return _vm.$set(item, "imageList", $event)
                                    },
                                    "update:image-list": function ($event) {
                                      return _vm.$set(item, "imageList", $event)
                                    },
                                  },
                                }),
                                _c("div", { staticClass: "gray" }, [
                                  _vm._v(" 最多上传5张证书图片 "),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]),
                ])
              }),
              0
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }