var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "brand_model" },
    [
      _vm._m(0),
      _c(
        "el-form",
        { staticClass: "demo-ruleForm", attrs: { "label-width": "110px" } },
        [
          _c(
            "el-form-item",
            { attrs: { label: "关联标签：", prop: "checkList", required: "" } },
            [
              !_vm.tradeServiceTagDtos || _vm.tradeServiceTagDtos.length === 0
                ? _c("div", [_vm._v(" 未选择标签 ")])
                : _vm._e(),
              _vm.tradeServiceTagDtos && _vm.tradeServiceTagDtos.length > 0
                ? _c(
                    "div",
                    _vm._l(_vm.tradeServiceTagDtos, function (item, i) {
                      return _c(
                        "span",
                        { key: i },
                        [
                          _c(
                            "el-tag",
                            {
                              staticStyle: { margin: "0 0 0 10px" },
                              attrs: {
                                type: item.belongType == 2 ? "warning" : "",
                                closable: !_vm.disabled,
                              },
                              on: {
                                close: function ($event) {
                                  return _vm.onCloseTab(item, i)
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(item.name) + " ")]
                          ),
                          item.coverImgUrlList &&
                          item.coverImgUrlList.length > 0
                            ? _c(
                                "div",
                                {
                                  staticStyle: {
                                    "margin-right": "20px",
                                    display: "inline-block",
                                    color: "#1890ff",
                                    "text-decoration": "underline",
                                    cursor: "pointer",
                                    transform: "translate(5px,9px)",
                                    "line-height": "20px",
                                  },
                                  attrs: { type: "primary" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.onProve(item, "show")
                                    },
                                  },
                                },
                                [_vm._v("查看证明")]
                              )
                            : _vm._e(),
                        ],
                        1
                      )
                    }),
                    0
                  )
                : _vm._e(),
              !_vm.disabled
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "small" },
                      on: { click: _vm.onShowTagList },
                    },
                    [_vm._v(" 选择标签 ")]
                  )
                : _vm._e(),
            ],
            1
          ),
          _c("el-form-item", { attrs: { label: "提示：" } }, [
            _c("div", [
              _vm._v(" 您需要对勾选的标签内容负责，请按照实际情况勾选标签。 "),
            ]),
          ]),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "选择标签",
            visible: _vm.dialogVisible,
            width: "800px",
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
            open: _vm.openModel,
          },
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
            },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    "margin-bottom": "10px",
                    "min-height": "35px",
                  },
                },
                [
                  _vm._v(" 已选择标签： "),
                  _vm._l(_vm.labelList, function (item, i) {
                    return _c(
                      "el-tag",
                      {
                        key: i,
                        staticStyle: { margin: "0 10px 0 0" },
                        attrs: {
                          type: item.belongType == 2 ? "warning" : "",
                          closable: "",
                        },
                        on: {
                          close: function ($event) {
                            return _vm.onCloseTab(item, i)
                          },
                        },
                      },
                      [_vm._v(" " + _vm._s(item.name) + " ")]
                    )
                  }),
                ],
                2
              ),
              _c(
                "div",
                { staticStyle: { "margin-bottom": "10px" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "200px", "margin-right": "10px" },
                    attrs: {
                      size: "small",
                      placeholder: "请输入标签名称",
                      clearable: "",
                    },
                    on: { clear: _vm.search },
                    model: {
                      value: _vm.name,
                      callback: function ($$v) {
                        _vm.name = $$v
                      },
                      expression: "name",
                    },
                  }),
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small", type: "primary" },
                      on: { click: _vm.search },
                    },
                    [_vm._v(" 搜索 ")]
                  ),
                  _vm.people === "gys"
                    ? _c(
                        "el-button",
                        {
                          attrs: { size: "small", type: "primary" },
                          on: {
                            click: function ($event) {
                              _vm.showDetailBrand = true
                            },
                          },
                        },
                        [_vm._v(" 新增标签 ")]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "div",
                [
                  _vm.belongType == "1" || _vm.belongType == "2"
                    ? _c(
                        "el-tabs",
                        {
                          attrs: { type: "card" },
                          on: { "tab-click": _vm.changeTabActive },
                          model: {
                            value: _vm.belongType,
                            callback: function ($$v) {
                              _vm.belongType = $$v
                            },
                            expression: "belongType",
                          },
                        },
                        [
                          _c("el-tab-pane", {
                            attrs: { label: "平台提供", name: "1" },
                          }),
                          _c("el-tab-pane", {
                            attrs: { label: "自定义标签", name: "2" },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.tagGroupTab && _vm.belongType == "1"
                    ? _c(
                        "el-tabs",
                        {
                          on: { "tab-click": _vm.changeTabGroupActive },
                          model: {
                            value: _vm.tagGroupTab,
                            callback: function ($$v) {
                              _vm.tagGroupTab = $$v
                            },
                            expression: "tagGroupTab",
                          },
                        },
                        _vm._l(_vm.tagGroupTabList, function (item, i) {
                          return _c("el-tab-pane", {
                            key: i,
                            attrs: {
                              label: item.name,
                              name: item.id.toString(),
                            },
                          })
                        }),
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-table",
                    {
                      ref: "tagTable",
                      staticStyle: { width: "100%" },
                      attrs: { data: _vm.tableData, "row-key": "id" },
                      on: {
                        "select-all": _vm.selectAll,
                        select: _vm.selectionChange,
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          type: "selection",
                          "reserve-selection": "",
                          selectable: (row, rowIndex) => {
                            if (
                              row.status != 1 ||
                              (row.isProve == 1 && !row.coverImgUrlList) ||
                              (row.isProve == 1 &&
                                row.coverImgUrlList.length === 0)
                            ) {
                              return false
                            } else {
                              return true
                            }
                          },
                          width: "55",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: { fixed: "", prop: "name", label: "标签名称" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function ({ row }) {
                              return [
                                [
                                  (row.isProve == 1 && !row.coverImgUrlList) ||
                                  (row.isProve == 1 &&
                                    row.coverImgUrlList.length === 0)
                                    ? _c(
                                        "el-tooltip",
                                        {
                                          attrs: {
                                            effect: "dark",
                                            content: "请先上传证明再勾选标签",
                                            placement: "top",
                                          },
                                        },
                                        [_c("span", [_vm._v(_vm._s(row.name))])]
                                      )
                                    : _c("span", [_vm._v(_vm._s(row.name))]),
                                ],
                                row.status == 1 &&
                                _vm.people === "gys" &&
                                _vm.belongType == "2" &&
                                !_vm.labelList.find((find) => find.id == row.id)
                                  ? _c("i", {
                                      staticClass: "el-icon-delete",
                                      staticStyle: {
                                        "margin-left": "20px",
                                        color: "red",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.deteleBrand(row)
                                        },
                                      },
                                    })
                                  : _vm._e(),
                                row.isProve == 1
                                  ? _c(
                                      "span",
                                      [
                                        _vm.belongType == "1"
                                          ? _c(
                                              "el-link",
                                              {
                                                staticStyle: {
                                                  "margin-left": "10px",
                                                },
                                                attrs: { type: "primary" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.onProve(row)
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      row.coverImgUrlList &&
                                                        row.coverImgUrlList
                                                          .length > 0
                                                        ? "查看证明"
                                                        : "上传证明"
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                row.status != 1
                                  ? _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          "margin-left": "10px",
                                          height: "18px",
                                          "line-height": "12px",
                                          "border-radius": "2px",
                                          border: "1px solid #C0C4CC",
                                          "font-size": "12px",
                                          padding: "2px 4px",
                                          color: "#C0C4CC",
                                        },
                                      },
                                      [_vm._v("已禁用")]
                                    )
                                  : _vm._e(),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                  _vm.tableData && _vm.tableData.length > 0
                    ? _c("el-pagination", {
                        attrs: {
                          "current-page": _vm.pagination.currentPage,
                          "page-sizes": [10, 20, 30, 50],
                          "page-size": _vm.pagination.nowPageSize,
                          layout: "total, sizes, prev, pager, next, jumper",
                          total: _vm.pagination.count,
                        },
                        on: {
                          "size-change": _vm.handleSizeChange,
                          "current-change": _vm.handleCurrentChange,
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ]
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small" },
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "small" },
                  on: { click: _vm.onSubmit },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.brandDetail.id ? "编辑标签" : "新增标签",
            visible: _vm.showDetailBrand,
            width: "400px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showDetailBrand = $event
            },
            closed: function ($event) {
              _vm.brandDetail.brandName = ""
            },
          },
        },
        [
          _c(
            "div",
            [
              _c("el-input", {
                attrs: {
                  placeholder: "请输入",
                  size: "small",
                  maxlength: "6",
                  "show-word-limit": "",
                },
                model: {
                  value: _vm.brandDetail.brandName,
                  callback: function ($$v) {
                    _vm.$set(_vm.brandDetail, "brandName", $$v)
                  },
                  expression: "brandDetail.brandName",
                },
              }),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small" },
                  on: {
                    click: function ($event) {
                      _vm.showDetailBrand = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    disabled: !_vm.brandDetail.brandName,
                    type: "primary",
                    size: "small",
                  },
                  on: { click: _vm.onEditBrand },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "证明管理",
            visible: _vm.showProveModel,
            width: "600px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showProveModel = $event
            },
          },
        },
        [
          _vm.tagData
            ? _c(
                "el-form",
                {
                  staticClass: "demo-ruleForm",
                  attrs: { "label-width": "110px" },
                },
                [
                  _c("el-form-item", { attrs: { label: "标签名称：" } }, [
                    _vm._v(" " + _vm._s(_vm.tagData.name) + " "),
                  ]),
                  _c(
                    "el-form-item",
                    { attrs: { label: "证明图片：" } },
                    [
                      _c("UpImage", {
                        attrs: {
                          num: 10,
                          "image-list": _vm.proveImageList,
                          disabled: _vm.tagData.type == "show",
                        },
                        on: {
                          "update:imageList": function ($event) {
                            _vm.proveImageList = $event
                          },
                          "update:image-list": function ($event) {
                            _vm.proveImageList = $event
                          },
                        },
                      }),
                      _c("div", [
                        _vm._v(
                          "建议：800px*800px，2M以内；图片可拖动排序；限10张。"
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.tagData && _vm.tagData.type != "show"
            ? _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        disabled: _vm.proveImageList.length === 0,
                        type: "primary",
                        size: "small",
                      },
                      on: { click: _vm.onSaveProve },
                    },
                    [_vm._v("保存")]
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h1", { staticClass: "add_goods_title" }, [
      _vm._v(" 标签"),
      _c("span", [
        _vm._v("(最多选择4个标签，蓝色为平台提供标签，橙色为商家自定义标签)"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }