<template>
  <el-dialog
    title="选择品牌"
    :visible.sync="show"
    width="800px"
    :before-close="clearDialog"
    @open="open"
  >
    <div>
      <div class="margin-b10">
        已选择：<BrandTag
          v-for="item in selectList"
          :key="item.id"
          class="margin-r10 margin-b5"
          :item="item"
          @close="closeTag"
        />
      </div>
      <div class="margin-b10">
        <el-input
          v-model="searchName"
          class="w300 margin-r10"
          size="small"
          placeholder="请输入品牌名称"
          clearable
        />
        <el-button
          size="small"
          type="primary"
          @click="onSearch"
        >
          搜索
        </el-button>
        <el-button
          v-if="brandShowType === 1"
          size="small"
          type="primary"
          @click="editBrand"
        >
          新增品牌
        </el-button>
      </div>
      <el-tabs
        v-if="brandDomain.length === 1"
        v-model="activeTab"
        type="card"
      >
        <template v-if="brandShowType === 1">
          <el-tab-pane
            label="平台提供"
            name="1"
          />
          <el-tab-pane
            label="自定义品牌"
            name="2"
          />
        </template>
        <template v-if="brandShowType === 2">
          <el-tab-pane
            label="区域公用品牌"
            name="3"
          />
          <el-tab-pane
            label="其他品牌"
            name="4"
          />
        </template>
      </el-tabs>
      <el-table
        ref="tableRef"
        v-loading="loading"
        :data="tableData"
        :show-header="false"
        row-key="id"
      >
        <el-table-column width="55">
          <template #default="{row}">
            <el-checkbox
              v-model="row.checked"
              :disabled="disabledCheck(row)"
              @change="changeCHecked(row)"
            />
          </template>
        </el-table-column>
        <el-table-column>
          <template #default="{row}">
            <div class="brand-content">
              <el-image
                v-if="row.brandLogo"
                class="image"
                :src="row.brandLogo"
                :preview-src-list="[row.brandLogo]"
                fit="contain"
              />
              <img
                v-if="!row.brandLogo"
                class="image"
                src="https://wsnbh.oss-cn-hangzhou.aliyuncs.com/default/1660282544838.png"
                alt=""
              />
              <div class="margin-r20">
                {{ row.brandName }}
              </div>
              <el-tooltip
                class="item"
                effect="dark"
                content="该品牌已被系统禁用"
                placement="top"
              >
                <span
                  v-if="row.brandState == 1"
                  class="disabled margin-r20"
                >已禁用</span>
              </el-tooltip>
              <el-tooltip
                class="item"
                effect="dark"
                content="请先设置品牌logo和名称"
                placement="top"
              >
                <span
                  v-if="!row.brandLogo"
                  class="disabled margin-r20"
                >未设置</span>
              </el-tooltip>
              <template v-if="activeTab === '2'">
                <!-- 编辑 -->
                <el-button
                  class="margin-r20"
                  type="text"
                  @click.stop="editBrand(row)"
                >
                  <i
                    v-if="brandType == '1' && people === 'gys'"
                    class="el-icon-edit "
                  ></i>
                </el-button>
                <!-- 删除 -->
                <el-button
                  class="margin-r20"
                  type="text"
                  @click.stop="deteleBrand(row)"
                >
                  <i
                    v-if="brandType == '1' && people === 'gys'"
                    class="el-icon-delete "
                  ></i>
                </el-button>
              </template>
              <template v-if="(activeTab === '3' || activeTab === '4') && row.isMustAuth===1">
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="请先上传品牌证明"
                  placement="top"
                >
                  <el-button
                    v-if="!row.authorizeAuthDto"
                    class="margin-r20"
                    type="text"
                    @click.stop="addProve(row)"
                  >
                    上传证明
                  </el-button>
                </el-tooltip>
                <el-button
                  v-if="row.authorizeAuthDto"
                  class="margin-r20"
                  type="text"
                  @click.stop="addProve(row)"
                >
                  查看证明
                </el-button>
              </template>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        small
        layout="prev, pager, next"
        :total="total"
        @current-change="currentChange"
      />
    </div>
    <span
      slot="footer"
    >
      <el-button
        size="small"
        @click="clearDialog"
      >取 消</el-button>
      <el-button
        type="primary"
        size="small"
        @click="onSubmit"
      >确 定</el-button>
    </span>

    <!-- 详情弹窗 -->
    <DetailDialog
      :id="detailId"
      :suppliers-id="suppliersId"
      :show="showDetail"
      @clear="showDetail = false"
      @success="getList"
    />
    <!-- 证明弹窗 -->
    <ProveDialog
      :prove="proveData"
      :show="showProve"
      @clear="showProve = false"
      @submit="submitProve"
    />
  </el-dialog>
</template>

<script setup>
import {
  ref, getCurrentInstance, onMounted, watchEffect, watch,
} from 'vue';
import BrandTag from './BrandTag.vue';
import DetailDialog from './DetailDialog.vue';
import ProveDialog from './ProveDialog.vue';

const { proxy } = getCurrentInstance();

const props = defineProps({
  show: {
    type: Boolean,
    default: false,
  },
  brandShowType: {
    type: Number,
    default: 0,
  },
  backendCategoryId: {
    type: String,
    default: '',
  },
  suppliersId: {
    type: String,
    default: '',
  },
  list: {
    type: Array,
    default: () => [],
  },
  max: {
    type: Number,
    default: 0,
  },
});

const emit = defineEmits(['clear', 'submit']);

const selectList = ref([]);
const tableRef = ref(null);
const loading = ref(false);
const searchName = ref('');
const page = ref(1);
const total = ref(0);
const activeTab = ref('');
const tableData = ref([]);
const brandType = ref('0');
const brandDomain = ref([1]); // 品牌域:1.自主品牌 2.省级公用品牌 3.区域公用品牌 4.其他品牌
const people = 'gys';
const showDetail = ref(false);
const detailId = ref();
const showProve = ref(false);
const allProveData = {}; // 证明缓存
const proveData = ref({});

const addProve = (row) => {
  showProve.value = true;
  proveData.value = {
    ...(row.authorizeAuthDto || {}),
    brandId: row.id,
    brandLogo: row.brandLogo,
    brandName: row.brandName,
  };
};

// 设置选中
const setSelect = (tableList, selectList) => {
  console.log(tableList, selectList);
  tableList.forEach((item) => {
    // 设置证明数据
    proxy.$set(item, 'authorizeAuthDto', allProveData[item.id]);
    // 勾选
    const find = selectList.find((f) => f.id === item.id);
    proxy.$set(item, 'checked', !!find);
  });
};
const getList = async () => {
  loading.value = true;
  const params = {
    currentPage: page.value,
    pageSize: 10,
    isShow: 0,
    name: searchName.value,
    brandDomains: brandDomain.value,
    brandType: brandType.value,
    backendCategoryId: props.backendCategoryId,
  };
  const { code, data } = await proxy.$axios.post(proxy.$api.brand.list, params);
  loading.value = false;
  console.log('清空表格');
  tableRef.value && tableRef.value.clearSelection();
  if (code !== 0) return;
  tableData.value = data.records;
  total.value = Number(data.total);
  // 回填勾选
  setSelect(tableData.value, selectList.value);
};

const submitProve = (row) => {
  // 设置已选数据证明
  const index = selectList.value.findIndex((f) => f.id === Number(row.brandId));
  if (index !== -1) {
    proxy.$set(selectList.value[index], 'authorizeAuthDto', row);
  }
  // 设置缓存
  allProveData[row.brandId] = row;
  getList();
};

const onSearch = () => {
  if (props.brandShowType === 2) {
    if (searchName.value) {
      brandDomain.value = [3, 4];
    } else {
      activeTab.value = '';
      queueMicrotask(() => { activeTab.value = '3'; });
      return;
    }
  }
  page.value = 1;
  getList();
};
const clearDialog = () => {
  emit('clear');
};

const onSubmit = () => {
  emit('submit', selectList.value);
  emit('clear');
};
// 分页
const currentChange = (e) => {
  page.value = e;
  getList();
};
// 禁用
const disabledCheck = (row) => {
  return row.brandState === 1 || !row.brandLogo || (row.isMustAuth === 1 && !row.authorizeAuthDto);
};
// 勾选
const changeCHecked = (row) => {
  function uncheck(type) {
    if (type === 'deleteFirst') {
      let first = selectList.value.shift();
      let index = tableData.value.findIndex((f) => f.id === first.id);
      if (index >= 0) {
        tableData.value[index].checked = false;
      }
    } else {
      row.checked = false;
      const index = selectList.value.findIndex((f) => f.id === row.id);
      selectList.value.splice(index, 1);
    }
  }
  if (row.checked) {
    selectList.value.push(row);
  } else {
    uncheck();
  }
  Promise.resolve().then(() => {
    if (selectList.value.length > props.max) {
      if (props.max === 1) {
        uncheck('deleteFirst');
      } else {
        uncheck();
        proxy.$message.error(`最多选${props.max}个`);
      }
    }
  });
};
// 编辑商家品牌
const editBrand = (row) => {
  detailId.value = row?.id;
  showDetail.value = true;
};
// 删除商家品牌
const deteleBrand = async (row) => {
  await proxy.$confirm('是否确认删除？');
  const { code } = await proxy.$axios.get(proxy.$api.brand.delete, { params: { id: row.id } });
  if (code !== 0) return;
  proxy.$message.success('操作成功！');
  // 取消勾选
  row.checked = false;
  changeCHecked(row);
  getList();
};
const open = () => {
  // 设置证明缓存
  selectList.value.forEach((item) => {
    if (item.authorizeAuthDto) {
      allProveData[item.id] = item.authorizeAuthDto;
    }
  });
};
// 清除单项
const closeTag = async (item) => {
  await proxy.$confirm('是否确认清除？');
  const index = selectList.value.findIndex((fin) => fin.id === item.id);
  selectList.value.splice(index, 1);
  getList();
};

watch(() => props.brandShowType, (val) => {
  activeTab.value = '';
  if (val === 1) activeTab.value = '1';
  if (val === 2) activeTab.value = '3';
  selectList.value = [...props.list];
});
watch(activeTab, (val) => {
  if (!val) return;
  if (val === '2') {
    brandDomain.value = [1];
    brandType.value = '1';
  } else {
    brandDomain.value = [val];
    brandType.value = '0';
  }
  page.value = 1;
  getList();
});
</script>

<style scoped lang="scss">
.brand-content{
  display: flex;
  align-items: center;
  min-height: 30px;
}
.image {
  --w: 50px;
  width: var(--w);
  height: var(--w);
  margin-right: 10px;
}
.disabled {
  border-radius: 2px;
  border: 1px solid #ccc;
  color: #959595;
  font-size: 12px;
  padding: 0px 4px;
}
</style>
