var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "component-bookRule" },
    [
      _c("h1", { staticClass: "title" }, [
        _vm._v(
          " " +
            _vm._s([8, 9].includes(+_vm.goodsType) ? "购买须知" : "预订规则") +
            " "
        ),
      ]),
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { model: _vm.forms, size: "mini", "label-width": "110px" },
        },
        [
          [8].includes(+_vm.goodsType)
            ? _c(
                "el-form-item",
                { attrs: { required: "", label: "卡名称：" } },
                [
                  _c("el-input", {
                    staticClass: "width360",
                    attrs: {
                      maxlength: "9",
                      "show-word-limit": "",
                      placeholder: "请输入卡名称",
                      size: "mini",
                      readonly: _vm.disabled,
                    },
                    model: {
                      value: _vm.forms.cardName,
                      callback: function ($$v) {
                        _vm.$set(_vm.forms, "cardName", $$v)
                      },
                      expression: "forms.cardName",
                    },
                  }),
                  _c("div", [_vm._v("例：采摘特惠卡")]),
                ],
                1
              )
            : _vm._e(),
          ![9].includes(+_vm.goodsType)
            ? _c(
                "el-form-item",
                { attrs: { required: "", label: "有效期：" } },
                [
                  ![8].includes(+_vm.goodsType)
                    ? [
                        _c(
                          "el-radio-group",
                          {
                            model: {
                              value: _vm.forms.times,
                              callback: function ($$v) {
                                _vm.$set(_vm.forms, "times", $$v)
                              },
                              expression: "forms.times",
                            },
                          },
                          [
                            _c("el-radio", { attrs: { label: 1 } }, [
                              _vm._v(" 永久有效 "),
                            ]),
                            _c(
                              "el-radio",
                              { attrs: { label: 2 } },
                              [
                                _c("span", { staticClass: "mRight10" }, [
                                  _vm._v("指定时间"),
                                ]),
                                _c(
                                  "el-date-picker",
                                  _vm._b(
                                    {
                                      staticStyle: { width: "360px" },
                                      attrs: { readonly: _vm.disabled },
                                      model: {
                                        value: _vm.date,
                                        callback: function ($$v) {
                                          _vm.date = $$v
                                        },
                                        expression: "date",
                                      },
                                    },
                                    "el-date-picker",
                                    _vm.dateConfig,
                                    false
                                  )
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "unDate-box" },
                          [
                            _c(
                              "el-checkbox",
                              {
                                staticClass: "mRight10",
                                model: {
                                  value: _vm.forms.unDate,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.forms, "unDate", $$v)
                                  },
                                  expression: "forms.unDate",
                                },
                              },
                              [_vm._v(" 不可用日期 ")]
                            ),
                            _c("el-input", {
                              staticClass: "unDate",
                              attrs: {
                                placeholder: "如：周末不可用；国庆不可用",
                                readonly: _vm.disabled,
                              },
                              model: {
                                value: _vm.unDateValue,
                                callback: function ($$v) {
                                  _vm.unDateValue = $$v
                                },
                                expression: "unDateValue",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    : _c(
                        "el-date-picker",
                        _vm._b(
                          {
                            staticStyle: { width: "360px" },
                            attrs: { readonly: _vm.disabled },
                            model: {
                              value: _vm.date,
                              callback: function ($$v) {
                                _vm.date = $$v
                              },
                              expression: "date",
                            },
                          },
                          "el-date-picker",
                          _vm.dateConfig,
                          false
                        )
                      ),
                ],
                2
              )
            : _vm._e(),
          ![8].includes(+_vm.goodsType)
            ? _c(
                "el-form-item",
                { attrs: { label: "预约规则：", required: "" } },
                [
                  ![9].includes(+_vm.goodsType)
                    ? _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.forms.rule,
                            callback: function ($$v) {
                              _vm.$set(_vm.forms, "rule", $$v)
                            },
                            expression: "forms.rule",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: 4 } }, [
                            _vm._v(" 无需预约 "),
                          ]),
                          _c(
                            "el-radio",
                            { attrs: { label: 5 } },
                            [
                              _c("span", { staticClass: "mRight10" }, [
                                _vm._v("需提前至少"),
                              ]),
                              _c("el-input", {
                                staticClass: "days",
                                attrs: { readonly: _vm.disabled },
                                model: {
                                  value: _vm.days,
                                  callback: function ($$v) {
                                    _vm.days = $$v
                                  },
                                  expression: "days",
                                },
                              }),
                              _vm._v(" 天预约 "),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _c("el-input", {
                        staticClass: "width360",
                        attrs: {
                          readonly: _vm.disabled,
                          maxlength: "25",
                          "show-word-limit": "",
                          placeholder: "例：16:00前可预约今日门票",
                          size: "mini",
                        },
                        model: {
                          value: _vm.appointmentValue,
                          callback: function ($$v) {
                            _vm.appointmentValue = $$v
                          },
                          expression: "appointmentValue",
                        },
                      }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            { attrs: { label: "售后政策：", required: "" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.forms.afterSale,
                    callback: function ($$v) {
                      _vm.$set(_vm.forms, "afterSale", $$v)
                    },
                    expression: "forms.afterSale",
                  },
                },
                [
                  [8].includes(+_vm.goodsType)
                    ? _c("el-radio", { attrs: { label: 9 } }, [
                        _vm._v(" 支持未使用过权益的订单退款 "),
                      ])
                    : [9].includes(+_vm.goodsType)
                    ? [
                        _c("el-radio", { attrs: { label: 7 } }, [
                          _vm._v(" 预约使用日24:00前，支持未核销订单申请退单 "),
                        ]),
                        _c("el-radio", { attrs: { label: 8 } }, [
                          _vm._v(" 下单后即不支持退单 "),
                        ]),
                      ]
                    : _c("el-radio", { attrs: { label: 6 } }, [
                        _vm._v(" 支持未核销申请退单 "),
                      ]),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "使用说明：", required: "" } },
            [
              _c(
                "el-input",
                _vm._b(
                  {
                    staticClass: "width360",
                    attrs: { readonly: _vm.disabled },
                    model: {
                      value: _vm.forms.text,
                      callback: function ($$v) {
                        _vm.$set(_vm.forms, "text", $$v)
                      },
                      expression: "forms.text",
                    },
                  },
                  "el-input",
                  _vm.textAreaConfig,
                  false
                )
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }