var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "组合规格",
        visible: _vm.dialogVisible,
        "before-close": _vm.clearModel,
        width: "1200px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
        open: _vm.openModel,
        closed: _vm.closedModel,
      },
    },
    [
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c("el-col", { attrs: { span: 13 } }, [
            _c(
              "div",
              { staticClass: "grid-content bg-purple" },
              [
                _c(
                  "el-card",
                  { staticClass: "box-card" },
                  [
                    _c(
                      "search-panel",
                      {
                        attrs: { flex: "", "show-btn": "", "is-entry": false },
                        on: { getList: _vm.getList },
                      },
                      [
                        _c(
                          "div",
                          [
                            _c("p", [_vm._v("商品ID")]),
                            _c("el-input", {
                              staticStyle: { width: "180px" },
                              attrs: {
                                clearable: "",
                                maxlength: 9,
                                size: "mini",
                                placeholder: "商品ID",
                              },
                              on: {
                                input: function ($event) {
                                  _vm.id = _vm.id.replace(/[^\d]/g, "")
                                },
                              },
                              model: {
                                value: _vm.id,
                                callback: function ($$v) {
                                  _vm.id =
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                },
                                expression: "id",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          [
                            _c("p", [_vm._v("商品名称")]),
                            _c("el-input", {
                              attrs: {
                                clearable: "",
                                size: "mini",
                                placeholder: "商品名称",
                              },
                              model: {
                                value: _vm.name,
                                callback: function ($$v) {
                                  _vm.name =
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                },
                                expression: "name",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                    _c(
                      "el-table",
                      {
                        directives: [
                          {
                            name: "loading",
                            rawName: "v-loading",
                            value: _vm.loading,
                            expression: "loading",
                          },
                        ],
                        staticStyle: { width: "100%" },
                        attrs: {
                          data: _vm.tableData,
                          "highlight-current-row": "",
                          "header-cell-style": {
                            background: "#dbdbdb",
                            color: "#0D0202",
                            "font-weight": 400,
                            fontSize: "12px",
                          },
                          "row-key": "goodsId",
                          "default-expand-all": true,
                        },
                      },
                      [
                        _c("el-table-column", {
                          attrs: { type: "expand" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function ({ row }) {
                                return [
                                  _c(
                                    "el-table",
                                    {
                                      staticStyle: {
                                        width: "85%",
                                        "margin-left": "auto",
                                      },
                                      attrs: {
                                        data: row.skuList,
                                        "header-cell-style": {
                                          "font-weight": 400,
                                          fontSize: "12px",
                                        },
                                      },
                                    },
                                    [
                                      _c("el-table-column", {
                                        attrs: { label: "商品规格信息" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function ({ row: rowChil }) {
                                                return [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        display: "flex",
                                                        "align-items": "center",
                                                      },
                                                    },
                                                    [
                                                      _c("el-image", {
                                                        staticStyle: {
                                                          width: "30px",
                                                          "margin-right":
                                                            "10px",
                                                        },
                                                        attrs: {
                                                          src: rowChil.imageIds.split(
                                                            ","
                                                          )[0],
                                                          "preview-src-list":
                                                            rowChil.imageIds.split(
                                                              ","
                                                            ),
                                                        },
                                                      }),
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            rowChil.specValue
                                                          )
                                                        ),
                                                      ]),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      }),
                                      _c("el-table-column", {
                                        attrs: {
                                          prop: "skuNo",
                                          label: "规格编号",
                                        },
                                      }),
                                      _c("el-table-column", {
                                        attrs: { label: "操作", width: "100" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function ({ row: rowChil }) {
                                                return [
                                                  _vm.isExist(
                                                    _vm.changeList,
                                                    rowChil
                                                  )
                                                    ? _c(
                                                        "el-link",
                                                        {
                                                          attrs: {
                                                            type: "danger",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.onChangeList(
                                                                rowChil,
                                                                "clear"
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [_vm._v(" 取消选择 ")]
                                                      )
                                                    : _c(
                                                        "el-link",
                                                        {
                                                          attrs: {
                                                            type: "primary",
                                                            size: "mini",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.onChangeList(
                                                                rowChil,
                                                                "add"
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [_vm._v(" 选择 ")]
                                                      ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c("el-table-column", {
                          attrs: { label: "商品名称" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function ({ row }) {
                                return [
                                  _c(
                                    "div",
                                    { staticStyle: { "font-weight": "bold" } },
                                    [_vm._v(" " + _vm._s(row.goodsName) + " ")]
                                  ),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c("el-table-column", {
                          attrs: { label: "商品ID" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function ({ row }) {
                                return [
                                  _c("span", [_vm._v(_vm._s(row.goodsId))]),
                                  _c(
                                    "el-button",
                                    {
                                      staticStyle: { "margin-left": "10px" },
                                      attrs: { type: "text", size: "mini" },
                                      on: {
                                        click: ($event) =>
                                          _vm.handleClipboard($event, row),
                                      },
                                    },
                                    [_vm._v(" 复制 ")]
                                  ),
                                ]
                              },
                            },
                          ]),
                        }),
                      ],
                      1
                    ),
                    _c("el-pagination", {
                      attrs: {
                        "current-page": _vm.pagination.currentPage,
                        "page-sizes": [10, 20, 30, 50],
                        "page-size": _vm.pagination.nowPageSize,
                        layout: "sizes, prev, pager, next, jumper",
                        total: _vm.pagination.count,
                      },
                      on: {
                        "size-change": _vm.handleSizeChange,
                        "current-change": _vm.handleCurrentChange,
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _c(
            "el-col",
            { attrs: { span: 11 } },
            [
              _c("div", { staticClass: "grid-content bg-purple" }),
              _c("el-card", { staticClass: "box-card" }, [
                _c(
                  "div",
                  { staticStyle: { "margin-bottom": "10" } },
                  [
                    _c(
                      "el-row",
                      {
                        staticStyle: {
                          display: "flex",
                          "align-items": "center",
                        },
                        attrs: { gutter: 0 },
                      },
                      [
                        _c("el-col", { attrs: { span: 7 } }, [
                          _c("span", { staticStyle: { color: "red" } }, [
                            _vm._v("*"),
                          ]),
                          _vm._v("组合规格名称 "),
                        ]),
                        _c(
                          "el-col",
                          { attrs: { span: 17 } },
                          [
                            _c("el-input", {
                              attrs: {
                                placeholder: "请输入规格名称",
                                size: "mini",
                                maxlength: "30",
                                "show-word-limit": "",
                              },
                              model: {
                                value: _vm.assSkuName,
                                callback: function ($$v) {
                                  _vm.assSkuName = $$v
                                },
                                expression: "assSkuName",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticStyle: {
                      display: "flex",
                      "align-items": "center",
                      "justify-content": "space-between",
                    },
                  },
                  [
                    _c("div", [
                      _vm._v(
                        "已选择 " +
                          _vm._s(_vm.changeList.length) +
                          " 个现有规格"
                      ),
                    ]),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", size: "min" },
                        on: { click: _vm.allClear },
                      },
                      [_vm._v(" 清空 ")]
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  [
                    _c(
                      "el-table",
                      {
                        staticStyle: { width: "100%" },
                        attrs: {
                          data: _vm.changeList,
                          border: "",
                          "highlight-current-row": "",
                          "header-cell-style": {
                            background: "#dbdbdb",
                            color: "#0D0202",
                            "font-weight": 400,
                            fontSize: "12px",
                          },
                          "row-key": "id",
                        },
                      },
                      [
                        _c("el-table-column", {
                          attrs: { label: "商品规格信息" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function ({ row }) {
                                return [
                                  _c("div", [
                                    _vm._v(
                                      "商品名称：" + _vm._s(row.goodsName)
                                    ),
                                  ]),
                                  _c("div", [
                                    _vm._v("商品ID：" + _vm._s(row.goodsId)),
                                  ]),
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        display: "flex",
                                        "align-items": "center",
                                      },
                                    },
                                    [
                                      row.imageIds
                                        ? _c("el-image", {
                                            staticStyle: {
                                              width: "30px",
                                              "margin-right": "10px",
                                            },
                                            attrs: {
                                              src: row.imageIds.split(",")[0],
                                              "preview-src-list":
                                                row.imageIds.split(","),
                                            },
                                          })
                                        : _vm._e(),
                                      _c("div", [
                                        _c("div", [
                                          _vm._v(
                                            "规格名称：" + _vm._s(row.specValue)
                                          ),
                                        ]),
                                        _c("div", [
                                          _vm._v(
                                            "规格编号：" + _vm._s(row.skuNo)
                                          ),
                                        ]),
                                      ]),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c("el-table-column", {
                          attrs: { label: "数量", width: "150" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function ({ row }) {
                                return [
                                  _c("el-input-number", {
                                    attrs: { size: "mini", min: 1, max: 9999 },
                                    model: {
                                      value: row.singleSkuCount,
                                      callback: function ($$v) {
                                        _vm.$set(row, "singleSkuCount", $$v)
                                      },
                                      expression: "row.singleSkuCount",
                                    },
                                  }),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c(
                          "el-table-column",
                          {
                            attrs: { label: "操作", width: "60" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function ({ row }) {
                                  return [
                                    _c(
                                      "el-link",
                                      {
                                        attrs: { type: "danger" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.onChangeList(
                                              row,
                                              "clear"
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v(" 移除 ")]
                                    ),
                                  ]
                                },
                              },
                            ]),
                          },
                          [_vm._v(" > ")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _c(
                "el-row",
                {
                  staticClass: "row-bg",
                  staticStyle: { "margin-top": "20px" },
                  attrs: { type: "flex", justify: "end" },
                },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 7 } },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", size: "small" },
                          on: { click: _vm.onSubmit },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.specValueId ? "保存" : "组合成新规格"
                              ) +
                              " "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }