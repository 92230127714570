var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { "margin-bottom": "70px" } },
    [
      _c("h1", { staticClass: "title" }, [
        _vm._v(" " + _vm._s(_vm.title) + " "),
      ]),
      _c(
        "el-form",
        {
          ref: "ruleForm",
          staticClass: "demo-ruleForm",
          attrs: { model: _vm.ruleForm, "label-width": "120px" },
        },
        [
          _c(
            "el-form-item",
            {
              attrs: {
                label: "请输入" + _vm.title,
                prop: "input",
                rules: [
                  {
                    required: _vm.required,
                    message: "请输入" + _vm.title,
                    trigger: "blur",
                  },
                ],
              },
            },
            [
              _c("el-input", {
                staticStyle: { width: "360px" },
                attrs: {
                  placeholder: "请输入内容" + _vm.title,
                  size: "mini",
                  readonly: _vm.disabled,
                },
                model: {
                  value: _vm.ruleForm.input,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.ruleForm,
                      "input",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "ruleForm.input",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }