var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mb50" },
    [
      _c("h1", { staticClass: "title" }, [_vm._v(" 销售对象 ")]),
      _c(
        "el-form",
        { attrs: { "label-width": "110px" } },
        [
          _vm.people === "gys"
            ? _c(
                "el-form-item",
                { attrs: { label: "销售对象：", required: "" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      attrs: { disabled: _vm.disabled },
                      model: {
                        value: _vm.radio,
                        callback: function ($$v) {
                          _vm.radio = $$v
                        },
                        expression: "radio",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 0 } }, [
                        _vm._v(" 个人 "),
                      ]),
                      _c("el-radio", { attrs: { label: 1 } }, [
                        _vm._v(" 企业 "),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.people === "admin" && _vm.radio === 1
            ? _c(
                "el-form-item",
                { attrs: { label: "销售对象：", required: "" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.radio,
                        callback: function ($$v) {
                          _vm.radio = $$v
                        },
                        expression: "radio",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 1 } }, [
                        _vm._v(" 企业 "),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }