<template>
  <el-dialog
    title="组合规格"
    :visible.sync="dialogVisible"
    :before-close="clearModel"
    width="1200px"
    @open="openModel"
    @closed="closedModel"
  >
    <el-row :gutter="20">
      <!-- 左边 -->
      <el-col :span="13">
        <div class="grid-content bg-purple">
          <el-card class="box-card">
            <search-panel
              flex
              show-btn
              :is-entry="false"
              @getList="getList"
            >
              <div>
                <p>商品ID</p>
                <el-input
                  v-model.trim="id"
                  style="width: 180px"
                  clearable
                  :maxlength="9"
                  size="mini"
                  placeholder="商品ID"
                  @input="id=id.replace(/[^\d]/g,'')"
                />
              </div>
              <div>
                <p>商品名称</p>
                <el-input
                  v-model.trim="name"
                  clearable
                  size="mini"
                  placeholder="商品名称"
                />
              </div>
            </search-panel>

            <el-table
              v-loading="loading"
              :data="tableData"

              style="width: 100%"
              highlight-current-row
              :header-cell-style="{
                background: '#dbdbdb',
                color: '#0D0202',
                'font-weight': 400,
                fontSize: '12px'
              }"
              row-key="goodsId"
              :default-expand-all="true"
            >
              <el-table-column type="expand">
                <template slot-scope="{row}">
                  <el-table
                    :data="row.skuList"
                    style="width: 85%; margin-left: auto"
                    :header-cell-style="{
                      'font-weight': 400,
                      fontSize: '12px'
                    }"
                  >
                    <el-table-column
                      label="商品规格信息"
                    >
                      <template slot-scope="{row: rowChil}">
                        <div style="display: flex;align-items: center;">
                          <el-image
                            style="width: 30px;margin-right: 10px"
                            :src="rowChil.imageIds.split(',')[0]"
                            :preview-src-list="rowChil.imageIds.split(',')"
                          />
                          <span>{{ rowChil.specValue }}</span>
                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column
                      prop="skuNo"
                      label="规格编号"
                    />
                    <el-table-column
                      label="操作"
                      width="100"
                    >
                      <template slot-scope="{row: rowChil}">
                        <el-link
                          v-if="isExist(changeList, rowChil)"
                          type="danger"
                          @click="onChangeList(rowChil, 'clear')"
                        >
                          取消选择
                        </el-link>
                        <el-link
                          v-else
                          type="primary"
                          size="mini"
                          @click="onChangeList(rowChil, 'add')"
                        >
                          选择
                        </el-link>
                      </template>
                    </el-table-column>
                  </el-table>
                </template>
              </el-table-column>
              <el-table-column
                label="商品名称"
              >
                <template slot-scope="{row}">
                  <div style="font-weight: bold;">
                    {{ row.goodsName }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                label="商品ID"
              >
                <template slot-scope="{row}">
                  <span>{{ row.goodsId }}</span>
                  <el-button
                    type="text"
                    size="mini"
                    style="margin-left: 10px;"
                    @click="($event)=> handleClipboard($event, row)"
                  >
                    复制
                  </el-button>
                </template>
              </el-table-column>
            </el-table>

            <el-pagination
              :current-page="pagination.currentPage"
              :page-sizes="[10, 20, 30, 50]"
              :page-size="pagination.nowPageSize"
              layout="sizes, prev, pager, next, jumper"
              :total="pagination.count"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
            />
          </el-card>
        </div>
      </el-col>
      <!-- 右边 -->
      <el-col :span="11">
        <div class="grid-content bg-purple"></div>
        <el-card class="box-card">
          <div style="margin-bottom: 10">
            <el-row
              :gutter="0"
              style="display: flex;align-items: center;"
            >
              <el-col :span="7">
                <span style="color: red">*</span>组合规格名称
              </el-col>
              <el-col :span="17">
                <el-input
                  v-model="assSkuName"
                  placeholder="请输入规格名称"
                  size="mini"
                  maxlength="30"
                  show-word-limit
                />
              </el-col>
            </el-row>
          </div>

          <div style="display: flex; align-items: center; justify-content: space-between;">
            <div>已选择 {{ changeList.length }} 个现有规格</div>
            <el-button
              type="text"
              size="min"
              @click="allClear"
            >
              清空
            </el-button>
          </div>

          <div>
            <el-table
              :data="changeList"
              border
              style="width: 100%"
              highlight-current-row
              :header-cell-style="{
                background: '#dbdbdb',
                color: '#0D0202',
                'font-weight': 400,
                fontSize: '12px'
              }"
              row-key="id"
            >
              <el-table-column
                label="商品规格信息"
              >
                <template slot-scope="{row}">
                  <div>商品名称：{{ row.goodsName }}</div>
                  <div>商品ID：{{ row.goodsId }}</div>
                  <div style="display: flex; align-items: center;">
                    <el-image
                      v-if="row.imageIds"
                      style="width: 30px;margin-right: 10px"
                      :src="row.imageIds.split(',')[0]"
                      :preview-src-list="row.imageIds.split(',')"
                    />
                    <div>
                      <div>规格名称：{{ row.specValue }}</div>
                      <div>规格编号：{{ row.skuNo }}</div>
                    </div>
                  </div>
                </template>
              </el-table-column>

              <el-table-column
                label="数量"
                width="150"
              >
                <template slot-scope="{row}">
                  <el-input-number
                    v-model="row.singleSkuCount"
                    size="mini"
                    :min="1"
                    :max="9999"
                  />
                </template>
              </el-table-column>

              <el-table-column
                label="操作"
                width="60"
              >
                >
                <template slot-scope="{row}">
                  <el-link
                    type="danger"
                    @click="onChangeList(row, 'clear')"
                  >
                    移除
                  </el-link>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-card>

        <el-row
          type="flex"
          class="row-bg"
          justify="end"
          style="margin-top: 20px"
        >
          <el-col :span="7">
            <el-button
              type="primary"
              size="small"
              @click="onSubmit"
            >
              {{ specValueId ? '保存' : '组合成新规格' }}
            </el-button>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
  </el-dialog>
</template>

<script>
import clipboard from '@/utils/clipboard';

export default {
  name: '',
  components: {},
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    specValueList: {
      type: Array,
      default: () => [],
    },
    specValueId: {
      type: String,
      default: '',
    },
    assSpecId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      loading: false,
      id: '',
      name: '',
      tableData: [],
      // 分页
      pagination: {
        currentPage: 1,
        nowPageSize: 10,
        count: 999,
      },

      assSkuName: '', // 规格名称
      oldSkuData: {}, // 编辑之前的对象
      changeList: [], // 选中列表
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {

  },
  methods: {
    onSubmit() {
      if (!this.assSkuName) {
        this.$message.error('请输入组合规格名称');
        return;
      }
      if (this.changeList.length === 0) {
        this.$message.error('请选择组合规格');
        return;
      }
      let mseeage = this.specValueId ? '是否确认保存' : '是否确认组合成新规格?';
      this.$confirm(mseeage, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(async () => {
        // let oldData;
        // if(specValueId) {
        //   oldData = this.specValueList.find( fin => fin.specKey == this.specValueId);
        // }
        let res;
        // 编辑了规格名称，重新生成id
        if (this.assSkuName !== this.oldSkuData.name) {
          if (this.specValueList.find((item) => item.name == this.assSkuName)) {
            this.$message.error('规格名称已存在');
            return;
          }
          res = await this.getSpecValueIdApi(this.assSkuName);
        } else {
          res = {
            data: this.specValueId,
          };
        }
        let arr = [...this.specValueList];
        let skuData = {
          name: this.assSkuName,
          assemblySkuList: this.changeList,
          specId: this.assSpecId,
          specKey: res.data,
          id: res.data,
        };
        if (this.assSkuName == this.oldSkuData.name) {
          // 名字不修改，修改列表
          let find = this.specValueList.find((fin) => fin.id == this.specValueId);
          find.assemblySkuList = this.changeList;
        } else {
          // 修改了名字
          if (this.specValueId) {
            // 编辑
            // arr.push({...this.oldSkuData, isDelete: 1});
            arr.splice(this.specValueList.findIndex((fin) => fin.specKey == this.specValueId), 1, skuData);
          } else {
            // 新增
            arr.push(skuData);
          }
        }
        // 移除标签
        this.$emit('changeGoods');
        this.$emit('update:specValueList', arr);
        this.$emit('update:dialogVisible', false);
      }).catch();
    },
    // 新增规格值id
    getSpecValueIdApi(name) {
      return new Promise((resolve, rejevt) => {
        this.$axios
          .post(this.$api.goods_spec.add_spec_value, {
            specId: this.assSpecId,
            name,
          })
          .then((res) => {
            resolve(res);
          });
      });
    },
    // 清空
    allClear() {
      this.changeList = [];
    },
    // 选择/清除
    onChangeList(row, type) {
      if (type === 'add') {
        this.$set(row, 'singleSkuCount', 1);
        this.changeList.push(row);
      } else {
        this.changeList.splice(this.changeList.indexOf(row), 1);
      }
    },
    clearModel() {
      this.$emit('update:dialogVisible', false);
    },
    openModel() {
      if (this.tableData.length === 0) this.getList();
      if (this.specValueId) {
        // 编辑
        let find = this.specValueList.find((fin) => fin.id == this.specValueId);
        this.assSkuName = find.name;// 规格名称
        this.oldSkuData = find;// 规格名称
        this.changeList = find.assemblySkuList;// 选中列表
      }
      // else {
      //   //新增
      // }
    },
    // 关闭弹窗
    closedModel() {
      this.changeList = [];
      this.assSkuName = '';
      this.oldSkuData = {};
      this.$emit('update:specValueId', '');
    },
    getList() {
      this.loading = true;
      this.$axios
        .post(this.$api.goodsmanage.getSkuByCondition, {
          currentPage: this.pagination.currentPage,
          pageSize: this.pagination.nowPageSize,
          goodsId: this.id,
          goodsName: this.name,
        })
        .then((res) => {
          if (res.code === 0) {
            this.tableData = res.data;
            // this.pagination.count = res.data.length;
          } else {
            this.tableData = [];
          }
          this.loading = false;
        });
    },
    // 分页控制
    handleSizeChange(val) {
      this.pagination.nowPageSize = val;
      this.pagination.currentPage = 1;
      this.getList();
    },
    handleCurrentChange(val) {
      this.pagination.currentPage = val;
      this.getList();
    },
    handleClipboard(event, row) {
      console.log(row);
      clipboard(row.goodsId, event);
    },
    // 查询数组中是否存在含指定id的对象
    isExist(arr, obj) {
      return !!arr.find((item) => item.skuId == obj.skuId);
    },
  },
};
</script>

<style scoped lang="scss">

</style>
