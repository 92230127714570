<template>
  <div class="brand_model">
    <h1 class="add_goods_title">
      标签<span>(最多选择4个标签，蓝色为平台提供标签，橙色为商家自定义标签)</span>
    </h1>
    <el-form
      label-width="110px"
      class="demo-ruleForm"
    >
      <el-form-item
        label="关联标签："
        prop="checkList"
        required
      >
        <div v-if="!tradeServiceTagDtos || tradeServiceTagDtos.length === 0">
          未选择标签
        </div>
        <div v-if="tradeServiceTagDtos && tradeServiceTagDtos.length > 0">
          <span
            v-for="(item,i) in tradeServiceTagDtos"
            :key="i"
          >
            <el-tag
              style="margin: 0 0 0 10px;"
              :type="item.belongType == 2 ? 'warning' : ''"
              :closable="!disabled"
              @close="onCloseTab(item, i)"
            >
              {{ item.name }}
            </el-tag>
            <div
              v-if="item.coverImgUrlList && item.coverImgUrlList.length > 0"
              style="margin-right: 20px;
              display: inline-block;
              color: #1890ff;
              text-decoration:underline;
              cursor: pointer;
              transform: translate(5px,9px);
              line-height: 20px;"
              type="primary"
              @click="onProve(item, 'show')"
            >查看证明</div>
          </span>
        </div>
        <el-button
          v-if="!disabled"
          type="primary"
          size="small"
          @click="onShowTagList"
        >
          选择标签
        </el-button>
      </el-form-item>
      <el-form-item label="提示：">
        <div>
          您需要对勾选的标签内容负责，请按照实际情况勾选标签。
        </div>
      </el-form-item>
    </el-form>

    <el-dialog
      title="选择标签"
      :visible.sync="dialogVisible"
      width="800px"
      :close-on-click-modal="false"
      @open="openModel"
    >
      <div v-loading="loading">
        <div style="margin-bottom: 10px;min-height: 35px;">
          已选择标签：
          <el-tag
            v-for="(item,i) in labelList"
            :key="i"
            style="margin: 0 10px 0 0;"
            :type="item.belongType == 2 ? 'warning' : ''"
            closable
            @close="onCloseTab(item, i)"
          >
            {{ item.name }}
          </el-tag>
        </div>

        <div style="margin-bottom: 10px;">
          <el-input
            v-model="name"
            size="small"
            placeholder="请输入标签名称"
            clearable
            style="width: 200px; margin-right: 10px;"
            @clear="search"
          />
          <el-button
            size="small"
            type="primary"
            @click="search"
          >
            搜索
          </el-button>
          <el-button
            v-if="people === 'gys'"
            size="small"
            type="primary"
            @click="showDetailBrand = true"
          >
            新增标签
          </el-button>
        </div>

        <div>
          <el-tabs
            v-if="belongType == '1' || belongType == '2'"
            v-model="belongType"
            type="card"
            @tab-click="changeTabActive"
          >
            <el-tab-pane
              label="平台提供"
              name="1"
            />
            <el-tab-pane
              label="自定义标签"
              name="2"
            />
          </el-tabs>
          <el-tabs
            v-if="tagGroupTab && belongType == '1'"
            v-model="tagGroupTab"
            @tab-click="changeTabGroupActive"
          >
            <el-tab-pane
              v-for="(item,i) in tagGroupTabList"
              :key="i"
              :label="item.name"
              :name="item.id.toString()"
            />
          </el-tabs>

          <el-table
            ref="tagTable"
            :data="tableData"
            row-key="id"
            style="width: 100%"
            @select-all="selectAll"
            @select="selectionChange"
          >
            <el-table-column
              type="selection"
              reserve-selection
              :selectable="(row, rowIndex)=> {
                if(row.status != 1 || (row.isProve == 1 && !row.coverImgUrlList) || ( row.isProve == 1 && row.coverImgUrlList.length === 0)) {
                  return false;
                }else {
                  return true;
                }
              }"
              width="55"
            />
            <el-table-column
              fixed
              prop="name"
              label="标签名称"
            >
              <template slot-scope="{row}">
                <template>
                  <el-tooltip
                    v-if="(row.isProve == 1 && !row.coverImgUrlList) || ( row.isProve == 1 && row.coverImgUrlList.length === 0)"
                    effect="dark"
                    content="请先上传证明再勾选标签"
                    placement="top"
                  >
                    <span>{{ row.name }}</span>
                  </el-tooltip>
                  <span v-else>{{ row.name }}</span>
                </template>
                <i
                  v-if="row.status == 1 && people === 'gys' && belongType == '2' && !labelList.find( find=> find.id == row.id)"
                  style="margin-left: 20px;color: red;"
                  class="el-icon-delete"
                  @click="deteleBrand(row)"
                ></i>
                <span v-if="row.isProve == 1">
                  <el-link
                    v-if="belongType == '1'"
                    style="margin-left: 10px;"
                    type="primary"
                    @click="onProve(row)"
                  >
                    {{ row.coverImgUrlList && row.coverImgUrlList.length > 0 ? '查看证明' : '上传证明' }}
                  </el-link>
                </span>

                <span
                  v-if="row.status != 1"
                  style="margin-left: 10px;height: 18px;line-height: 12px;border-radius: 2px; border: 1px solid #C0C4CC;font-size: 12px; padding: 2px 4px;color: #C0C4CC;"
                >已禁用</span>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            v-if="tableData && tableData.length > 0"
            :current-page="pagination.currentPage"
            :page-sizes="[10, 20, 30, 50]"
            :page-size="pagination.nowPageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="pagination.count"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          />
        </div>
      </div>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          size="small"
          @click="dialogVisible = false"
        >取 消</el-button>
        <el-button
          type="primary"
          size="small"
          @click="onSubmit"
        >确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog
      :title="brandDetail.id ? '编辑标签' : '新增标签'"
      :visible.sync="showDetailBrand"
      width="400px"
      @closed="brandDetail.brandName = ''"
    >
      <div>
        <el-input
          v-model="brandDetail.brandName"
          placeholder="请输入"
          size="small"
          maxlength="6"
          show-word-limit
        />
      </div>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          size="small"
          @click="showDetailBrand = false"
        >取 消</el-button>
        <el-button
          :disabled="!brandDetail.brandName"
          type="primary"
          size="small"
          @click="onEditBrand"
        >确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog
      :title="'证明管理'"
      :visible.sync="showProveModel"
      width="600px"
    >
      <el-form
        v-if="tagData"
        label-width="110px"
        class="demo-ruleForm"
      >
        <el-form-item label="标签名称：">
          {{ tagData.name }}
        </el-form-item>
        <el-form-item label="证明图片：">
          <UpImage
            :num="10"
            :image-list.sync="proveImageList"
            :disabled="tagData.type == 'show'"
          />
          <div>建议：800px*800px，2M以内；图片可拖动排序；限10张。</div>
        </el-form-item>
      </el-form>
      <span
        v-if="tagData && tagData.type != 'show'"
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          :disabled="proveImageList.length === 0"
          type="primary"
          size="small"
          @click="onSaveProve"
        >保存</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import UpImage from '@/components/common/upImage/index';

export default {
  components: {
    UpImage,
  },
  props: ['disabled', 'people', 'tradeServiceTagDtos', 'isAssembly'],
  data() {
    return {
      pageType: '',
      labelList: [],

      dialogVisible: false,
      goodsIdList: [],
      tableData: [],
      // 分页
      pagination: {
        currentPage: 1,
        nowPageSize: 10,
        count: 0,
      },
      loading: false,
      name: '',
      showDetailBrand: false, // 编辑自定义标签
      belongType: '1',
      tagGroupTabInfo: '',
      tagGroupTab: '',
      tagGroupTabList: [],
      brandDetail: {
        brandName: '',
      }, // 详情

      showProveModel: false, // 展示证明
      imageList: [],
      tagData: null,
      proveImageList: [], // 上传后的图片集合
      upProveList: [],
      suppliersId: '', // 商家id
      backendCategoryId: '', // 商品分类id
    };
  },
  watch: {
    tradeServiceTagDtos(val) {
      if (val) {
        this.labelList = val;
        for (let item of val) {
          if (item.coverImgUrlList) {
            this.upProveList.push(item);
          }
        }
      }
    },
  },
  mounted() {
    this.pageType = this.$route.query.type;
    this.suppliersId = this.$route.query.suppliersId;
    this.backendCategoryId = this.$route.query.backendCategoryId;
  },
  methods: {
    onShowTagList() {
      if (this.isAssembly) {
        let list = this.$parent.$refs.skuInfo.skuTable.filter((item) => item.isDelete != 1);
        let goodsIdList = [];
        for (let item of list) {
          for (let value of item.assemblySkuList) {
            goodsIdList.push(value.goodsId);
          }
        }
        console.log(goodsIdList);
        if (goodsIdList.length === 0) {
          this.$message.error('请先完善组合规格');
          return;
        }
        this.goodsIdList = goodsIdList;
      }
      this.dialogVisible = true;
    },
    // 上传证明
    onProve(row, type) {
      this.tagData = { ...row, type };
      if (row.coverImgUrlList) {
        this.proveImageList = [...row.coverImgUrlList];
      }
      this.showProveModel = true;
    },
    // 保存证明
    onSaveProve() {
      this.showProveModel = false;
      // this.$refs.tagTable.toggleRowSelection(this.tagData,false);

      // this.$set(this.tagData, 'coverImgUrlList', this.proveImageList);
      this.$set(this.tableData.find((find) => find.id == this.tagData.id), 'coverImgUrlList', this.proveImageList);

      // this.proveImageList = [];
      // 上传过图片的数据
      let find = this.upProveList.find((find) => find.id == this.tagData.id);
      if (find) {
        find.coverImgUrlList = this.proveImageList;
      } else {
        this.upProveList.push({
          ...this.tagData,
          coverImgUrlList: this.proveImageList,
        });
      }
      this.proveImageList = [];
      this.tagData = null;
    },
    openModel() {
      // if(this.tableData.length === 0) this.getList();
      this.getTabList();
    },
    changeTabGroupActive() {
      this.getList();
    },
    getTabList() {
      this.$axios
        .post(this.$api.goods_supplier_tag.tagGroupList, {
          backendCategoryId: this.backendCategoryId,
        })
        .then((res) => {
          if (res.code === 0) {
            this.tagGroupTabList = res.data;
            this.tagGroupTab = res.data[0].id.toString();
            this.tagGroupTabInfo = this.tagGroupTab;
            this.getList();
          } else {
            this.tagGroupTabList = [];
          }
          this.loading = false;
        });
    },
    // getListInit() {
    //   // this.page = 1;
    //   this.belongType = '1';
    //   this.getList();
    // },
    search() {
      if (this.name && !this.isAssembly) {
        this.belongType = '';
        this.tagGroupTab = '';
      } else if (this.name && this.isAssembly) {
        this.tagGroupTab = '';
      } else {
        this.belongType = '1';
        this.tagGroupTab = this.tagGroupTabInfo;
      }
      this.getList();
    },
    changeTabActive() {
      this.pagination.currentPage = 1;
      this.getList();
    },
    // currentChange(e) {
    //   // this.page = e;
    //   this.getList();
    // },
    getList() {
      this.loading = true;
      this.$axios
        .post(this.$api.goods_supplier_tag.page, {
          currentPage: this.pagination.currentPage,
          pageSize: this.pagination.nowPageSize,
          tagName: this.name,
          belongType: this.belongType,
          tagGroupId: this.belongType == 1 ? this.tagGroupTab : '',
          goodsIdList: this.isAssembly && this.belongType == 1 ? this.goodsIdList : undefined,
          supplierId: this.suppliersId,
          backendCategoryId: !this.isAssembly && this.belongType == 1 ? this.backendCategoryId : undefined,
        })
        .then((res) => {
          if (res.code === 0) {
            this.tableData = res.data.records;
            for (let item of this.tableData) {
              let find = this.upProveList.find((find) => find.id == item.id);
              if (find) {
                this.$set(item, 'coverImgUrlList', find.coverImgUrlList);
              }
            }
            this.pagination.count = Number(res.data.total);
          } else {
            this.tableData = [];
          }
          this.loading = false;

          this.$refs.tagTable.clearSelection();
          for (let item of this.labelList) {
            this.$refs.tagTable.toggleRowSelection(item, true);
          }
        });
    },
    // 分页控制
    handleSizeChange(val) {
      this.pagination.nowPageSize = val;
      this.pagination.currentPage = 1;
      this.getList();
    },
    handleCurrentChange(val) {
      this.pagination.currentPage = val;
      this.getList();
    },
    onSubmit() {
      for (let item of this.labelList) {
        let find = this.upProveList.find((find) => find.id == item.id);
        if (find) {
          this.$set(item, 'coverImgUrlList', find.coverImgUrlList);
        }
      }
      this.$emit('update:tradeServiceTagDtos', this.labelList);
      this.dialogVisible = false;
    },
    selectionChange(e) {
      if (e.length > 4) {
        this.$message.warning('最多只能选择4个标签');
        let arr = e.slice(0, 4);
        this.$refs.tagTable.clearSelection();
        for (let item of arr) {
          this.$refs.tagTable.toggleRowSelection(item, true);
        }
        this.labelList = arr;
      } else {
        this.labelList = e;
      }
      console.log(this.labelList);
    },
    selectAll() {
      // this.$refs.tagTable.clearSelection();
      // this.$message.warning('最多选择4个标签');
    },
    deteleBrand(row) {
      console.log(row);
      this.$confirm('删除标签，会把商品中已勾选的标签删除，确认删除？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.$axios
            .get(this.$api.goods_supplier_tag.delete, {
              params: { supplierTagId: row.id },
            })
            .then((res) => {
              if (res.code === 0) {
                this.$message.success('操作成功');
                this.getList();
              // let find = this.labelList.find( find=> find.id == row.id);
              // if(find) {
              //   this.labelList.splice(this.labelList.indexOf(find), 1);
              //   this.$refs.tagTable.clearSelection();
              //   for(let item of this.labelList) {
              //     this.$refs.tagTable.toggleRowSelection(item,true);
              //   }
              // }
              }
            });
        })
        .catch(() => {});
    },
    // 确定编辑品牌
    onEditBrand() {
      this.$axios
        .post(this.$api.goods_supplier_tag.add, {
          name: this.brandDetail.brandName,
          belongType: 2,
        })
        .then((res) => {
          if (res.code === 0) {
            this.$message.success('操作成功');
            this.showDetailBrand = false;
            this.getList();
          }
        });
    },
    onCloseTab(row, i) {
      this.labelList.splice(i, 1);
      if (this.$refs.tagTable) {
        let nowTab = this.labelList;
        this.$refs.tagTable.clearSelection();
        for (let item of nowTab) {
          this.$refs.tagTable.toggleRowSelection(item, true);
        }
        this.getList();
      }
    },
  },
};
</script>

<style lang="scss">
.brand_model {
  .el-pagination {
    text-align: left;
  }
  .el-table__header .el-checkbox {
    display: none
  }
}

</style>
