<template>
  <div class="showAttr">
    <!-- v-show="!disabled || childreanRuleForm[item.id]" -->
    <el-form-item
      v-for="(item, i) in inputList"
      :key="i"
      :label="item.name + '：'"
      :required="item.isRequire == 1 ? true : false"
    >
      <el-input
        v-if="item.valueType == '1' "
        v-model="childreanRuleForm[item.id]"
        size="mini"
        show-word-limit
        :placeholder="'请输入' + item.name"
        :readonly="disabled"
        :disabled="item.status === 0"
        @input="onInputUnit(item.id, item.tradeUnitDto)"
      >
        <span
          v-if="item.unitName"
          slot="append"
        >
          {{ item.unitName }}
        </span>
        <div v-if="item.unitName && item.belongType == 2 && !disabled" slot="append" style="display: inline-block; margin: 0 10px; width: 1px;background: #ccc;margin-right: 10px;height: 10px;"></div>
        <span slot="append" v-if="item.belongType == 2 && !disabled">
          <el-button icon="el-icon-edit" size="mini" @click="editAttr(item, childreanRuleForm[item.id])"></el-button>
          <el-button icon="el-icon-delete" size="mini" @click="deteleAttr(item)"></el-button>
        </span>
      </el-input>
      <el-radio-group
        v-if="item.valueType == '2'"
        v-model="childreanRuleForm[item.id]"
        size="mini"
        :disabled="disabled"
      >
        <el-radio
          v-for="(value, index) in item.tradeAttributeValueVoList"
          :key="index"
          :label="value.value"
        >
         
        </el-radio>
      </el-radio-group>
      <el-checkbox-group
        v-if="item.valueType == '3'"
        v-model="childreanRuleForm[item.id]"
        size="mini"
        :disabled="disabled"
      >
        <el-checkbox
          v-for="(value, index) in item.tradeAttributeValueVoList"
          :key="index"
          :label="value.value"
        >
        </el-checkbox>
      </el-checkbox-group>
      <el-select
        v-if="item.valueType == '4'"
        v-model="childreanRuleForm[item.id]"
        size="mini"
        :disabled="disabled"
        :placeholder="'请选择' + item.name"
        clearable
      >
        <el-option
          v-for="(value, index) in item.tradeAttributeValueVoList"
          :key="index"
          :label="value.value"
          :value="value.id"
        ></el-option>
      </el-select>
      <el-input
        v-if="item.valueType == '5'"
        v-model="childreanRuleForm[item.id]"
        size="mini"
        readonly
      ></el-input>
      <el-input
        v-if="item.valueType == '6'"
        v-model="childreanRuleForm[item.id]"
        class="my_textarea"
        type="textarea"
        size="mini"
        :autosize="{minRows: 2, maxRows: 4}"
        maxlength="1000"
        show-word-limit
        :placeholder="'请输入' + item.name"
        :readonly="disabled"
      >
      </el-input>
    </el-form-item>
  </div>
</template>

<script>
export default {
  name: 'AttrEdit',
  props: ['disabled','myTradeAttributeDtos', 'ruleForm', 'pageType'],
  data() {
    return {
      inputList: [],
      childreanRuleForm: {},
      
    }
  },
  watch: {
    myTradeAttributeDtos(item) {
      console.log('1111-----------', item)
      this.inputList = item
    },
    ruleForm(item) {
      console.log('222------------------', item)
      this.childreanRuleForm = item
    },
    childreanRuleForm(item) {
      this.$emit('changeRuleForm', item)
    },
  },
  methods: {
    editAttr(row, value) {
      this.$emit('editAttr', {...row, value})
    },
    deteleAttr(row) {
      this.$emit('deteleAttr', row)
    },
    onInputUnit(model, unit) {
      if(!unit || unit.dataType === 0) {
        this.childreanRuleForm[model] = this.childreanRuleForm[model];
      }else if(unit.dataType === 1) {
        this.childreanRuleForm[model] = this.childreanRuleForm[model].replace(/[^\d]/g,'')
      }else if(unit.dataType === 2) {
        let str = this.childreanRuleForm[model].replace(/[^\d^\.]+/g,'');
        if(str.split('.')[1] && str.split('.')[1].length > 2) {
          str = str.split('.')[0] + '.' + str.split('.')[1].substring(0, 2);
        }
        this.childreanRuleForm[model] = str;
      }
    }
  }
}
</script>

<style lang="scss">
.showAttr {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  .el-form-item__content .el-input-group {
    vertical-align: middle
  }
}
</style>
