<template>
  <div>
    <div>
      <div v-if="!shopListDto.length">
        未关联
      </div>
      <div
        v-for="(item,index) in shopListDto"
        :key="index"
      >
        门店{{ index+1 }}：{{ item.name }}
      </div>
    </div>
    <el-button
      v-if="!disabled"
      type="primary"
      size="mini"
      @click="showHomeShop"
    >
      点击关联
    </el-button>
    <el-dialog
      title="关联门店"
      :visible.sync="isShowHomeShop"
      width="600px"
      :before-close="() => {
        isShowHomeShop = false
      }"
      @open="getShopList"
    >
      <div v-loading="loading">
        <el-checkbox-group
          v-model="shopList"
          @change="onSubmit"
        >
          <el-checkbox
            v-for="(item,index) in tableData"
            :key="index"
            :label="item"
            style="display: flex;margin-bottom: 15px;"
          >
            <div style="margin-bottom: 4px;">
              门店名称：{{ item.name }}
            </div>
            <div style="margin-bottom: 4px;">
              联系方式：{{ item.moblie }}
            </div>
            <div>门店地址：{{ item.address }}</div>
          </el-checkbox>
        </el-checkbox-group>
      </div>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          type="primary"
          size="mini"
          @click="toAddShop"
        >新增门店</el-button>
        <el-button
          type="primary"
          size="mini"
          @click="isShowHomeShop = false"
        >确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    shopListDto: {
      type: Array,
      default: [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      isShowHomeShop: false,
      suppliersId: '',
      shopList: [],
      tableData: [],
    };
  },
  watch: {
    // shopListDto: {
    //   handler(item){
    //     if(item.length){
    //       this.shopList = item;
    //     }
    //   },
    //   deep: true,
    //   immediate: true,
    // }
  },
  created() {
    this.suppliersId = JSON.parse(localStorage.getItem('userInfo')).suppliersId;
  },
  methods: {
    showHomeShop() {
      this.isShowHomeShop = true;
    },
    getShopList() {
      let _this = this;
      _this.loading = true;
      _this.$axios
        .get(_this.$api.tradeShop.list, {
          params: {
            suppliersId: this.suppliersId,
          },
        })
        .then((res) => {
          _this.loading = false;
          if (res.code === 0 && res.data) {
            _this.shopList = [];
            const {
              size, total, current, pages,
            } = res.data;
            _this.tableData = res.data;
            _this.tableData.forEach((item) => {
              item.businessHours && _this.$set(item, 'parseBusinessHours', JSON.parse(item.businessHours));
              if (_this.shopListDto.find((find) => find.shopId === item.id)) {
                _this.shopList.push(item);
              }
            });
          } else {
            _this.tableData = [];
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    onSubmit() {
      if (this.shopList) {
        if (this.$route.query.goodsType == '2' && this.shopList.length > 1) {
          this.shopList.shift();
          this.$message({
            type: 'warning',
            message: '民宿商品最多关联一个门店',
          });
        }
        this.$emit('changeHomeShop', this.shopList);
      }
    },
    toAddShop() {
      window.open(`${window.location.origin}/nb/offlineStore/list`);
    },
  },
};
</script>

<style lang="scss" scoped>
:deep(.el-checkbox) {
  white-space: normal;
}
</style>
