var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mb50" },
    [
      _c("h1", { staticClass: "title" }, [_vm._v(" 基础保障 ")]),
      _c(
        "el-form",
        {
          ref: "freightForm",
          staticClass: "demo-ruleForm",
          attrs: { "label-width": "110px" },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "保障：" } },
            [
              !_vm.disabled
                ? _c(
                    "el-checkbox-group",
                    {
                      model: {
                        value: _vm.checkList,
                        callback: function ($$v) {
                          _vm.checkList = $$v
                        },
                        expression: "checkList",
                      },
                    },
                    _vm._l(_vm.list, function (item, i) {
                      return _c(
                        "div",
                        { key: i },
                        [
                          _c("el-checkbox", { attrs: { label: item } }, [
                            _vm._v(
                              " " +
                                _vm._s(item.label) +
                                "：" +
                                _vm._s(item.value) +
                                " "
                            ),
                          ]),
                        ],
                        1
                      )
                    }),
                    0
                  )
                : _vm._e(),
              _vm.disabled
                ? _c(
                    "el-checkbox-group",
                    { attrs: { value: _vm.checkList } },
                    _vm._l(_vm.list, function (item, i) {
                      return _c(
                        "div",
                        { key: i },
                        [
                          _c("el-checkbox", { attrs: { label: item } }, [
                            _vm._v(
                              " " +
                                _vm._s(item.label) +
                                "：" +
                                _vm._s(item.value) +
                                " "
                            ),
                          ]),
                        ],
                        1
                      )
                    }),
                    0
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }