// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/.pnpm/css-loader@3.6.0_webpack@4.47.0/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/* Variables */\n\n/* Mixins */\n.upload-demo {\n  width: 360px;\n}\n.quillBox {\n  width: 360px;\n  float: left;\n}\n.ql-editor {\n  height: 700px !important;\n  padding: 0;\n}\n.ql-editor img {\n  cursor: move !important;\n  width: 100%;\n  display: block;\n}\n.detail-info .rule_box {\n  color: #666;\n  line-height: 14px;\n  margin-top: 5px;\n}", ""]);
// Exports
exports.locals = {
	"theme": "#30B08F",
	"menuBg": "#304156",
	"menuText": "#bfcbd9",
	"menuActiveText": "#409EFF"
};
module.exports = exports;
