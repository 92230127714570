<template>
  <el-dialog
    title="编辑图片"
    :visible="imgEditVisible"
    @close="cancel"
  >
    <img-drag
      :list="imgList"
      @remove="removeImg"
      @move="move"
    />
    <span
      slot="footer"
      class="dialog-footer"
    >
      <el-button @click="cancel">取 消</el-button>
      <el-button
        type="primary"
        @click="submit"
      >确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import imgDrag from '@/components/common/imgDrag'

export default {
  name: 'DragDialog',
  components: {
    imgDrag
  },
  props: {
    imgEditVisible: {
      type: Boolean,
      default: false
    },
    imgList: {
      type: Array,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: []
    }
  },
  data() {
    return {
      // eslint-disable-next-line vue/no-dupe-keys
    }
  },
  methods: {
    submit() {
      this.$emit('submit', this.imgList)
    },
    cancel() {
      this.$emit('cancel', this.imgList)
    },
    // 删除图片
    removeImg(val) {
      this.$emit('remove', { item: val, list: this.imgList })
    },
    // 移动图片
    move(file) {
      this.$emit('move', file)
    }
  }
}
</script>
