<template>
  <div class="component-bookRule">
    <h1 class="title">
      {{ [8,9].includes(+goodsType) ? '购买须知' : '预订规则' }}
    </h1>
    <el-form
      ref="form"
      :model="forms"
      size="mini"
      label-width="110px"
    >
      <el-form-item
        v-if="[8].includes(+goodsType)"
        required
        label="卡名称："
      >
        <el-input
          v-model="forms.cardName"
          maxlength="9"
          show-word-limit
          class="width360"
          placeholder="请输入卡名称"
          size="mini"
          :readonly="disabled"
        />
        <div>例：采摘特惠卡</div>
      </el-form-item>
      <el-form-item
        v-if="![9].includes(+goodsType)"
        required
        label="有效期："
      >
        <template v-if="![8].includes(+goodsType)">
          <el-radio-group
            v-model="forms.times"
          >
            <el-radio
              :label="1"
            >
              永久有效
            </el-radio>
            <el-radio :label="2">
              <span class="mRight10">指定时间</span>
              <el-date-picker
                v-model="date"
                style="width: 360px"
                v-bind="dateConfig"
                :readonly="disabled"
              />
            </el-radio>
          </el-radio-group>
          <div
            class="unDate-box"
          >
            <el-checkbox
              v-model="forms.unDate"
              class="mRight10"
            >
              不可用日期
            </el-checkbox>
            <el-input
              v-model="unDateValue"
              class="unDate"
              placeholder="如：周末不可用；国庆不可用"
              :readonly="disabled"
            />
          </div>
        </template>
        <el-date-picker
          v-else
          v-model="date"
          style="width: 360px"
          v-bind="dateConfig"
          :readonly="disabled"
        />
      </el-form-item>

      <el-form-item
        v-if="![8].includes(+goodsType)"
        label="预约规则："
        required
      >
        <el-radio-group
          v-if="![9].includes(+goodsType)"
          v-model="forms.rule"
        >
          <el-radio :label="4">
            无需预约
          </el-radio>
          <el-radio :label="5">
            <span class="mRight10">需提前至少</span>
            <el-input
              v-model="days"
              class="days"
              :readonly="disabled"
            /> 天预约
          </el-radio>
        </el-radio-group>
        <el-input
          v-else
          v-model="appointmentValue"
          :readonly="disabled"
          maxlength="25"
          show-word-limit
          class="width360"
          placeholder="例：16:00前可预约今日门票"
          size="mini"
        />
      </el-form-item>

      <el-form-item
        label="售后政策："
        required
      >
        <el-radio-group
          v-model="forms.afterSale"
        >
          <el-radio
            v-if="[8].includes(+goodsType)"
            :label="9"
          >
            支持未使用过权益的订单退款
          </el-radio>
          <template v-else-if="[9].includes(+goodsType)">
            <el-radio
              :label="7"
            >
              预约使用日24:00前，支持未核销订单申请退单
            </el-radio>
            <el-radio
              :label="8"
            >
              下单后即不支持退单
            </el-radio>
          </template>
          <el-radio
            v-else
            :label="6"
          >
            支持未核销申请退单
          </el-radio>
        </el-radio-group>
      </el-form-item>

      <el-form-item
        label="使用说明："
        required
      >
        <el-input
          v-bind="textAreaConfig"
          v-model="forms.text"
          class="width360"
          :readonly="disabled"
        />
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  props: {
    info: {
      type: Array, default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    this.dateConfig = {
      type: 'daterange',
      'range-separator': '-',
      'start-placeholder': '开始日期',
      'end-placeholder': '结束日期',
      format: 'yyyy-MM-dd',
      'value-format': 'yyyy-MM-dd',
      'picker-options': {
        disabledDate(time) {
          return time.getTime() < Date.now() - 24 * 60 * 60 * 1000;
        },
      },
    };
    const placeholderName = +this.$route.query.goodsType === 8
      ? '例：下单购买后，在【我的】-【我的订单】中，点击该商品订单，查看订单详情及可兑换权益，选择权益进行兑换。'
      : '如：本套餐周末节假日适用；本套餐不可拆分使用';
    this.textAreaConfig = {
      type: 'textarea',
      class: 'textArea',
      maxlength: 1000,
      autosize: { minRows: 10, maxRows: 14 },
      placeholder: placeholderName,
      'show-word-limit': true,
    };

    return {
      goodsType: this.$route.query.goodsType,
      forms: {
        times: [8].includes(+this.$route.query.goodsType) ? 2 : 1,
        rule: '',
        unDate: false,
        afterSale: [8].includes(+this.$route.query.goodsType) ? 9 : null,
        text: '',
        cardName: '',
        afterSaleValue: '',
      },
      date: '',
      unDateValue: '',
      days: '',
      once: true,
      appointmentValue: '', // 预约规则文案
    };
  },
  computed: {
    list() {
      const {
        times, rule, unDate, afterSale, text, cardName, afterSaleValue,
      } = this.forms;
      const goodsId = this.$route.query.goodsId || '';
      const date = this.date ? this.date : [];
      let afterSaleName; // 售后政策value
      switch (+afterSale) {
        case 6:
          afterSaleName = '支持未核销申请退单。';
          break;
        case 7:
          afterSaleName = '预约使用日24:00前，支持未核销订单申请退单。';
          break;
        case 8:
          afterSaleName = '下单后即不支持退单。';
          break;
        case 9:
          afterSaleName = '支持未使用过权益的订单退款。';
          break;
        default:
          afterSaleName = afterSaleValue;
      }

      const explainInfo = { detailType: 5, value: text, goodsId };
      const periodInfo = {
        detailType: 8,
        optionalType: times,
        value: times === 1 ? '' : date.join(),
        goodsId,
        optionalValue: unDate ? this.unDateValue : '',
      };
      const appointmentInfo = {
        detailType: 9, optionalType: rule, value: rule === 5 ? this.days : this.appointmentValue, goodsId,
      };
      const afterSaleInfo = {
        detailType: 10, optionalType: afterSale, value: afterSaleName, goodsId,
      };
      const cardNameInfo = { detailType: 16, value: cardName, goodsId };

      if (+this.$route.query.goodsType === 8) {
        // 16,8,10,5
        return [cardNameInfo, periodInfo, afterSaleInfo, explainInfo];
      } if (+this.$route.query.goodsType === 9) {
        // 9,10,5
        return [appointmentInfo, afterSaleInfo, explainInfo];
      }
      // 8,9,10,5
      return [periodInfo, appointmentInfo, afterSaleInfo, explainInfo];
    },
  },
  watch: {
    info() {
      if (this.once) this.init();
    },
    list: {
      handler(news) {
        this.$emit('getBookData', news);
      },
      deep: true,
    },
  },
  methods: {
    init() {
      this.info.forEach((item) => {
        switch (item.detailType) {
          case 8:
            this.forms.times = item.optionalType;
            this.date = item.value ? item.value.split(',') : '';
            this.unDateValue = item.optionalValue;
            this.forms.unDate = !!item.optionalValue;
            break;
          case 9:
            this.forms.rule = item.optionalType;
            this.days = item.value;
            this.appointmentValue = item.value;
            break;
          case 10:
            this.forms.afterSale = item.optionalType;
            if (!item.optionalType) {
              this.forms.afterSaleValue = item.value;
            }
            break;
          case 5: this.forms.text = item.value; break;
          case 16: this.forms.cardName = item.value; break;
        }
      });
      this.once = false;
    },
  },
};
</script>

<style lang="scss">
.component-bookRule{
  .mRight10{margin-right: 10px;}
  .unDate-box{display: flex; margin-top: 10px;}
  .unDate{width: 496px;}
  .days{
    @extend .mRight10;
    width: 100px;
  }
  .el-radio__input{margin-bottom: 2px;}
}
</style>
