var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "审核商品",
        visible: _vm.showAudit,
        width: "700px",
        "before-close": _vm.handleClose,
      },
      on: {
        "update:visible": function ($event) {
          _vm.showAudit = $event
        },
        open: _vm.openFun,
      },
    },
    [
      _c(
        "div",
        [
          _c(
            "el-form",
            { staticClass: "demo-ruleForm", attrs: { "label-width": "110px" } },
            [
              _c("el-form-item", { attrs: { label: "商品标题：" } }, [
                _c("div", [_vm._v(_vm._s(_vm.goodsName))]),
              ]),
              _c(
                "el-form-item",
                { attrs: { required: "", label: "审核结果：" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.auditType,
                        callback: function ($$v) {
                          _vm.auditType = $$v
                        },
                        expression: "auditType",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 1 } }, [
                        _vm._v(" 通过 "),
                      ]),
                      _c("el-radio", { attrs: { label: 0 } }, [
                        _vm._v(" 不通过 "),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.auditType === 1
                ? _c(
                    "el-form-item",
                    { attrs: { label: "平台标签：" } },
                    [
                      _c(
                        "el-checkbox-group",
                        {
                          model: {
                            value: _vm.label,
                            callback: function ($$v) {
                              _vm.label = $$v
                            },
                            expression: "label",
                          },
                        },
                        _vm._l(_vm.labelList, function (item, i) {
                          return _c(
                            "el-checkbox",
                            { key: i, attrs: { label: item } },
                            [_vm._v(" " + _vm._s(item.tagName) + " ")]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.auditType === 1
                ? _c(
                    "el-form-item",
                    { attrs: { label: "选择品牌：" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            placeholder: "请选择",
                            size: "mini",
                            clearable: "",
                          },
                          model: {
                            value: _vm.brand,
                            callback: function ($$v) {
                              _vm.brand = $$v
                            },
                            expression: "brand",
                          },
                        },
                        _vm._l(_vm.brandList, function (item, i) {
                          return _c("el-option", {
                            key: i,
                            attrs: {
                              label: item.brandName,
                              value: item.brandId,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.auditType === 0
                ? _c(
                    "el-form-item",
                    { attrs: { required: "", label: "不通过原因：" } },
                    [
                      _c("el-input", {
                        staticClass: "width360",
                        attrs: {
                          size: "mini",
                          type: "textarea",
                          "show-word-limit": "",
                          maxlength: "200",
                          placeholder:
                            "请输入审核不通过原因，原因包含不通过的项目以及修改建议。",
                          autosize: { minRows: 4, maxRows: 8 },
                        },
                        model: {
                          value: _vm.refuse,
                          callback: function ($$v) {
                            _vm.refuse =
                              typeof $$v === "string" ? $$v.trim() : $$v
                          },
                          expression: "refuse",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            { attrs: { size: "small" }, on: { click: _vm.handleClose } },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "small" },
              on: { click: _vm.submitAudit },
            },
            [_vm._v("提 交")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }