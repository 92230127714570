<template>
  <el-tag
    :closable="!disabled"
    effect="plain"
    disable-transitions
    @close="close"
  >
    <el-image
      class="tag-image"
      :src="item.brandLogo"
      :preview-src-list="[item.brandLogo]"
    />
    <span>{{ item.brandName }}</span>
  </el-tag>
</template>

<script setup>
const props = defineProps({
  item: {
    type: Object,
    default: () => {},
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(['close']);

const close = () => {
  emit('close', props.item);
};
</script>

<style scoped lang="scss">
.tag-image {
  float: left;
  height: 26px;
  margin-top: 2px;
  margin-right: 4px;
}
</style>
