var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "sku",
    },
    [
      _c(
        "h1",
        { staticClass: "title" },
        [
          _vm._v(
            " " + _vm._s(_vm.goodsType == "2" ? "" : "设置商品规格") + " "
          ),
          _vm.goodsType != "2"
            ? _c(
                "el-link",
                {
                  staticClass: "margin_right_10",
                  attrs: { type: "primary" },
                  on: { click: _vm.showBigSkuImg },
                },
                [_vm._v(" 点击查看示例图 ")]
              )
            : _vm._e(),
          _c("el-image", {
            ref: "bigSkuImg",
            staticStyle: { width: "0px", height: "0px" },
            attrs: {
              id: "bigSkuImg",
              src: _vm.goodsType == "2" ? _vm.skuImage[1] : _vm.skuImage[0],
              "preview-src-list": [
                _vm.goodsType == "2" ? _vm.skuImage[1] : _vm.skuImage[0],
              ],
            },
          }),
        ],
        1
      ),
      !_vm.disabled && !_vm.isAssembly && _vm.isShow
        ? _c(
            "el-card",
            { staticClass: "box-card" },
            [
              _vm._l(_vm.skuData, function (item, i) {
                return _c(
                  "div",
                  { key: i },
                  [
                    _c(
                      "div",
                      { staticClass: "sku-header" },
                      [
                        _c("span", { staticClass: "margin_right_30" }, [
                          _vm._v("规格 " + _vm._s(i + 1) + " :"),
                        ]),
                        !item.name
                          ? _c("span", { staticStyle: { color: "red" } }, [
                              _vm._v("请选择规格项"),
                            ])
                          : _vm._e(),
                        _c(
                          "span",
                          {
                            staticClass: "margin_right_30",
                            staticStyle: { "font-weight": "bold" },
                          },
                          [_vm._v(_vm._s(item.name))]
                        ),
                        _c(
                          "el-button",
                          {
                            staticClass: "margin_right_30",
                            attrs: { type: "primary", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.showAddSkuGroup(i)
                              },
                            },
                          },
                          [_vm._v(" 选择规格项 ")]
                        ),
                        _c(
                          "el-button",
                          {
                            staticClass: "margin_right_30",
                            attrs: {
                              type: "danger",
                              size: "mini",
                              disabled: _vm.skuData.length <= 1,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.deleteSku(i, item)
                              },
                            },
                          },
                          [_vm._v(" 删除规格 ")]
                        ),
                      ],
                      1
                    ),
                    item.id
                      ? _c(
                          "div",
                          { staticClass: "sku-content" },
                          [
                            _c(
                              "span",
                              { staticStyle: { "font-size": "14px" } },
                              [_vm._v("规格值：")]
                            ),
                            !item.tradeSpecValueDtos
                              ? _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      color: "red",
                                      "font-size": "14px",
                                    },
                                  },
                                  [_vm._v("请设置规格值")]
                                )
                              : _vm._e(),
                            _vm._l(
                              item.tradeSpecValueDtos,
                              function (value, index) {
                                return _c(
                                  "el-tag",
                                  {
                                    key: index,
                                    staticStyle: { "margin-right": "10px" },
                                    attrs: { size: "medium", closable: "" },
                                    on: {
                                      close: function ($event) {
                                        return _vm.onCloseVal(item, index, i)
                                      },
                                    },
                                  },
                                  [_vm._v(" " + _vm._s(value.name) + " ")]
                                )
                              }
                            ),
                            _c(
                              "div",
                              [
                                _c(
                                  "el-button",
                                  {
                                    staticClass: "margin_left_10",
                                    attrs: { type: "primary", size: "mini" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.addSkuVal(i, item)
                                      },
                                    },
                                  },
                                  [_vm._v(" 设置规格值 ")]
                                ),
                              ],
                              1
                            ),
                          ],
                          2
                        )
                      : _vm._e(),
                    _c("el-divider"),
                  ],
                  1
                )
              }),
              _c(
                "el-button",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    disabled: _vm.skuData.length >= 2,
                    size: "small",
                    type: "primary",
                    plain: "",
                  },
                  on: { click: _vm.addSku },
                },
                [_vm._v(" +新增规格 ")]
              ),
            ],
            2
          )
        : _vm._e(),
      _c(
        "el-card",
        { staticClass: "box-card box-card-tabl" },
        [
          !_vm.disabled && _vm.isAssembly
            ? _c(
                "el-button",
                {
                  staticClass: "margin_bottom_10",
                  attrs: { size: "small", type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.showAssSku = true
                    },
                  },
                },
                [_vm._v(" 新增组合规格 ")]
              )
            : _vm._e(),
          !_vm.disabled &&
          _vm.skuTable.filter((item) => item.isDelete != 1).length > 0
            ? _c(
                "el-button",
                {
                  staticClass: "margin_bottom_10",
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.setBathSkuList },
                },
                [_vm._v(" 批量设置 ")]
              )
            : _vm._e(),
          _c(
            "el-table",
            {
              ref: "skuTable",
              attrs: {
                data: _vm.skuTable.filter((item) => item.isDelete != 1),
                border: "",
                size: "mini",
                "row-key": "specValue",
              },
            },
            [
              _c("el-table-column", {
                key: "selection",
                attrs: {
                  type: "selection",
                  "reserve-selection": "",
                  width: "55",
                },
              }),
              _c("el-table-column", {
                key: "specValue",
                attrs: { prop: "specValue", label: "规格" },
              }),
              _vm.isSupplyGoods()
                ? _c("el-table-column", {
                    key: "supplyPrice",
                    attrs: {
                      label: "订货价（元）",
                      "render-header": _vm.renderHeader,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("el-input", {
                                attrs: { readonly: _vm.disabled, size: "mini" },
                                on: {
                                  input: function ($event) {
                                    return _vm.onChangeNum(
                                      scope.row,
                                      "supplyPrice"
                                    )
                                  },
                                  blur: function ($event) {
                                    return _vm.blurTable(scope.row)
                                  },
                                },
                                model: {
                                  value: scope.row.supplyPrice,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      scope.row,
                                      "supplyPrice",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "scope.row.supplyPrice",
                                },
                              }),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2123396093
                    ),
                  })
                : _vm._e(),
              _vm.isShowMarketPrice()
                ? _c("el-table-column", {
                    key: "marketPrice",
                    attrs: {
                      label: "市场价（元）",
                      "render-header": _vm.renderHeader,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("el-input", {
                                attrs: { readonly: _vm.disabled, size: "mini" },
                                on: {
                                  input: function ($event) {
                                    return _vm.onChangeNum(
                                      scope.row,
                                      "marketPrice"
                                    )
                                  },
                                  blur: function ($event) {
                                    return _vm.blurTable(scope.row)
                                  },
                                },
                                model: {
                                  value: scope.row.marketPrice,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      scope.row,
                                      "marketPrice",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "scope.row.marketPrice",
                                },
                              }),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2448731133
                    ),
                  })
                : _vm._e(),
              _vm.isShowSellPrice()
                ? _c("el-table-column", {
                    key: "sellPrice",
                    attrs: {
                      label: "销售价（元）",
                      "render-header": _vm.renderHeader,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("el-input", {
                                attrs: { readonly: _vm.disabled, size: "mini" },
                                on: {
                                  input: function ($event) {
                                    return _vm.onChangeNum(
                                      scope.row,
                                      "sellPrice"
                                    )
                                  },
                                  blur: function ($event) {
                                    return _vm.blurTable(scope.row)
                                  },
                                },
                                model: {
                                  value: scope.row.sellPrice,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      scope.row,
                                      "sellPrice",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "scope.row.sellPrice",
                                },
                              }),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3322754557
                    ),
                  })
                : _vm._e(),
              _vm.isShowMinBuy()
                ? _c("el-table-column", {
                    key: "minBuy",
                    attrs: {
                      label: "起购量（件）",
                      "render-header": _vm.renderHeader,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("el-input", {
                                attrs: {
                                  readonly: _vm.disabled,
                                  disabled: _vm.isDisabledMinBuy(),
                                  size: "mini",
                                },
                                on: {
                                  input: function ($event) {
                                    return _vm.onChangeNum(scope.row, "minBuy")
                                  },
                                  blur: function ($event) {
                                    return _vm.blurTable(scope.row)
                                  },
                                },
                                model: {
                                  value: scope.row.minBuy,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      scope.row,
                                      "minBuy",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "scope.row.minBuy",
                                },
                              }),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3221662356
                    ),
                  })
                : _vm._e(),
              _c("el-table-column", {
                key: "stockQuantity",
                attrs: {
                  label: "库存（件）",
                  "render-header": _vm.renderHeader,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("el-input", {
                          attrs: {
                            readonly: _vm.disabled,
                            size: "mini",
                            maxlength: "6",
                          },
                          on: {
                            input: function ($event) {
                              return _vm.onChangeNum(scope.row, "stockQuantity")
                            },
                            blur: function ($event) {
                              return _vm.blurTable(scope.row)
                            },
                          },
                          model: {
                            value: scope.row.stockQuantity,
                            callback: function ($$v) {
                              _vm.$set(
                                scope.row,
                                "stockQuantity",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "scope.row.stockQuantity",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                key: "stockQuantityWarning",
                attrs: {
                  label: "预警库存（件）",
                  "render-header": _vm.renderHeader,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("el-input", {
                          attrs: {
                            readonly: _vm.disabled,
                            size: "mini",
                            maxlength: "6",
                          },
                          on: {
                            input: function ($event) {
                              return _vm.onChangeNum(
                                scope.row,
                                "stockQuantityWarning"
                              )
                            },
                            blur: function ($event) {
                              return _vm.blurTable(scope.row)
                            },
                          },
                          model: {
                            value: scope.row.stockQuantityWarning,
                            callback: function ($$v) {
                              _vm.$set(
                                scope.row,
                                "stockQuantityWarning",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "scope.row.stockQuantityWarning",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _vm.isShowSkuImage()
                ? _c("el-table-column", {
                    key: "imageList",
                    attrs: {
                      label: "规格图",
                      "render-header": _vm.renderHeader,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("CutImgUp", {
                                ref: "cutImgUp",
                                attrs: {
                                  disabled: _vm.disabled,
                                  url: scope.row.imageList,
                                  "auto-crop-width": 2000,
                                  "auto-crop-height": 2000,
                                  fixed: true,
                                  "max-num": 1,
                                },
                                on: {
                                  change: (e) => {
                                    _vm.changeAvatar(e, scope.row)
                                  },
                                },
                              }),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      553684951
                    ),
                  })
                : _vm._e(),
              +_vm.goodsType !== 8
                ? _c("el-table-column", {
                    key: "skuNo",
                    attrs: { label: "规格编号" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("el-input", {
                                attrs: { readonly: _vm.disabled, size: "mini" },
                                on: {
                                  input: function ($event) {
                                    scope.row.skuNo = scope.row.skuNo.replace(
                                      /[\W]/g,
                                      ""
                                    )
                                  },
                                },
                                model: {
                                  value: scope.row.skuNo,
                                  callback: function ($$v) {
                                    _vm.$set(scope.row, "skuNo", $$v)
                                  },
                                  expression: "scope.row.skuNo",
                                },
                              }),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      296677857
                    ),
                  })
                : _vm._e(),
              +_vm.goodsType === 8 && _vm.pageType !== "add"
                ? _c("el-table-column", {
                    key: "equity",
                    attrs: { label: "权益" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function ({ row }) {
                            return [
                              _c(
                                "el-link",
                                {
                                  attrs: {
                                    type: "row.showspec" ? "primary" : "danger",
                                    underline: false,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.toShowEquity(row)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        "row.showspec" ? "查看" : "未设置"
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2451567590
                    ),
                  })
                : _vm._e(),
              _vm.isAssembly && !_vm.disabled
                ? _c("el-table-column", {
                    key: "option",
                    attrs: { label: "操作" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function ({ row }) {
                            return [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "text", size: "mini" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.operation(row, "edit")
                                    },
                                  },
                                },
                                [_vm._v("编辑")]
                              ),
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "text", size: "mini" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.operation(row, "delete")
                                    },
                                  },
                                },
                                [_vm._v("删除")]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2851514356
                    ),
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c("AddSkuGroup", {
        attrs: {
          dialogVisible: _vm.showSkuGroup,
          specData: _vm.skuData[_vm.setSkuGroupIndex],
          skuData: _vm.skuData,
        },
        on: {
          "update:dialogVisible": function ($event) {
            _vm.showSkuGroup = $event
          },
          "update:dialog-visible": function ($event) {
            _vm.showSkuGroup = $event
          },
          "update:specData": function ($event) {
            return _vm.$set(_vm.skuData, _vm.setSkuGroupIndex, $event)
          },
          "update:spec-data": function ($event) {
            return _vm.$set(_vm.skuData, _vm.setSkuGroupIndex, $event)
          },
        },
      }),
      _c("AddSkuValue", {
        ref: "skuValRef",
        attrs: {
          dialogVisible: _vm.showSkuValue,
          id: _vm.setSkuGroupId,
          value: _vm.skuData[_vm.setSkuGroupIndex].tradeSpecValueDtos,
        },
        on: {
          "update:dialogVisible": function ($event) {
            _vm.showSkuValue = $event
          },
          "update:dialog-visible": function ($event) {
            _vm.showSkuValue = $event
          },
          "update:value": function ($event) {
            return _vm.$set(
              _vm.skuData[_vm.setSkuGroupIndex],
              "tradeSpecValueDtos",
              $event
            )
          },
        },
      }),
      _c("SetAllSku", {
        attrs: {
          dialogVisible: _vm.showSetAllSku,
          skuTable: _vm.skuTable,
          bathSetList: _vm.bathSetList,
          isShowMarketPrice: _vm.isShowMarketPrice(),
          isShowSellPrice: _vm.isShowSellPrice(),
          isShowMinBuy: _vm.isShowMinBuy() && !_vm.isDisabledMinBuy(),
        },
        on: {
          "update:dialogVisible": function ($event) {
            _vm.showSetAllSku = $event
          },
          "update:dialog-visible": function ($event) {
            _vm.showSetAllSku = $event
          },
        },
      }),
      _c("AddSkuList", {
        attrs: {
          dialogVisible: _vm.showAssSku,
          assSpecId: _vm.assSpecId,
          specValueList: _vm.skuData[0].tradeSpecValueDtos,
          specValueId: _vm.specValueId,
        },
        on: {
          "update:dialogVisible": function ($event) {
            _vm.showAssSku = $event
          },
          "update:dialog-visible": function ($event) {
            _vm.showAssSku = $event
          },
          "update:specValueList": function ($event) {
            return _vm.$set(_vm.skuData[0], "tradeSpecValueDtos", $event)
          },
          "update:spec-value-list": function ($event) {
            return _vm.$set(_vm.skuData[0], "tradeSpecValueDtos", $event)
          },
          "update:specValueId": function ($event) {
            _vm.specValueId = $event
          },
          "update:spec-value-id": function ($event) {
            _vm.specValueId = $event
          },
          changeGoods: _vm.clearTag,
        },
      }),
      _c("EquityListDialog", {
        attrs: {
          show: _vm.equityListShow,
          "sku-type": "show",
          "sku-id": _vm.skuId,
        },
        on: {
          hide: function ($event) {
            _vm.equityListShow = false
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }