var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "detail-info" },
    [
      _vm.goodsType != "2" && _vm.goodsType != "3" && _vm.goodsType != "4"
        ? _c("h1", { staticClass: "title" }, [_vm._v(" 设置商品详情 ")])
        : _vm._e(),
      _vm.goodsType == "2"
        ? _c("h1", { staticClass: "title" }, [_vm._v(" 设置入住须知 ")])
        : _vm._e(),
      _vm.goodsType == "3" || _vm.goodsType == "4"
        ? _c("h1", { staticClass: "title" }, [_vm._v(" 说明信息 ")])
        : _vm._e(),
      _c(
        "el-form",
        {
          ref: "ruleForm",
          staticClass: "demo-ruleForm",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            "label-width": "110px",
          },
        },
        [
          _vm.goodsType != "2" && _vm.goodsType != "3" && _vm.goodsType != "4"
            ? _c(
                "el-form-item",
                { attrs: { required: "", label: "提示信息：" } },
                [
                  _c("div", [
                    _vm._v(
                      " 请将图片或文字内容添加到下图所示区域，用来展示商品的详情信息。 "
                    ),
                  ]),
                ]
              )
            : _vm._e(),
          _c("el-form-item", { attrs: { required: "", label: "详情图：" } }, [
            !_vm.disabled
              ? _c(
                  "div",
                  {
                    staticClass: "margin-b20",
                    staticStyle: { width: "400px" },
                  },
                  [
                    _c("LoadImgOss", {
                      attrs: { multiple: true },
                      on: { onSuccess: _vm.handleChange },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "div",
              { staticClass: "wd-zoom" },
              [
                _c("quill-editor", {
                  ref: "myTextEditor",
                  staticClass: "quillBox wd-zoom",
                  attrs: { options: _vm.editorOption },
                  model: {
                    value: _vm.serveData.goodsContent,
                    callback: function ($$v) {
                      _vm.$set(_vm.serveData, "goodsContent", $$v)
                    },
                    expression: "serveData.goodsContent",
                  },
                }),
                _c("drag-dialog", {
                  attrs: {
                    "img-list": _vm.imgList,
                    "img-edit-visible": _vm.imgEditVisible,
                  },
                  on: {
                    move: _vm.goodsImgMove,
                    remove: _vm.goodsImgRemove,
                    submit: _vm.submitImg,
                    cancel: _vm.cancelImg,
                  },
                }),
                !_vm.disableStatus && !_vm.disabled
                  ? _c(
                      "el-button",
                      {
                        staticClass: "margin-l20",
                        on: { click: _vm.editImgs },
                      },
                      [_vm._v(" 编辑图片 ")]
                    )
                  : _vm._e(),
              ],
              1
            ),
          ]),
          _vm.goodsType == "2"
            ? _c(
                "el-form-item",
                { attrs: { label: "入住规则：", prop: "liveRule" } },
                [
                  _c("el-input", {
                    staticClass: "width360",
                    attrs: {
                      disabled: _vm.disabled,
                      type: "textarea",
                      rows: 4,
                      placeholder: "请输入入住规则",
                    },
                    model: {
                      value: _vm.ruleForm.liveRule,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "liveRule", $$v)
                      },
                      expression: "ruleForm.liveRule",
                    },
                  }),
                  _c(
                    "div",
                    { staticClass: "rule_box" },
                    [
                      _c("span", [
                        _vm._v("例：1、入离时间：12点前离店，14定后入住；"),
                        _c("br"),
                        _vm._v(
                          "2、房间内不得聚会、赌博、大声喧哗或其他影响他人事情；"
                        ),
                      ]),
                      _c(
                        "el-button",
                        {
                          staticClass: "rule_btn",
                          attrs: { type: "text" },
                          on: {
                            click: function ($event) {
                              return _vm.sutoFill(
                                "liveRule",
                                "1、入离时间：12点前离店，14定后入住；\n2、房间内不得聚会、赌博、大声喧哗或其他影响他人事情；"
                              )
                            },
                          },
                        },
                        [_vm._v(" 填入 ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.goodsType == "2"
            ? _c(
                "el-form-item",
                { attrs: { label: "退改须知：", prop: "ticketRule" } },
                [
                  _c("el-input", {
                    staticClass: "width360",
                    attrs: {
                      disabled: _vm.disabled,
                      type: "textarea",
                      rows: 4,
                      placeholder: "请输入退改须知",
                    },
                    model: {
                      value: _vm.ruleForm.ticketRule,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "ticketRule", $$v)
                      },
                      expression: "ruleForm.ticketRule",
                    },
                  }),
                  _c("div", { staticStyle: { color: "red" } }, [
                    _vm._v(
                      " 注：系统仅支持入住当日18:00之前退订。其余时间退订用户需和商家线下协商处理。 "
                    ),
                  ]),
                  _c(
                    "div",
                    { staticClass: "rule_box" },
                    [
                      _c("span", [
                        _vm._v(
                          "例：入住当日18:00前退订，可退全款；入住当日18:00之后，取消订单或退订将扣除100%订单金额。"
                        ),
                        _c("br"),
                        _vm._v("如因不可抗力不能入住，请联系我们协商。"),
                      ]),
                      _c(
                        "el-button",
                        {
                          staticClass: "rule_btn",
                          attrs: { type: "text" },
                          on: {
                            click: function ($event) {
                              return _vm.sutoFill(
                                "ticketRule",
                                "入住当日18:00前退订，可退全款；入住当日18:00之后，取消订单或退订将扣除100%订单金额。如因不可抗力不能入住，请联系我们协商。"
                              )
                            },
                          },
                        },
                        [_vm._v(" 填入 ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.goodsType == "3" || _vm.goodsType == "4"
            ? _c(
                "el-form-item",
                { attrs: { label: "购买须知：", prop: "saleRule" } },
                [
                  _c("el-input", {
                    staticClass: "width360",
                    attrs: {
                      disabled: _vm.disabled,
                      type: "textarea",
                      rows: 4,
                      placeholder: "请输入购买须知",
                    },
                    model: {
                      value: _vm.ruleForm.saleRule,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "saleRule", $$v)
                      },
                      expression: "ruleForm.saleRule",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.goodsType == "3" || _vm.goodsType == "4"
            ? _c(
                "el-form-item",
                { attrs: { label: "使用说明：", prop: "useRule" } },
                [
                  _c("el-input", {
                    staticClass: "width360",
                    attrs: {
                      disabled: _vm.disabled,
                      type: "textarea",
                      rows: 4,
                      placeholder: "请输入使用说明",
                    },
                    model: {
                      value: _vm.ruleForm.useRule,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "useRule", $$v)
                      },
                      expression: "ruleForm.useRule",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.goodsType == "2" || _vm.goodsType == "3" || _vm.goodsType == "4"
            ? _c(
                "el-form-item",
                { attrs: { label: "费用须知：", prop: "costRule" } },
                [
                  _c("el-input", {
                    staticClass: "width360",
                    attrs: {
                      disabled: _vm.disabled,
                      type: "textarea",
                      rows: 4,
                      placeholder: "请输入费用须知",
                    },
                    model: {
                      value: _vm.ruleForm.costRule,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "costRule", $$v)
                      },
                      expression: "ruleForm.costRule",
                    },
                  }),
                  _c(
                    "div",
                    { staticClass: "rule_box" },
                    [
                      _c("span", [
                        _vm._v(
                          "例：1、该费用仅包括预定房间费用，不含定金，不包括餐费，如需在店就餐需额外协商费用；"
                        ),
                        _c("br"),
                        _vm._v("2、增加床位需要另行协商费用；"),
                      ]),
                      _c(
                        "el-button",
                        {
                          staticClass: "rule_btn",
                          attrs: { type: "text" },
                          on: {
                            click: function ($event) {
                              return _vm.sutoFill(
                                "costRule",
                                "1、该费用仅包括预定房间费用，不含定金，不包括餐费，如需在店就餐需额外协商费用；\n2、增加床位需要另行协商费用；"
                              )
                            },
                          },
                        },
                        [_vm._v(" 填入 ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.goodsType == "3" || _vm.goodsType == "4"
            ? _c(
                "el-form-item",
                { attrs: { label: "其他说明：", prop: "otherRule" } },
                [
                  _c("el-input", {
                    staticClass: "width360",
                    attrs: {
                      disabled: _vm.disabled,
                      type: "textarea",
                      rows: 4,
                      placeholder: "请输入其他说明",
                    },
                    model: {
                      value: _vm.ruleForm.otherRule,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "otherRule", $$v)
                      },
                      expression: "ruleForm.otherRule",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.goodsType == 7
            ? _c(
                "el-form-item",
                { attrs: { label: "认养规则：", prop: "adoptionRules" } },
                [
                  _c("el-input", {
                    staticClass: "width360",
                    attrs: {
                      disabled: _vm.disabled,
                      type: "textarea",
                      rows: 4,
                      placeholder: "请输入认养规则",
                    },
                    model: {
                      value: _vm.ruleForm.adoptionRules,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "adoptionRules", $$v)
                      },
                      expression: "ruleForm.adoptionRules",
                    },
                  }),
                  _c(
                    "div",
                    { staticClass: "rule_box" },
                    [
                      _c("span", [
                        _vm._v(
                          "例：购买认养卡后，每张认养卡对应唯一蜂箱编码，"
                        ),
                        _c("br"),
                        _vm._v(
                          "认养期间该蜂箱产出归购买者所有，将根据蜂蜜产量按产蜜次数分次数发货。"
                        ),
                      ]),
                      _c(
                        "el-button",
                        {
                          staticClass: "rule_btn",
                          attrs: { type: "text" },
                          on: {
                            click: function ($event) {
                              return _vm.sutoFill(
                                "adoptionRules",
                                "购买认养卡后，每张认养卡对应唯一蜂箱编码，认养期间该蜂箱产出归购买者所有，将根据蜂蜜产量按产蜜次数分次数发货。"
                              )
                            },
                          },
                        },
                        [_vm._v(" 填入 ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.goodsType == 7
            ? _c(
                "el-form-item",
                {
                  attrs: { label: "发货须知：", prop: "adoptionDeliverNotice" },
                },
                [
                  _c("el-input", {
                    staticClass: "width360",
                    attrs: {
                      disabled: _vm.disabled,
                      type: "textarea",
                      rows: 4,
                      placeholder: "请输入发货须知",
                    },
                    model: {
                      value: _vm.ruleForm.adoptionDeliverNotice,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "adoptionDeliverNotice", $$v)
                      },
                      expression: "ruleForm.adoptionDeliverNotice",
                    },
                  }),
                  _c(
                    "div",
                    { staticClass: "rule_box" },
                    [
                      _c("span", [
                        _vm._v(
                          "例：认养卡存在多次发货情况，用户购买后根据发货次数拆分成多个订单，"
                        ),
                        _c("br"),
                        _vm._v(
                          "每个订单在采蜜后寄出到用户，并在用户确认收货后结束该子订单。"
                        ),
                      ]),
                      _c(
                        "el-button",
                        {
                          staticClass: "rule_btn",
                          attrs: { type: "text" },
                          on: {
                            click: function ($event) {
                              return _vm.sutoFill(
                                "adoptionDeliverNotice",
                                "认养卡存在多次发货情况，用户购买后根据发货次数拆分成多个订单，每个订单在采蜜后寄出到用户，并在用户确认收货后结束该子订单。"
                              )
                            },
                          },
                        },
                        [_vm._v(" 填入 ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.goodsType == 7
            ? _c(
                "el-form-item",
                { attrs: { label: "售后须知：", prop: "afterSaleNotice" } },
                [
                  _c("el-input", {
                    staticClass: "width360",
                    attrs: {
                      disabled: _vm.disabled,
                      type: "textarea",
                      rows: 4,
                      placeholder: "请输入发货须知",
                    },
                    model: {
                      value: _vm.ruleForm.afterSaleNotice,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "afterSaleNotice", $$v)
                      },
                      expression: "ruleForm.afterSaleNotice",
                    },
                  }),
                  _c(
                    "div",
                    { staticClass: "rule_box" },
                    [
                      _c("span", [
                        _vm._v(
                          "例：认养期间不可退款退货，如产品质量问题，可与平台联系并提供相应证据，"
                        ),
                        _c("br"),
                        _vm._v(
                          "按照问题实际情况进行协商处理，如协商不一致，将由平台客服根据平台规则进行判定。"
                        ),
                      ]),
                      _c(
                        "el-button",
                        {
                          staticClass: "rule_btn",
                          attrs: { type: "text" },
                          on: {
                            click: function ($event) {
                              return _vm.sutoFill(
                                "afterSaleNotice",
                                "认养期间不可退款退货，如产品质量问题，可与平台联系并提供相应证据，按照问题实际情况进行协商处理，如协商不一致，将由平台客服根据平台规则进行判定。"
                              )
                            },
                          },
                        },
                        [_vm._v(" 填入 ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }