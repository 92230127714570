<template>
  <div class="detail-info">
    <h1
      v-if="goodsType != '2' && goodsType != '3' && goodsType != '4'"
      class="title"
    >
      设置商品详情
    </h1>
    <h1
      v-if="goodsType == '2'"
      class="title"
    >
      设置入住须知
    </h1>
    <h1
      v-if="goodsType == '3' || goodsType == '4'"
      class="title"
    >
      说明信息
    </h1>
    <el-form
      ref="ruleForm"
      :model="ruleForm"
      :rules="rules"
      label-width="110px"
      class="demo-ruleForm"
    >
      <el-form-item
        v-if="goodsType != '2' && goodsType != '3' && goodsType != '4'"
        required
        label="提示信息："
      >
        <div>
          请将图片或文字内容添加到下图所示区域，用来展示商品的详情信息。
        </div>
      </el-form-item>
      <!-- <el-form-item required label="提示信息：" v-if="goodsType == '2'">
        <div>1、入离时间：14:00后入住， 12:00前离店； </div>
        <div>2、每间房最多入住2人等；</div>
      </el-form-item> -->
      <el-form-item
        required
        label="详情图："
      >
        <div
          v-if="!disabled"
          class="margin-b20"
          style="width: 400px"
        >
          <LoadImgOss
            :multiple="true"
            @onSuccess="handleChange"
          />
        </div>
        <div class="wd-zoom">
          <!-- 编辑器 -->
          <quill-editor
            ref="myTextEditor"
            v-model="serveData.goodsContent"
            :options="editorOption"
            class="quillBox wd-zoom"
          />
          <drag-dialog
            :img-list="imgList"
            :img-edit-visible="imgEditVisible"
            @move="goodsImgMove"
            @remove="goodsImgRemove"
            @submit="submitImg"
            @cancel="cancelImg"
          />
          <!-- 编辑图片 -->
          <el-button
            v-if="!disableStatus && !disabled"
            class="margin-l20"
            @click="editImgs"
          >
            编辑图片
          </el-button>
        </div>
      </el-form-item>

      <el-form-item
        v-if="goodsType == '2'"
        label="入住规则："
        prop="liveRule"
      >
        <el-input
          v-model="ruleForm.liveRule"
          class="width360"
          :disabled="disabled"
          type="textarea"
          :rows="4"
          placeholder="请输入入住规则"
        />
        <div class="rule_box">
          <span>例：1、入离时间：12点前离店，14定后入住；<br />2、房间内不得聚会、赌博、大声喧哗或其他影响他人事情；</span>
          <el-button
            class="rule_btn"
            type="text"
            @click="sutoFill('liveRule', '1、入离时间：12点前离店，14定后入住；\n2、房间内不得聚会、赌博、大声喧哗或其他影响他人事情；')"
          >
            填入
          </el-button>
        </div>

        <!-- <el-popover
          placement="top-start"
          title="示例"
          width="400"
          trigger="hover"
          content="1、入离时间：14:00后入住， 12:00前离店；2、每间房最多入住2人等；">
          <i slot="reference" class="el-icon-warning-outline"></i>
        </el-popover> -->
      </el-form-item>
      <el-form-item
        v-if="goodsType == '2'"
        label="退改须知："
        prop="ticketRule"
      >
        <el-input
          v-model="ruleForm.ticketRule"
          class="width360"
          :disabled="disabled"
          type="textarea"
          :rows="4"
          placeholder="请输入退改须知"
        />
        <div style="color: red">
          注：系统仅支持入住当日18:00之前退订。其余时间退订用户需和商家线下协商处理。
        </div>
        <div class="rule_box">
          <span>例：入住当日18:00前退订，可退全款；入住当日18:00之后，取消订单或退订将扣除100%订单金额。<br />如因不可抗力不能入住，请联系我们协商。</span>
          <el-button
            class="rule_btn"
            type="text"
            @click="sutoFill('ticketRule', '入住当日18:00前退订，可退全款；入住当日18:00之后，取消订单或退订将扣除100%订单金额。如因不可抗力不能入住，请联系我们协商。')"
          >
            填入
          </el-button>
        </div>
      </el-form-item>
      <el-form-item
        v-if="goodsType == '3' || goodsType == '4'"
        label="购买须知："
        prop="saleRule"
      >
        <el-input
          v-model="ruleForm.saleRule"
          class="width360"
          :disabled="disabled"
          type="textarea"
          :rows="4"
          placeholder="请输入购买须知"
        />
      </el-form-item>
      <el-form-item
        v-if="goodsType == '3' || goodsType == '4'"
        label="使用说明："
        prop="useRule"
      >
        <el-input
          v-model="ruleForm.useRule"
          class="width360"
          :disabled="disabled"
          type="textarea"
          :rows="4"
          placeholder="请输入使用说明"
        />
      </el-form-item>
      <el-form-item
        v-if="goodsType == '2' || goodsType == '3' || goodsType == '4'"
        label="费用须知："
        prop="costRule"
      >
        <el-input
          v-model="ruleForm.costRule"
          class="width360"
          :disabled="disabled"
          type="textarea"
          :rows="4"
          placeholder="请输入费用须知"
        />
        <div class="rule_box">
          <span>例：1、该费用仅包括预定房间费用，不含定金，不包括餐费，如需在店就餐需额外协商费用；<br />2、增加床位需要另行协商费用；</span>
          <el-button
            class="rule_btn"
            type="text"
            @click="sutoFill('costRule', '1、该费用仅包括预定房间费用，不含定金，不包括餐费，如需在店就餐需额外协商费用；\n2、增加床位需要另行协商费用；')"
          >
            填入
          </el-button>
        </div>
      </el-form-item>
      <el-form-item
        v-if="goodsType == '3' || goodsType == '4'"
        label="其他说明："
        prop="otherRule"
      >
        <el-input
          v-model="ruleForm.otherRule"
          class="width360"
          :disabled="disabled"
          type="textarea"
          :rows="4"
          placeholder="请输入其他说明"
        />
      </el-form-item>

      <el-form-item
        v-if="goodsType == 7"
        label="认养规则："
        prop="adoptionRules"
      >
        <el-input
          v-model="ruleForm.adoptionRules"
          class="width360"
          :disabled="disabled"
          type="textarea"
          :rows="4"
          placeholder="请输入认养规则"
        />
        <div class="rule_box">
          <span>例：购买认养卡后，每张认养卡对应唯一蜂箱编码，<br />认养期间该蜂箱产出归购买者所有，将根据蜂蜜产量按产蜜次数分次数发货。</span>
          <el-button
            class="rule_btn"
            type="text"
            @click="sutoFill('adoptionRules', '购买认养卡后，每张认养卡对应唯一蜂箱编码，认养期间该蜂箱产出归购买者所有，将根据蜂蜜产量按产蜜次数分次数发货。')"
          >
            填入
          </el-button>
        </div>
      </el-form-item>

      <el-form-item
        v-if="goodsType == 7"
        label="发货须知："
        prop="adoptionDeliverNotice"
      >
        <el-input
          v-model="ruleForm.adoptionDeliverNotice"
          class="width360"
          :disabled="disabled"
          type="textarea"
          :rows="4"
          placeholder="请输入发货须知"
        />
        <div class="rule_box">
          <span>例：认养卡存在多次发货情况，用户购买后根据发货次数拆分成多个订单，<br />每个订单在采蜜后寄出到用户，并在用户确认收货后结束该子订单。</span>
          <el-button
            class="rule_btn"
            type="text"
            @click="sutoFill('adoptionDeliverNotice', '认养卡存在多次发货情况，用户购买后根据发货次数拆分成多个订单，每个订单在采蜜后寄出到用户，并在用户确认收货后结束该子订单。')"
          >
            填入
          </el-button>
        </div>
      </el-form-item>

      <el-form-item
        v-if="goodsType == 7"
        label="售后须知："
        prop="afterSaleNotice"
      >
        <el-input
          v-model="ruleForm.afterSaleNotice"
          class="width360"
          :disabled="disabled"
          type="textarea"
          :rows="4"
          placeholder="请输入发货须知"
        />
        <div class="rule_box">
          <span>例：认养期间不可退款退货，如产品质量问题，可与平台联系并提供相应证据，<br />按照问题实际情况进行协商处理，如协商不一致，将由平台客服根据平台规则进行判定。</span>
          <el-button
            class="rule_btn"
            type="text"
            @click="sutoFill('afterSaleNotice', '认养期间不可退款退货，如产品质量问题，可与平台联系并提供相应证据，按照问题实际情况进行协商处理，如协商不一致，将由平台客服根据平台规则进行判定。')"
          >
            填入
          </el-button>
        </div>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { quillEditor } from 'vue-quill-editor';
import dragDialog from '@/components/goods/dragDialog';
import LoadImgOss from '@/components/common/loadImgOss';

export default {
  components: {
    quillEditor,
    dragDialog,
    LoadImgOss,
  },
  props: ['disabled', 'tradeGoodsDetailDtos'],
  data() {
    return {
      ruleForm: {
        liveRule: '', // 入住规则
        ticketRule: '', // 退改须知
        saleRule: '', // 购买须知
        useRule: '', // 使用说明
        costRule: '', // 费用须知
        otherRule: '', // 其他说明

        adoptionRules: '', // 认养规则
        adoptionDeliverNotice: '', // 发货须知
        afterSaleNotice: '', // 售后须知
      },
      imgFileList: [],
      disableStatus: false,
      serveData: {
        templateId: '',
        goodsContent: '',
        checked: false,
      },
      editorOption: {
        placeholder: '商品详情图区域',
        modules: {
          toolbar: null,
          clipboard: {
            // 粘贴版，处理粘贴时候带图片
            matchers: [[Node.ELEMENT_NODE, this.handleCustomMatcher]],
          },
        },
      },
      // 商品详情图片编辑
      imgEditVisible: false,
      imgList: [], // 详情图片列表
      imgStr: '', // 缓存商品详情
      isMove: false, // 是否拖拽图片
      upApi: this.$api.nb_common.newUpload,
      goodsType: '',
      pageType: '',
      rules: {
        liveRule: [
          { required: true, message: '请输入入住规则', trigger: 'change' },
        ],
        ticketRule: [
          { required: true, message: '请输入退改须知', trigger: 'change' },
        ],
        saleRule: [
          { required: true, message: '请输入购买须知', trigger: 'change' },
        ],
        useRule: [
          { required: true, message: '请输入使用说明', trigger: 'change' },
        ],
        costRule: [
          { required: true, message: '请输入费用须知', trigger: 'change' },
        ],
        otherRule: [
          { required: true, message: '请输入其他说明', trigger: 'change' },
        ],
        adoptionRules: [
          { required: true, message: '请输入认养规则', trigger: 'change' },
        ],
        adoptionDeliverNotice: [
          { required: true, message: '请输入发货须知', trigger: 'change' },
        ],
        afterSaleNotice: [
          { required: true, message: '请输入售后须知', trigger: 'change' },
        ],
      },
    };
  },
  watch: {
    'serveData.templateId': 'changeFreight',
    suppliersId(newval) {
      // 解决接口异步传输问题
      this.getTemplate(newval);
    },
    checkTagList(newval) {
      this.tagList = newval;
    },
    disableStatus(newval) {
      console.log('编辑器状态', newval);
      if (newval) {
        quill.enable(false); // 禁止用户输入
      }
    },
    tradeGoodsDetailDtos(data) {
      if (this.pageType === 'edit' || this.disabled) {
        data
          && data.forEach((item) => {
            if (item.detailType == '1') {
              this.serveData.goodsContent = item.value;
            } else if (item.detailType == '2') {
              this.ruleForm.liveRule = item.value;
            } else if (item.detailType == '3') {
              this.ruleForm.ticketRule = item.value;
            } else if (item.detailType == '4') {
              this.ruleForm.saleRule = item.value;
            } else if (item.detailType == '5') {
              this.ruleForm.useRule = item.value;
            } else if (item.detailType == '6') {
              this.ruleForm.costRule = item.value;
            } else if (item.detailType == '7') {
              this.ruleForm.otherRule = item.value;
            } else if (item.detailType == '10') {
              this.ruleForm.afterSaleNotice = item.value;
            } else if (item.detailType == '11') {
              this.ruleForm.adoptionRules = item.value;
            } else if (item.detailType == '12') {
              this.ruleForm.adoptionDeliverNotice = item.value;
            }
          });
      }
    },
  },
  created() {
    this.goodsType = this.$route.query.goodsType;
    this.pageType = this.$route.query.type;
  },
  methods: {
    handleCustomMatcher(node, Delta) {
      let ops = [];
      Delta.ops.forEach((op) => {
        if (op.insert?.image?.includes('base64,')) {
          this.$message.error('不允许粘贴图片文件,请手动上传');
        } else {
          ops.push({
            insert: op.insert,
          });
        }
      });
      Delta.ops = ops;
      return Delta;
    },
    // imgSuccess(response, file, fileList) {
    //   if (response.error === 0) {
    //     this.serveData.goodsContent +=
    //       "<img src=" +
    //       response.data.url +
    //       " alt=" +
    //       response.data.title +
    //       " width='100%'>";
    //   }
    //   if (response.error === 1) {
    //     this.goodsDetail.fileList3 = [];
    //     this.$message(response.error_msg);
    //   }
    // },
    // 自动填入
    sutoFill(name, txt) {
      this.ruleForm[name] = txt;
    },
    goodsImgMove(val) {
      let str = '';
      this.isMove = true;
      val.forEach((item) => {
        str = str.concat(
          `<p style="margin: 0; padding: 0;"><img style="display: block" src='${item.url}' alt='${item.alt}'  width="100%"></p>`,
        );
      });
      this.imgStr = str;
    },
    // 删除--商品详情图片val
    goodsImgRemove(val) {
      this.isMove = true;
      let str = '';
      val.list.forEach((j, i) => {
        if (i === val.item.index) {
          val.list.splice(i, 1);
        }
      });
      val.list.forEach((item) => {
        str = str.concat(
          `<p style="margin: 0; padding: 0;"><img style="display: block" width="100%" src='${item.url}' alt='${item.alt}'></p>`,
        );
      });
      this.imgStr = str;
    },
    // 确认修改--商品详情图片
    submitImg() {
      this.imgEditVisible = false;
      if (this.isMove) {
        this.serveData.goodsContent = this.imgStr;
      }
      this.imgStr = '';
    },
    // 取消修改--商品详情图片
    cancelImg() {
      this.imgEditVisible = false;
      this.imgStr = '';
    },
    // 编辑图片
    editImgs() {
      this.isMove = false;
      let imgReg = /<img.*?(?:>|\/>)/gi;
      // eslint-disable-next-line no-useless-escape
      let srcReg = /src=[\'\"]?([^\'\"]*)[\'\"]?/i;
      // eslint-disable-next-line no-useless-escape
      let altReg = /alt=[\'\"]?([^\'\"]*)[\'\"]?/i;
      let arr = [];
      this.imgList = [];
      arr = this.serveData.goodsContent.match(imgReg) || [];
      arr.forEach((item, index) => {
        let src = item.match(srcReg);
        let alt = item.match(altReg);
        this.imgList.push({
          url: src[1],
          alt: alt ? alt[1] : '',
          title: alt ? alt[1] : '',
        });
      });
      this.imgEditVisible = true;
    },
    getForm(rank) {
      return new Promise((resolve) => {
        if (!this.serveData.goodsContent) {
          this.$message({
            type: 'error',
            message: '请完善商品详情（设置商品详情）',
          });
          return;
        }
        // liveRule: "", //入住规则
        // ticketRule: "", //退改须知
        // saleRule: "", //购买须知
        // useRule: "", //使用说明
        // costRule: "", //费用须知
        // otherRule: "", //其他说明
        if (
          (!this.ruleForm.liveRule && this.goodsType == '2')
        || (!this.ruleForm.ticketRule && this.goodsType == '2')
        || (!this.ruleForm.saleRule && (this.goodsType == '3' || this.goodsType == '4'))
        || (!this.ruleForm.useRule && (this.goodsType == '3' || this.goodsType == '4'))
        || (!this.ruleForm.costRule && (this.goodsType == '2' || this.goodsType == '3' || this.goodsType == '4'))
        || (!this.ruleForm.otherRule && (this.goodsType == '3' || this.goodsType == '4'))
        || (this.goodsType == '7' && (!this.ruleForm.adoptionRules || !this.ruleForm.adoptionDeliverNotice || !this.ruleForm.afterSaleNotice))
        ) {
          this.$message({
            type: 'error',
            message: '请完规则（设置商品详情）',
          });
          return;
        }
        let typeList = [];
        if (this.goodsType != '2' && this.goodsType != '3' && this.goodsType != '4' && this.goodsType != '7') {
          typeList = [1];
        } else if (this.goodsType == '2') {
          typeList = [1, 2, 3, 6];
        } else if (this.goodsType == '3' || this.goodsType == '4') {
          typeList = [1, 4, 5, 6, 7];
        } else if (this.goodsType == '7') {
          typeList = [1, 10, 11, 12];
        }
        let typeDetailList = [
          {
            detailType: 1,
            value: this.formatGoodsContent(this.serveData.goodsContent),
            id:
              this.tradeGoodsDetailDtos
              && this.tradeGoodsDetailDtos.find(
                (find) => find.detailType == 1,
              )
                ? this.tradeGoodsDetailDtos.find(
                  (find) => find.detailType == 1,
                ).id
                : null,
            goodsId: this.$route.query.goodsId
              ? this.$route.query.goodsId
              : null,
          },
          {
            detailType: 2,
            value: this.ruleForm.liveRule,
            id:
              this.tradeGoodsDetailDtos
              && this.tradeGoodsDetailDtos.find(
                (find) => find.detailType == 2,
              )
                ? this.tradeGoodsDetailDtos.find(
                  (find) => find.detailType == 2,
                ).id
                : null,
            goodsId: this.$route.query.goodsId
              ? this.$route.query.goodsId
              : null,
          },
          {
            detailType: 3,
            value: this.ruleForm.ticketRule,
            id:
              this.tradeGoodsDetailDtos
              && this.tradeGoodsDetailDtos.find(
                (find) => find.detailType == 3,
              )
                ? this.tradeGoodsDetailDtos.find(
                  (find) => find.detailType == 3,
                ).id
                : null,
            goodsId: this.$route.query.goodsId
              ? this.$route.query.goodsId
              : null,
          },
          {
            detailType: 4,
            value: this.ruleForm.saleRule,
            id:
              this.tradeGoodsDetailDtos
              && this.tradeGoodsDetailDtos.find(
                (find) => find.detailType == 4,
              )
                ? this.tradeGoodsDetailDtos.find(
                  (find) => find.detailType == 4,
                ).id
                : null,
            goodsId: this.$route.query.goodsId
              ? this.$route.query.goodsId
              : null,
          },
          {
            detailType: 5,
            value: this.ruleForm.useRule,
            id:
              this.tradeGoodsDetailDtos
              && this.tradeGoodsDetailDtos.find(
                (find) => find.detailType == 5,
              )
                ? this.tradeGoodsDetailDtos.find(
                  (find) => find.detailType == 5,
                ).id
                : null,
            goodsId: this.$route.query.goodsId
              ? this.$route.query.goodsId
              : null,
          },
          {
            detailType: 6,
            value: this.ruleForm.costRule,
            id:
              this.tradeGoodsDetailDtos
              && this.tradeGoodsDetailDtos.find(
                (find) => find.detailType == 6,
              )
                ? this.tradeGoodsDetailDtos.find(
                  (find) => find.detailType == 6,
                ).id
                : null,
            goodsId: this.$route.query.goodsId
              ? this.$route.query.goodsId
              : null,
          },
          {
            detailType: 7,
            value: this.ruleForm.otherRule,
            id:
              this.tradeGoodsDetailDtos
              && this.tradeGoodsDetailDtos.find(
                (find) => find.detailType == 7,
              )
                ? this.tradeGoodsDetailDtos.find(
                  (find) => find.detailType == 7,
                ).id
                : null,
            goodsId: this.$route.query.goodsId
              ? this.$route.query.goodsId
              : null,
          },
          {
            detailType: 10,
            value: this.ruleForm.afterSaleNotice,
            id:
              this.tradeGoodsDetailDtos
              && this.tradeGoodsDetailDtos.find(
                (find) => find.detailType == 10,
              )
                ? this.tradeGoodsDetailDtos.find(
                  (find) => find.detailType == 10,
                ).id
                : null,
            goodsId: this.$route.query.goodsId
              ? this.$route.query.goodsId
              : null,
          },
          {
            detailType: 11,
            value: this.ruleForm.adoptionRules,
            id:
              this.tradeGoodsDetailDtos
              && this.tradeGoodsDetailDtos.find(
                (find) => find.detailType == 11,
              )
                ? this.tradeGoodsDetailDtos.find(
                  (find) => find.detailType == 11,
                ).id
                : null,
            goodsId: this.$route.query.goodsId
              ? this.$route.query.goodsId
              : null,
          },
          {
            detailType: 12,
            value: this.ruleForm.adoptionDeliverNotice,
            id:
              this.tradeGoodsDetailDtos
              && this.tradeGoodsDetailDtos.find(
                (find) => find.detailType == 12,
              )
                ? this.tradeGoodsDetailDtos.find(
                  (find) => find.detailType == 12,
                ).id
                : null,
            goodsId: this.$route.query.goodsId
              ? this.$route.query.goodsId
              : null,
          },
        ];
        this.$refs.ruleForm.validate((valid) => {
          if (valid) {
            typeDetailList = typeDetailList.filter((fil) => {
              let find = typeList.find((find) => find === fil.detailType);
              return !!find;
            });
          }
        });
        resolve(typeDetailList);
      });
    },
    // 商品详情p标签样式修改
    formatGoodsContent(info) {
      const strGoodsContent = info.replaceAll('<p', '<p style="margin: 0; padding: 0;" ')
        .replaceAll('<img', '<img style="display: block; width: 100%" ');
      return strGoodsContent;
    },
    handleChange(data) {
      const { quill } = this.$refs.myTextEditor;
      // const addImageRange = quill.getSelection();
      const lastRange = this.serveData.goodsContent?.length || 0;
      quill.insertEmbed(lastRange, 'image', data[0].url);
    },
  },
};
</script>

<style lang="scss">
.upload-demo {
  width: 360px;
}
.quillBox {
  width: 360px;
  float: left;
}
.ql-editor {
  height: 700px !important;
  padding: 0;
}
.ql-editor img {
  cursor: move !important;
  width: 100%;
  display: block;
}
.detail-info {
  .rule_box {
    color: #666;
    line-height: 14px;
    margin-top: 5px;
  }
}
</style>
