var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {},
    [
      _c("h1", { staticClass: "add_goods_title" }, [_vm._v(" 发货时间 ")]),
      _c(
        "el-form",
        { attrs: { "label-width": "110px" } },
        [
          _c(
            "el-form-item",
            { attrs: { label: "发货时间：", required: "" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _setup.freightTime.deliveryTimeType,
                    callback: function ($$v) {
                      _vm.$set(_setup.freightTime, "deliveryTimeType", $$v)
                    },
                    expression: "freightTime.deliveryTimeType",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: 0 } }, [
                    _vm._v(" 24小时发货 "),
                  ]),
                  _c("el-radio", { attrs: { label: 1 } }, [
                    _vm._v(" 48小时发货 "),
                  ]),
                  _c(
                    "el-radio",
                    { attrs: { label: 2 } },
                    [
                      _vm._v(" 大于48小时发货 "),
                      _c("el-input-number", {
                        staticClass: "margin-l20 margin-r10",
                        attrs: {
                          size: "mini",
                          precision: 0,
                          controls: false,
                          min: 3,
                          max: 90,
                          placeholder: "3~90天",
                        },
                        model: {
                          value: _setup.freightTime.deliveryDays,
                          callback: function ($$v) {
                            _vm.$set(_setup.freightTime, "deliveryDays", $$v)
                          },
                          expression: "freightTime.deliveryDays",
                        },
                      }),
                      _vm._v("天内发货 "),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }