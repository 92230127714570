<template>
  <div>
    <div>
      <!-- 商品基本信息 -->
      <BaseInfo
        ref="baseInfo"
        class="component"
        :disabled="type === 'show'"
        :trade-goods-dto="addDetail.tradeGoodsDto"
        :trade-shop-goods-relation-dtos="addDetail.tradeShopGoodsRelationDtos"
      />

      <!-- 品牌 -->
      <BrandInfo
        v-if="[1,2,5,6,7,8,9].includes(Number(goodsType))"
        :disabled="type === 'show' || (type === 'edit' && isOnSale == 1)"
        :show-authorization="Number(goodsType) === 1 && !isAssembly"
        :goods-detail-brand-info="addDetail.goodsDetailBrandInfo"
        :backend-category-id="query.backendCategoryId"
        :suppliers-id="query.suppliersId"
        @change="e=>addDetail.goodsDetailBrandInfo = e"
      />

      <!-- 基础保障 -->
      <GuaranteeInfo
        v-if="[1].includes(+goodsType) || isAssembly"
        ref="guaranteeRef"
        :disabled="type === 'show' || (type === 'edit' && isOnSale == 1)"
        :goods-base-guarantees="addDetail.goodsBaseGuarantees"
      />

      <!-- 特殊认证 -->
      <Authentication
        v-if="goodsType == 1 && !isAssembly"
        ref="authenticationRef"
        :disabled="type === 'show'"
        :goods-certificates="addDetail.goodsCertificates"
      />

      <!-- 属性 -->
      <AttrInfo
        v-if="![5,6,8,9].includes(+goodsType) && !isAssembly"
        ref="attrInfo"
        class="component"
        :disabled="type === 'show' || (type === 'edit' && isOnSale == 1)"
        people="gys"
        :trade-attribute-dtos="addDetail.tradeAttributeDtos"
        :trade-goods-attribute-relation-dtos="
          addDetail.tradeGoodsAttributeRelationDtos
        "
      />

      <!-- 商品基础属性 -->
      <BaseGoodsAttr
        v-if="goodsType == '7'"
        id="imei"
        ref="baseGoodsAttr-imei"
        title="IMEI编号"
        type="string"
        :required="true"
        :disabled="type === 'show'"
        :trade-goods-dto="addDetail.tradeGoodsDto"
      />

      <!-- 发货次数-认养类目 -->
      <DeliverGoodsNum
        v-if="goodsType == '7'"
        :disabled="type === 'show'"
        :shipments.sync="addDetail.shipments"
      />

      <!-- 规格 -->
      <SkuInfo
        v-if="![2,9].includes(+goodsType)"
        ref="skuInfo"
        class="component"
        :disabled="type === 'show' || (type === 'edit' && isOnSale == 1)"
        :trade-spec-dtos="addDetail.tradeSpecDtos"
        :trade-goods-sku-dtos="addDetail.tradeGoodsSkuDtos"
        :trade-service-tag-dtos.sync="addDetail.tradeServiceTagDtos"
        :project-code="projectCode"
        :supply="supply"
        :is-assembly="isAssembly"
        :is-not-sale="isNotSale"
      />

      <!-- 商详 -->
      <DetailInfo
        ref="detailInfo"
        class="component"
        :disabled="type === 'show'"
        :trade-goods-detail-dtos="addDetail.tradeGoodsDetailDtos"
      />

      <!-- 预定须知 -->
      <BookRule
        v-if="[5,6,8,9].includes(+goodsType)"
        :disabled="type === 'show' || (type === 'edit' && isOnSale == 1)"
        class="component"
        :info="ployRule"
        @getBookData="getBookData"
      />

      <!-- 标签 -->
      <ServeInfo
        :disabled="type === 'show' || (type === 'edit' && isOnSale == 1)"
        people="gys"
        :trade-service-tag-dtos.sync="addDetail.tradeServiceTagDtos"
        :is-assembly="isAssembly"
      />

      <!-- 关联供应商 -->
      <SupplierInfo
        v-if="userInfo.isMemberStore"
        ref="supplierRef"
        people="gys"
        :disabled="type === 'show' || (type === 'edit' && isOnSale == 1)"
        :trade-goods-dto="addDetail.tradeGoodsDto"
        :supply="supply"
      />

      <template v-if="[1,7].includes(+goodsType)">
        <!-- 快递模板 + 发货时间 -->
        <FreightInfo
          ref="freightInfo"
          :disabled="type === 'show' || (type === 'edit' && isOnSale == 1)"
          people="gys"
          :trade-template-goods-relation-dtos="
            addDetail.tradeTemplateGoodsRelationDtos
          "
          :freight-time="freightTime"
        />
      </template>

      <!-- 售卖时间 -->
      <SellTime
        v-show="![9].includes(+goodsType) && !isNotSale"
        ref="sellTimeRef"
        :disabled="type === 'show' || (type === 'edit' && isOnSale == 1)"
        :trade-goods-dto="addDetail.tradeGoodsDto"
      />

      <!-- 销售对象 -->
      <SaleTarget
        v-if="userInfo.isMemberStore && goodsType == '1'"
        ref="saleTargetRef"
        people="gys"
        :disabled="type === 'show' || (type === 'edit' && isOnSale == 1)"
        :trade-goods-dto="addDetail.tradeGoodsDto"
      />
    </div>
    <!-- 民宿 -->
    <template v-if="[2].includes(+goodsType)">
      <el-button
        v-if="type == 'add'"
        type="primary"
        size="small"
        @click="toSubmit('homeAdd')"
      >
        下一步（设置房源）
      </el-button>
      <el-button
        v-if="type == 'edit' && goodStatus == 0"
        type="primary"
        size="small"
        @click="toSubmit('homeEditSetPrice')"
      >
        下一步（设置房源）
      </el-button>
      <el-button
        v-if="type == 'edit' && goodStatus == 0 && addDetail.tradeGoodsSkuDtos && addDetail.tradeGoodsSkuDtos.length > 0"
        type="primary"
        size="small"
        @click="toSubmit('homeEditAuto')"
      >
        提交审核
      </el-button>
      <el-button
        v-if="type == 'edit' && goodStatus == 3"
        type="primary"
        size="small"
        @click="toSubmit('homeEditAuto')"
      >
        提交审核
      </el-button>
      <el-button
        v-if="type == 'edit' && goodStatus == 2"
        type="primary"
        size="small"
        @click="toSubmit('homeEdit')"
      >
        保 存
      </el-button>
      <el-button
        v-if="type == 'edit' && goodStatus == 2"
        type="primary"
        size="small"
        @click="toSubmit('homeEditPrice')"
      >
        保存并设置房源
      </el-button>
    </template>
    <!-- 非民宿 -->
    <template v-if="![2,8,9].includes(+goodsType) && !isAddSuppleGoods()">
      <el-button
        v-if="type == 'add'"
        type="primary"
        size="small"
        @click="toSubmit('add')"
      >
        保 存
      </el-button>
      <el-button
        v-if="type == 'add'"
        type="primary"
        size="small"
        @click="toSubmit('addAndAudit')"
      >
        创建并提交审核
      </el-button>
      <el-button
        v-if="type == 'edit' && goodStatus != 2"
        type="primary"
        size="small"
        @click="toSubmit('edit')"
      >
        保 存
      </el-button>
      <el-button
        v-if="type == 'edit' && goodStatus != 2"
        type="primary"
        size="small"
        @click="toSubmit('editAndAudit')"
      >
        提交审核
      </el-button>
      <el-button
        v-if="type == 'edit' && goodStatus == 2"
        type="primary"
        size="small"
        @click="toSubmit('editAuditOpen')"
      >
        保 存
      </el-button>
      <el-button
        v-if="type == 'show' && pageAudit == '1'"
        type="primary"
        size="small"
        @click="showAudit = true"
      >
        审 核
      </el-button>
      <!-- <el-button @click="toBack()" size="small">返回</el-button> -->
    </template>
    <!-- 权益卡\采摘日票 -->
    <template v-if="[8].includes(+goodsType) && type !== 'show'">
      <el-button
        v-if="+isOnSale === 1"
        type="primary"
        size="small"
        @click="toSubmit('edit')"
      >
        保 存
      </el-button>
      <el-button
        v-else
        type="primary"
        size="small"
        @click="toNextPage"
      >
        下一步(设置权益)
      </el-button>
    </template>
    <!-- 权益卡\采摘日票 -->
    <template v-if="[9].includes(+goodsType) && type !== 'show'">
      <el-button
        v-if="+goodStatus === 2"
        type="primary"
        size="small"
        @click="toSubmit('edit')"
      >
        保 存
      </el-button>
      <template v-else>
        <el-button
          v-if="type === 'add'"
          type="primary"
          size="small"
          @click="toNextPage"
        >
          下一步(设置规格)
        </el-button>
        <el-button
          v-else
          type="primary"
          size="small"
          @click="toSubmit('editAndAudit')"
        >
          提交审核
        </el-button>
      </template>
    </template>
    <template v-if="isAddSuppleGoods()">
      <el-button
        type="primary"
        size="small"
        @click="toSubmit('addAndAudit')"
      >
        提交审核
      </el-button>
    </template>
    <!-- 预览 -->
    <el-button
      v-if="isShowViewGoods()"
      type="primary"
      size="small"
      @click="onShowPreview"
    >
      预 览
    </el-button>
    <div>
      <AuditModel
        :name="addDetail.tradeGoodsDto ? addDetail.tradeGoodsDto.name : ''"
        :show-audit="showAudit"
        @closeModel="closeModel"
      />
    </div>

    <ShowGoods
      v-if="showGoods"
      :id="query.goodsId"
      :show-goods="showGoods"
      :goods-type="query.goodsType"
      @closeShowGoods="showGoods=false"
    />
  </div>
</template>

<script>
import BaseGoodsAttr from './baseGoodsAttr';
import BaseInfo from './baseInfo';
import AttrInfo from './attrInfo';
import SkuInfo from './skuInfo';
import DeliverGoodsNum from './deliverGoodsNum';
import DetailInfo from './detailInfo';
import ServeInfo from './serveInfo';
import SupplierInfo from './supplierInfo';
import FreightInfo from './freightInfo';
import AuditModel from '../auditModel';
import ShowGoods from './components/showGoods';
import BookRule from './bookRule';
import SellTime from './sellTime';
import SaleTarget from './saleTarget';
import BrandInfo from './brandInfo'; // 品牌
import Authentication from './authentication'; // 特殊认证
import GuaranteeInfo from './guaranteeInfo'; // 基础保障

export default {
  components: {
    BaseGoodsAttr,
    BaseInfo,
    AttrInfo,
    SkuInfo,
    DeliverGoodsNum,
    DetailInfo,
    ServeInfo,
    SupplierInfo,
    FreightInfo,
    AuditModel,
    ShowGoods,
    BookRule,
    SellTime,
    BrandInfo, // 品牌
    Authentication, // 特殊认证
    GuaranteeInfo, // 基础保障
    SaleTarget, // 销售对象
  },
  data() {
    return {
      isLimit: 0,
      query: {},
      goodsType: '',
      type: '',
      pageAudit: '',
      showAudit: false,
      showGoods: false,
      addDetail: {
        shipments: 1, // 发货次数
        tradeGoodsDto: {}, // 商品详情
        // tradeGoodsBrandDto: null, // 品牌信息
        goodsDetailBrandInfo: null, // 品牌信息
        goodsCertificates: null, // 特殊认证
        tradeAttributeDtos: [], // 所有属性
        tradeGoodsAttributeRelationDtos: [], // 选中的属性
        tradeSpecDtos: [], // 规格列表
        tradeGoodsSkuDtos: [], // sku列表
        tradeServiceTagDtos: null, // 标签
        tradeTemplateGoodsRelationDtos: [], // 运费模板
      },
      goodStatus: 0, // 0：未提审 1：审核中 2：审核通过 3：不通过
      ployRule: [], // 预订规则数据
      // goodsDetail: {},
      goodsBuyType: 0,
      isAssembly: false, // 是否组合商品 goodsBuyType = 1，3
      isNotSale: false, // 非买品 goodsBuyType = 2，3
      isOnSale: 0, // 是否上架
      projectCode: 3300, // 判断鱼米之乡和网上农博
      userInfo: null, // 用户信息
      supply: { // 供应商商品信息
        boolean: false,
        name: '',
        type: 'add',
      },
      freightTime: {
        deliveryTimeType: 1,
        deliveryDays: undefined,
      }, // 发货时间
    };
  },
  created() {
    let userInfo = localStorage.getItem('userInfo');
    this.isLimit = userInfo && JSON.parse(userInfo).isLimit ? userInfo && JSON.parse(userInfo).isLimit : 0;
    this.query = this.$route.query;
    this.goodsType = this.$route.query.goodsType;
    this.type = this.$route.query.type;
    this.pageAudit = this.$route.query.audit;
    this.goodsBuyType = this.$route.query.goodsBuyType;
    this.isAssembly = ['1', '3'].includes(this.goodsBuyType);
    this.isNotSale = ['2', '3'].includes(this.goodsBuyType);
    this.projectCode = Number(localStorage.getItem('projectCode')) || 3300;
    this.userInfo = userInfo ? JSON.parse(userInfo) : null;
  },
  mounted() {
    this.getDetail();
    // 新建商预览
    if (this.query.showH5 === '1') {
      this.showGoods = true;
    }
  },
  methods: {
    getBookData(data) {
      this.ployRule = data;
    },
    getDetail() {
      let params = {
        id:
          this.type === 'add'
            ? this.query.backendCategoryId
            : this.query.goodsId,
        type: this.type === 'add' ? 2 : 1,
      };
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(255, 255, 255, 0.7)',
      });
      this.$axios({
        method: 'get',
        url: this.$api.goodsmanage.trade_goods_detail,
        params,
      }).then(async (res) => {
        if (res.code === 0) {
          this.addDetail = res.data;
          // 回填供货商品信息
          if (this.query.suppliedGoodsType === 'add') {
            this.addDetail.tradeGoodsDto.belongSupplierName = this.query.suppliersName;
            this.supply = {
              boolean: true,
              name: this.query.suppliersName,
              type: 'add',
            };
            // 处理数据
            await this.formatNewSuppleGoods();
          } else if (this.addDetail.tradeGoodsDto?.isSupplyChainGoods) { // 是否是供应商一键绑品商品
            this.supply = {
              boolean: true,
              name: this.addDetail.tradeGoodsDto.belongSupplierName,
              type: 'edit',
            };
          }
          // 发货时间数据
          const deliveryTimeType = res.data.tradeGoodsDto?.extendDTO?.deliveryTimeType;
          this.freightTime.deliveryTimeType = deliveryTimeType !== undefined ? deliveryTimeType : 1;
          this.freightTime.deliveryDays = res.data.tradeGoodsDto?.extendDTO?.deliveryDays || undefined;
          // 发货次数处理
          if (this.addDetail.tradeGoodsDto && this.addDetail.tradeGoodsDto.shipments) {
            this.addDetail.shipments = this.addDetail.tradeGoodsDto.shipments;
          } else {
            this.addDetail.shipments = 1;
          }

          this.isOnSale = res.data.tradeGoodsDto && res.data.tradeGoodsDto.isOnSale;// 上下架
          this.goodStatus = res.data.tradeGoodsDto ? res.data.tradeGoodsDto.status : 0;
          if (this.addDetail.tradeGoodsDetailDtos) {
            this.ployRule = this.addDetail.tradeGoodsDetailDtos.filter((item) => [5, 8, 9, 10, 16].includes(Number(item.detailType)));
          }
        }
        loading.close();
        this.$nextTick(() => {
          document.getElementsByClassName('app-main')[0].scrollTop = 0;
        });
      });
    },
    async toSubmit(name, showH5) {
      if (this.isLimit == 1) {
        this.$message({ type: 'error', message: '您当前暂无权限，详情请联系客服' });
        return;
      }

      let message = '是否确认保存?';
      let tradeGoodsDto;
      let attrInfoForm;
      let skuInfoForm;
      let detailInfoForm;
      let freightInfoForm;
      let authenticationForm; // 特殊认证
      let supplierForm; // 关联供应商
      if (name == 'add' || name == 'edit') {
        tradeGoodsDto = await this.$refs.baseInfo.getForm('easy');
        attrInfoForm = this.$refs.attrInfo ? await this.$refs.attrInfo.getForm('easy') : null; // 商品属性
        skuInfoForm = this.$refs.skuInfo ? await this.$refs.skuInfo.getForm('easy') : {
          skuData: this.addDetail.tradeSpecDtos,
          skuTable: this.addDetail.tradeGoodsSkuDtos,
        }; // sku规格
        detailInfoForm = this.$refs.detailInfo ? await this.$refs.detailInfo.getForm('easy') : []; // 详情
        freightInfoForm = this.goodsType == '1' || this.goodsType == '7' ? await this.$refs.freightInfo.getForm('easy') : []; // 运费模板
      } else if (name == 'addAndAudit' || name == 'editAndAudit' || name == 'editAuditOpen' || name == 'homeAdd' || name == 'homeEditSetPrice' || name == 'homeEditNoAuto' || name == 'homeEditAuto' || name == 'homeEdit' || name == 'homeEditPrice') {
        if (name == 'editAuditOpen' || name == 'homeEdit') {
          message = '是否保存编辑?';
        } if (name == 'homeAdd' || name == 'homeEditSetPrice' || name == 'homeEditNoAuto' || name == 'homeEditPrice') {
          message = '是否保并去设置房源?';
        } else {
          message = '是否确认提交审核?';
        }
        tradeGoodsDto = await this.$refs.baseInfo.getForm();
        attrInfoForm = this.$refs.attrInfo ? await this.$refs.attrInfo.getForm() : null; // 商品属性
        skuInfoForm = this.$refs.skuInfo ? await this.$refs.skuInfo.getForm() : {
          skuData: this.addDetail.tradeSpecDtos,
          skuTable: this.addDetail.tradeGoodsSkuDtos,
        }; // sku规格
        detailInfoForm = this.$refs.detailInfo ? await this.$refs.detailInfo.getForm() : []; // 详情
        freightInfoForm = this.goodsType == '1' || this.goodsType == '7' ? await this.$refs.freightInfo.getForm() : []; // 运费模板
      }
      // 特殊认证
      authenticationForm = this.$refs.authenticationRef ? await this.$refs.authenticationRef.getForm() : null;
      // 关联供应商
      supplierForm = this.$refs.supplierRef ? await this.$refs.supplierRef.getForm() : null;

      // 判断商品单独属性,//IMEI编号
      let imei = this.$refs['baseGoodsAttr-imei'] ? await this.$refs['baseGoodsAttr-imei'].getForm() : null;

      if (showH5) {
        message = '确定保存商品信息并预览?';
      }
      if (!this.addDetail.tradeServiceTagDtos || this.addDetail.tradeServiceTagDtos.length == 0) {
        this.$message({ type: 'error', message: '请选择服务标签' });
        return;
      }
      // 换购商品提示
      // console.log(skuInfoForm.editSkuList,'修改的sku')
      if (this.type == 'edit' && this.isOnSale != 1 && this.addDetail.tradeGoodsDto && this.addDetail.tradeGoodsDto.isExchange == 1) {
        message = '该商品已参加了加价换购活动，修改商品规格可能导致活动中的原规格失效，确认保存？';
        this.isExchange = 1;
      }
      // 判断商品是否被组合
      if (this.type == 'edit' && this.isOnSale != 1 && this.addDetail.tradeGoodsDto && this.addDetail.tradeGoodsDto.isPartOfAssembly == 1) {
        message = '该商品规格已被组合成组合商品规格，修改商品规格可能导致组合商品失效，确认保存？';
        this.isPartOfAssembly = 1;
      }
      if (this.isExchange == 1 && this.isPartOfAssembly == 1) {
        message = '该商品已参加了加价换购活动,且被组合成组合商品规格，修改商品规格可能导致活动中的原规格和组合商品失效，确认保存？';
      }
      // 设置售卖时间
      this.sellTimeData = await this.$refs.sellTimeRef.getForm(); // 售卖时间

      // 预订规则
      if ([6, 8, 9].includes(+this.goodsType)) {
        let title;
        let reluList;
        if ([6].includes(+this.goodsType)) {
          title = '预订规则';
          reluList = [
            { type: 8, message: `请完善有效日期（${title}）` },
            { type: 9, message: `请完善预约规则（${title}）` },
            { type: 10, message: `请完善售后政策（${title}）` },
            { type: 5, message: `请完善使用说明（${title}）` },
          ];
        } else if ([8].includes(+this.goodsType)) {
          title = '购买须知';
          reluList = [
            { type: 16, message: `请完善权益卡名称（${title}）` },
            { type: 8, message: `请完善权益卡有效期（${title}）` },
            { type: 10, message: `请完善售后政策（${title}）` },
            { type: 5, message: `请完善使用说明（${title}）` },
          ];
        } else if ([9].includes(+this.goodsType)) {
          title = '购买须知';
          reluList = [
            { type: 9, message: `请完善预约规则（${title}）` },
            { type: 10, message: `请完善售后政策（${title}）` },
            { type: 5, message: `请完善使用说明（${title}）` },
          ];
        }
        console.log(this.ployRule, 999);
        for (let myrule of reluList) {
          const finder = this.ployRule.find((find) => +find.detailType === myrule.type);
          console.log(finder, myrule);
          if (!finder) {
            this.$message({ type: 'error', message: `${myrule.message}` });
            return;
          }
          if ([8].includes(finder.detailType)) {
            // 有效期
            if (finder.optionalType) {
              if ((+finder.optionalType === 2 || +finder.optionalType === 5) && !finder.value) {
                this.$message({ type: 'error', message: `${myrule.message}` });
                return;
              }
            } else {
              this.$message({ type: 'error', message: `${myrule.message}` });
              return;
            }
          } else if ([9].includes(finder.detailType)) {
            // 预约规则
            if (+finder.optionalType === 5 && !finder.value) {
              this.$message({ type: 'error', message: `${myrule.message}` });
            } else if (!finder.optionalType && !finder.value) {
              this.$message({ type: 'error', message: `${myrule.message}` });
            }
          } else if ([10].includes(finder.detailType) && !finder.optionalType) {
            // 售后政策
            this.$message({ type: 'error', message: `${myrule.message}` });
            return;
          } else if (!finder.value) {
            this.$message({ type: 'error', message: `${myrule.message}` });
            return;
          }
        }
      }

      // 发货时间判断 选择普通快递或同城配送
      if ([1, 7].includes(+this.goodsType)
      && freightInfoForm.find((item) => [1, 2].includes(+item.templateType))) {
        let { deliveryTimeType, deliveryDays } = this.freightTime;
        if (![0, 1, 2].includes(deliveryTimeType) || (deliveryTimeType === 2 && !deliveryDays)) {
          this.$message({ type: 'error', message: '请完善发货时间' });
          return;
        }
      }

      console.log([...detailInfoForm, ...this.ployRule], '商品详情数据dto');
      this.$confirm(message, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(async () => {
          const loading = this.$loading({
            lock: true,
            text: 'Loading',
            spinner: 'el-icon-loading',
            background: 'rgba(255, 255, 255, 0.7)',
          });
          // if((this.goodsType == '1' || this.goodsType == '3' || this.goodsType == '4') && this.type == 'edit'){
          //   let skuApi = await this.$refs.skuInfo.onSubmit();
          //   if(skuApi && skuApi.code === 0){
          //     console.log('规格编辑成功')
          //   }else {
          //     loading.close();
          //     return
          //   }
          // }
          let apiUrl = this.$api.goodsmanage.trade_goods_add;
          if (name === 'edit' || name === 'editAndAudit' || name === 'editAuditOpen' || name === 'homeEdit' || name == 'homeEditSetPrice' || name === 'homeEditNoAuto' || name === 'homeEditAuto' || name === 'homeEditPrice') {
            apiUrl = this.$api.goodsmanage.trade_goods_edit;
          }
          let status = 0;// 0：未提审 1：审核中 2：审核通过 3：不通过
          if (showH5) {
            status = this.goodStatus;
          } else if (name === 'editAndAudit' || name === 'addAndAudit' || name === 'homeEditAuto') {
            status = 1;
          } else if (this.goodStatus == 2 || name === 'editAuditOpen' || name === 'homeEdit' || name === 'homeEditPrice') {
            status = 2;
          } else {
            status = 0;
          }

          let params = {
            // 商品基本信息
            tradeGoodsDto: {
              ...tradeGoodsDto.baseResolve,
              status,
              ...this.sellTimeData, // 售卖时间
              shipments: this.addDetail.shipments, // 发货次数
              imei, // IMEI编号
              belongSupplierName: supplierForm, // 关联供应商
              saleTarget: this.$refs.saleTargetRef ? this.$refs.saleTargetRef.radio : 0, // 售卖对象
              extendDTO: this.freightTime, // 发货时间
            },
            // 基础保障
            goodsBaseGuarantees: this.$refs.guaranteeRef ? this.$refs.guaranteeRef.checkList : [],
            goodsDetailBrandInfo: this.addDetail.goodsDetailBrandInfo, // 品牌信息
            goodsCertificates: authenticationForm, // 特殊认证
            tradeShopGoodsRelationDtos: tradeGoodsDto.shopResolve, // 关联门店
            tradeGoodsAttributeRelationDtos: attrInfoForm, // 属性列表
            tradeSpecDtos: skuInfoForm.skuData, // 规格列表
            tradeGoodsSkuDtos: [2, 9].includes(Number(this.goodsType)) ? null : (skuInfoForm.skuTable && skuInfoForm.skuTable.map((maps) => {
              return { ...maps, marketPrice: maps.marketPrice == '' ? 0 : maps.marketPrice };
            })), // sku详情列表
            tradeGoodsDetailDtos: [...detailInfoForm, ...this.ployRule], // 详情以及使用说明,// 预订规则
            tradeServiceTagDtos: this.addDetail.tradeServiceTagDtos, // 标签列表
            tradeTemplateGoodsRelationDtos: freightInfoForm, // 运费模板关系列表
          };
          // 新建供应商品
          if (this.isAddSuppleGoods()) {
            this.$axios
              .post(this.$api.supplychain.relation, params)
              .then((res) => {
                loading.close();
                if (res.code === 0) {
                  this.$message({ type: 'success', message: '提交成功！' });
                  this.$router.replace('/nb/goods/index');
                }
              });
            return;
          }
          this.$axios
            .post(apiUrl, params)
            .then((res) => {
              if (res.code === 0) {
                if (this.isExchange && this.isExchange == 1 && skuInfoForm.editSkuList && skuInfoForm.editSkuList.length > 0) {
                  this.setExchange(skuInfoForm.editSkuList);
                }
                this.$message({ type: 'success', message: '操作成功！' });
                if (name === 'homeAdd' || name == 'homeEditSetPrice' || name === 'homeEditPrice' || name === 'homeEditNoAuto') {
                  this.$router.push({
                    path: '/nb/goods/homeSet',
                    query: {
                      goodsId: res.data,
                      goodsBuyType: this.goodsBuyType,
                    },
                  });
                } else if (this.nextRouterPath) {
                  // 下一步
                  this.$router.push({
                    path: this.nextRouterPath,
                    query: {
                      goodsId: res.data,
                      goodStatus: this.goodStatus,
                      ...this.$route.query,
                    },
                  });
                } else if (showH5) {
                  const query = {
                    ...this.query,
                    showH5: '1',
                  };
                  if (name === 'add') {
                    query.type = 'edit';
                    query.goodsId = res.data;
                  }
                  this.$router.replace({
                    path: '/nb/goods/detail',
                    query,
                  });
                  window.location.reload();
                } else {
                  this.$router.push('/nb/goods/index');
                }
                // 采摘日票类型，兼容关联门店被修改的情况
                if (+this.goodsType === 9 && this.query.goodsId) {
                  this.$axios.get(this.$api.goods_pick.resetShopSku, {
                    params: {
                      goodsId: this.query.goodsId,
                      shopIds: params.tradeShopGoodsRelationDtos.map((item) => item.shopId).join(','),
                    },
                  });
                }
              }
              loading.close();
            })
            .catch(() => {
              loading.close();
            });
        })
        .catch(() => {});
    },
    // 下一步权益设置/规格
    toNextPage() {
      if (+this.goodsType === 8) {
        this.nextRouterPath = '/nb/goods/equityCardSet';
      } else if (+this.goodsType === 9) {
        this.nextRouterPath = '/nb/goods/pickCardSet';
      }
      if (this.type === 'add') {
        this.toSubmit('add');
      } else {
        this.toSubmit('edit');
      }
    },
    closeModel() {
      this.showAudit = false;
    },
    toBack() {
      if (this.query.type === 'show' && this.query.audit != 1) {
        this.$router.go(-1);
        return;
      }
      this.$confirm('确定返回列表吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.$router.go(-1);
        })
        .catch(() => {});
    },

    // 换购商品处理
    setExchange(arr) {
      this.$axios
        .post(this.$api.goodsmanage.trade_goods_checkExchange, arr)
        .then((res) => {
          if (res.code === 0) {

          }
        });
    },
    // 预览
    onShowPreview() {
      if (this.query.type === 'show') {
        this.showGoods = true;
      } else if (this.query.type === 'edit') {
        this.toSubmit('edit', true);
      } else if (this.query.type === 'add') {
        this.toSubmit('add', true);
      }
    },
    formatNewSuppleGoods() {
      return new Promise((resolve) => {
        console.log(this.addDetail, '处理数据=========');
        this.addDetail.tradeGoodsDto.status = 0;
        this.addDetail.tradeGoodsDto.isOnSale = 0;
        // 品牌
        // if (+this.addDetail.tradeGoodsBrandDto?.brandType === 1) { // 0.平台品牌 1.商家品牌
        //   this.addDetail.tradeGoodsBrandDto = null;
        // }
        // 属性
        const attrList = this.addDetail.tradeAttributeDtos;
        const attrListRelation = this.addDetail.tradeGoodsAttributeRelationDtos;
        if (attrList) {
          this.addDetail.tradeAttributeDtos = attrList.filter((item) => +item.belongType === 1);
        }
        // if (attrListRelation) {
        //   this.addDetail.tradeGoodsAttributeRelationDtos = attrListRelation.filter((item) => +item.belongType === 1);
        // }
        // 标签
        const tagList = this.addDetail.tradeServiceTagDtos;
        if (tagList) {
          this.addDetail.tradeServiceTagDtos = tagList.filter((item) => +item.belongType === 1);
        }
        // 门店
        this.addDetail.tradeShopGoodsRelationDtos = null;
        // 运费模板
        this.addDetail.tradeTemplateGoodsRelationDtos = null;

        // if (this.query.type === 'edit') {
        //   this.$router.replace({
        //     query: {
        //       ...this.query,
        //       type: 'add',
        //     },
        //   });
        // }
        console.log(this.addDetail, '处理后的数据=====');
        resolve();
      });
    },
    isAddSuppleGoods() {
      return this.supply.boolean && this.supply.type === 'add';
    },
    isShowViewGoods() {
      return ![9].includes(+this.goodsType) // 不是日票商品
      && !this.isAddSuppleGoods() // 不是供应链商品
      && !([2].includes(+this.goodsType) && this.goodStatus === 0); // 不是名宿 且 未提审 （新增名宿商品）
    },
  },
};
</script>
<style lang="scss">
.add_goods_title {
  font-weight: bold;
  margin-bottom: 10px;
  span {
        font-weight: normal;
        font-size: 14px;
        color: red;
        margin-left: 20px;
    }
}
</style>
<style scoped lang="scss">
.title {
  span {
        font-weight: normal;
        font-size: 14px;
        color: red;
        margin-left: 20px;
    }
}
</style>
