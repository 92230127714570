var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "img-drag" },
    [
      _c(
        "draggable",
        _vm._b(
          {
            staticClass: "drag-box",
            attrs: { element: "div", move: _vm.onMove },
            on: {
              start: function ($event) {
                _vm.isDragging = true
              },
              end: function ($event) {
                _vm.isDragging = false
              },
            },
            model: {
              value: _vm.list,
              callback: function ($$v) {
                _vm.list = $$v
              },
              expression: "list",
            },
          },
          "draggable",
          _vm.dragOptions,
          false
        ),
        _vm._l(_vm.list, function (item, index) {
          return _c("div", { key: index, staticClass: "list-group" }, [
            _c("img", { attrs: { src: item.url, alt: "" } }),
            _c(
              "p",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: item.title,
                    expression: "item.title",
                  },
                ],
              },
              [_vm._v(" " + _vm._s(item.title) + " ")]
            ),
            _c("i", {
              staticClass: "iconfont el-icon-close",
              on: {
                click: function ($event) {
                  return _vm.remove(item, index)
                },
              },
            }),
          ])
        }),
        0
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }