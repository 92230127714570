var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "brand_model margin-b50" },
    [
      _c("h1", { staticClass: "title" }, [_vm._v(" 商品品牌 ")]),
      _c(
        "el-form",
        { attrs: { "label-width": "120px" } },
        [
          _c(
            "el-form-item",
            { attrs: { label: "自主品牌(单选)：" } },
            [
              _c(
                "div",
                [
                  !_setup.selfBrand.length
                    ? _c("span", [_vm._v("/")])
                    : _vm._e(),
                  _vm._l(_setup.selfBrand, function (item, i) {
                    return _c(
                      "span",
                      { key: i, staticClass: "margin-r10" },
                      [
                        _c(_setup.BrandTag, {
                          attrs: { item: item, disabled: _vm.disabled },
                          on: { close: _setup.closeSelfBrand },
                        }),
                        item.isPublic === 1
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _setup.onShowDetail(item)
                                  },
                                },
                              },
                              [_vm._v("查看证明")]
                            )
                          : _vm._e(),
                      ],
                      1
                    )
                  }),
                ],
                2
              ),
              !_vm.disabled
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "small" },
                      on: {
                        click: function ($event) {
                          return _setup.setBrand(1)
                        },
                      },
                    },
                    [_vm._v(" 关联品牌 ")]
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm.showAuthorization
            ? _c(
                "el-form-item",
                { attrs: { label: "授权品牌(多选)：" } },
                [
                  _c(
                    "div",
                    [
                      !_setup.authorizationList.length
                        ? _c("span", [_vm._v("/")])
                        : _vm._e(),
                      _vm._l(_setup.authorizationList, function (item, i) {
                        return _c(
                          "span",
                          { key: i, staticClass: "margin-r10" },
                          [
                            _c(_setup.BrandTag, {
                              attrs: { item: item, disabled: _vm.disabled },
                              on: { close: _setup.closeAuthorizationBrand },
                            }),
                            item.authorizeAuthDto
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text", size: "small" },
                                    on: {
                                      click: function ($event) {
                                        return _setup.showProw(item)
                                      },
                                    },
                                  },
                                  [_vm._v("查看证明")]
                                )
                              : _vm._e(),
                          ],
                          1
                        )
                      }),
                    ],
                    2
                  ),
                  !_vm.disabled
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "primary", size: "small" },
                          on: {
                            click: function ($event) {
                              return _setup.setBrand(2)
                            },
                          },
                        },
                        [_vm._v(" 关联品牌 ")]
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(_setup.SelectDialog, {
        attrs: {
          list: _setup.selectList,
          show: _setup.showSelect,
          "brand-show-type": _setup.brandShowType,
          "backend-category-id": _vm.backendCategoryId,
          "suppliers-id": _vm.suppliersId,
          max: _setup.maxNum,
        },
        on: { clear: _setup.onClear, submit: _setup.submit },
      }),
      _c(_setup.DetailDialog, {
        attrs: { id: _setup.detailId, disabled: "", show: _setup.showDetail },
        on: {
          clear: function ($event) {
            _setup.showDetail = false
          },
        },
      }),
      _c(_setup.ProveDialog, {
        attrs: {
          disabled: "",
          prove: _setup.proveData,
          show: _setup.showProve,
        },
        on: {
          clear: function ($event) {
            _setup.showProve = false
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }