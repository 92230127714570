<!-- 商品单独属性组件，如蜂箱认养IMEI组件 -->
<template>
  <div class="" style="margin-bottom: 70px">
    <h1 class="title">
      {{title}}
    </h1>
    <el-form
      ref="ruleForm"
      :model="ruleForm"
      label-width="120px"
      class="demo-ruleForm"
    >
      <el-form-item
        :label="'请输入' + title"
        prop="input"
        :rules="[
          { required, message: '请输入' + title, trigger: 'blur' },
        ]"
      >
        <el-input 
          style="width: 360px"
          v-model.trim="ruleForm.input" 
          :placeholder="'请输入内容' + title"
          size="mini"
          :readonly="disabled"
        ></el-input>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  name: '',
  components: {},
  props: ['title','id','type','required','disabled','tradeGoodsDto'],
  data () {
    return {
      ruleForm: {
        input: ''
      },
    }
  },
  computed: {},
  watch: {
    tradeGoodsDto: {
      handler(val) {
        if(val && val[this.id]) {
          this.ruleForm.input = val[this.id]
        }
      },
      deep: true
    }
  },
  created () {},
  mounted () {
    
  },
  methods: {
    // 完整验证
    getForm() {
      return new Promise((resolve) => {
        this.$refs.ruleForm.validate((valid) => {
          if (valid) {
            resolve(this.ruleForm.input)
          }else {
            this.$message({
              type: 'error',
              message: '请输入' + this.title
            })
          }
        })
      })
    }
  }
}
</script>

<style scoped lang="scss">

</style>