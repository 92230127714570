<template>
  <el-dialog
    title="批量设置"
    :visible.sync="dialogVisible"
    :before-close="clearModel"
    width="500px"
    @open="openDialog"
  >
    <div>
      <el-row
        v-for="(item,i) in fromList"
        :key="i"
        :gutter="20"
        style="margin-bottom: 10px;"
      >
        <el-col :span="7">
          <div
            style="line-height: 24px;
          text-align: right;"
          >
            {{ item.title }}：
          </div>
        </el-col>
        <el-col :span="17">
          <el-input
            v-model="item.value"
            type="number"
            size="mini"
            :placeholder="'请输入' + item.title"
          />
        </el-col>
      </el-row>
      <div style="margin-top: 40px;">
        注：不需要批量设置的参数，可不填。
      </div>
    </div>
    <span
      slot="footer"
      class="dialog-footer"
    >
      <el-button
        size="small"
        @click="clearModel"
      >取 消</el-button>
      <el-button
        type="primary"
        size="small"
        @click="onSubmit"
      >保 存</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  name: '',
  components: {},
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    skuTable: {
      type: Array,
      default: () => [],
    },
    bathSetList: {
      type: Array,
      default: () => [],
    },
    isShowMarketPrice: {
      type: Boolean,
      default: true,
    },
    isShowSellPrice: {
      type: Boolean,
      default: true,
    },
    isShowMinBuy: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      marketPrice: null,
      fromList: [
        {
          title: '市场价（元）',
          key: 'marketPrice',
          value: null,
          isShow: this.isShowMarketPrice,
        },
        {
          title: '销售价（元）',
          key: 'sellPrice',
          value: null,
          isShow: this.isShowSellPrice,
        },
        {
          title: '起购量（件）',
          key: 'minBuy',
          value: null,
          isShow: this.isShowMinBuy,
        },
        {
          title: '库存（件）',
          key: 'stockQuantity',
          value: null,
        },
        {
          title: '预警库存（件）',
          key: 'stockQuantityWarning',
          value: null,
        },
      ],
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {
    this.fromList = this.fromList.filter((item) => item.isShow !== false);
  },
  methods: {
    clearModel() {
      this.$emit('update:dialogVisible', false);
    },
    onSubmit() {
      for (let item of this.skuTable) {
        if (this.bathSetList.find((find) => find.specValue == item.specValue)) {
          for (let value of this.fromList) {
            if (!value.value) {
              continue;
            } else {
              this.$set(item, value.key, value.value);
            }
          }
        }
      }
      this.clearModel();
    },
    openDialog() {

    },
  },
};
</script>

<style scoped lang="scss">

</style>
