// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/.pnpm/css-loader@3.6.0_webpack@4.47.0/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/* Variables */\n\n/* Mixins */\n.title {\n  margin-bottom: 20px;\n  font-weight: bold;\n}\n\n.component {\n  margin-bottom: 70px;\n}\n\n.width140 {\n  width: 140px;\n}\n\n.width180 {\n  width: 180px;\n}\n\n.width360 {\n  width: 360px;\n}\n\n.width450 {\n  width: 450px;\n}\n\n.margin_left_10 {\n  margin-left: 10px;\n}\n\n.margin_right_10 {\n  margin-right: 10px;\n}\n\n.margin_right_30 {\n  margin-right: 30px;\n}\n\n.margin_bottom_10 {\n  margin-bottom: 10px !important;\n}\n\n.mb30 {\n  margin-bottom: 30px;\n}\n\n.mb40 {\n  margin-bottom: 40px;\n}\n\n.mb50 {\n  margin-bottom: 50px;\n}\n\n.margin_top_10 {\n  margin-top: 10px;\n}\n\n.back-btn-parent {\n  position: relative;\n  margin-bottom: 20px;\n}\n.back-btn-parent .back-btn {\n  position: absolute;\n  top: -50px;\n  right: 30px;\n}", ""]);
// Exports
exports.locals = {
	"theme": "#30B08F",
	"menuBg": "#304156",
	"menuText": "#bfcbd9",
	"menuActiveText": "#409EFF"
};
module.exports = exports;
