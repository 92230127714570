// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/.pnpm/css-loader@3.6.0_webpack@4.47.0/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/* Variables */\n\n/* Mixins */\n.component-bookRule .mRight10, .component-bookRule .days {\n  margin-right: 10px;\n}\n.component-bookRule .unDate-box {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  margin-top: 10px;\n}\n.component-bookRule .unDate {\n  width: 496px;\n}\n.component-bookRule .days {\n  width: 100px;\n}\n.component-bookRule .el-radio__input {\n  margin-bottom: 2px;\n}", ""]);
// Exports
exports.locals = {
	"theme": "#30B08F",
	"menuBg": "#304156",
	"menuText": "#bfcbd9",
	"menuActiveText": "#409EFF"
};
module.exports = exports;
