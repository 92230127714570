<template>
  <div class="" style="margin-bottom: 70px">
    <h1 class="title">
      发货次数
    </h1>
    <el-form
      ref="ruleForm"
      :model="ruleForm"
      :rules="rules"
      label-width="120px"
      class="demo-ruleForm"
    >
      <el-form-item
        label="固定发货次数："
        prop="shipments"
      >
        <el-input-number
          v-model="ruleForm.shipments"
          style="width: 150px"
          size="mini"
          :min="1" :max="999"
          :readonly="disabled"
          @blur="changeNum"
          @change="onChange"
        ></el-input-number>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  name: '',
  components: {},
  props: ['disabled','shipments'],
  data () {
    return {
      ruleForm: {
        shipments: null
      },
      rules: {
        shipments: [
          { required: true, message: '请输入发货次数', trigger: 'change' }
        ],
      }
    }
  },
  computed: {},
  watch: {
    shipments: {
      handler(val) {
        this.ruleForm.shipments = val;
      },
    },
  },
  created () {},
  mounted () {
    
  },
  methods: {
    changeNum() {
      this.ruleForm.shipments = this.ruleForm.shipments > 0 ? this.ruleForm.shipments : 1
      this.$emit('update:shipments', this.ruleForm.shipments)
      // 
      // this.$emit('update:tradeGoodsDto', {...this.tradeGoodsDto, shipments: this.ruleForm.shipments })
    },
    onChange() {
      this.$emit('update:shipments', this.ruleForm.shipments)
      // console.log('onChangeonChangeonChangeonChange')
      // this.$emit('update:tradeGoodsDto', {...this.tradeGoodsDto, shipments: this.ruleForm.shipments })
    }
  }
}
</script>

<style scoped lang="scss">
.title {
  margin-bottom: 20px;
  font-weight: bold;
}
</style>