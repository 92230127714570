<template>
  <div class="img-drag">
    <draggable
      v-model="list"
      class="drag-box"
      element="div"
      v-bind="dragOptions"
      :move="onMove"
      @start="isDragging=true"
      @end="isDragging=false"
    >
      <div
        v-for="(item,index) in list"
        :key="index"
        class="list-group"
      >
        <img
          :src="item.url"
          alt=""
        >
        <p v-show="item.title">
          {{ item.title }}
        </p>
        <i
          class="iconfont el-icon-close"
          @click="remove(item,index)"
        />
      </div>
    </draggable>
  </div>
</template>
<script>
import draggable from 'vuedraggable'

export default {
  name: 'ImgDrag',
  components: { draggable },
  props: {
    list: {
      type: Array,
      // 对象或数组默认值必须从一个工厂函数获取
      default: function() {
        return []
      }
    }
  },
  data() {
    return {
      dragOptions: {
        group: 'name'
      },
      imgNameShow: false
    }
  },
  watch: {
    list(val) {
      this.$emit('move', val)
    }
  },
  methods: {
    onMove({ relatedContext, draggedContext }) {
      const relatedElement = relatedContext.element
      const draggedElement = draggedContext.element
      return (
        (!relatedElement || !relatedElement.fixed) && !draggedElement.fixed
      )
    },
    remove(item, index) {
      this.$emit('remove', { item: item, index: index })
    }
  }
}
</script>
<style lang="scss" scoped>

.drag-box{
    display: flex;
    flex-wrap: wrap;
}
.list-group{
    width: 100px;
    height: 100px;
    position: relative;
    border: 1px solid #c0ccda;
    margin: 0 3px;
    border-radius: 6px;
    img{
        width: 100%;
        height: 100%;
        cursor: move;
        border-radius: 6px;

    }
    i{
        position: absolute;
        right: 3px;
        top: 3px;
        background: #fff;
        cursor: pointer;
    }
    p{
        width: 100%;
        height: 30px;
        line-height: 30px;
        color: #fff;
        position: absolute;
        bottom: 0;
        background: rgba(0,0,0,0.3);
        text-align: center;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-weight:600;
        font-size:18px;
        margin: 0;
    }
}
</style>
