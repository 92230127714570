var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "选择品牌",
        visible: _vm.show,
        width: "800px",
        "before-close": _setup.clearDialog,
      },
      on: {
        "update:visible": function ($event) {
          _vm.show = $event
        },
        open: _setup.open,
      },
    },
    [
      _c(
        "div",
        [
          _c(
            "div",
            { staticClass: "margin-b10" },
            [
              _vm._v(" 已选择："),
              _vm._l(_setup.selectList, function (item) {
                return _c(_setup.BrandTag, {
                  key: item.id,
                  staticClass: "margin-r10 margin-b5",
                  attrs: { item: item },
                  on: { close: _setup.closeTag },
                })
              }),
            ],
            2
          ),
          _c(
            "div",
            { staticClass: "margin-b10" },
            [
              _c("el-input", {
                staticClass: "w300 margin-r10",
                attrs: {
                  size: "small",
                  placeholder: "请输入品牌名称",
                  clearable: "",
                },
                model: {
                  value: _setup.searchName,
                  callback: function ($$v) {
                    _setup.searchName = $$v
                  },
                  expression: "searchName",
                },
              }),
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: { click: _setup.onSearch },
                },
                [_vm._v(" 搜索 ")]
              ),
              _vm.brandShowType === 1
                ? _c(
                    "el-button",
                    {
                      attrs: { size: "small", type: "primary" },
                      on: { click: _setup.editBrand },
                    },
                    [_vm._v(" 新增品牌 ")]
                  )
                : _vm._e(),
            ],
            1
          ),
          _setup.brandDomain.length === 1
            ? _c(
                "el-tabs",
                {
                  attrs: { type: "card" },
                  model: {
                    value: _setup.activeTab,
                    callback: function ($$v) {
                      _setup.activeTab = $$v
                    },
                    expression: "activeTab",
                  },
                },
                [
                  _vm.brandShowType === 1
                    ? [
                        _c("el-tab-pane", {
                          attrs: { label: "平台提供", name: "1" },
                        }),
                        _c("el-tab-pane", {
                          attrs: { label: "自定义品牌", name: "2" },
                        }),
                      ]
                    : _vm._e(),
                  _vm.brandShowType === 2
                    ? [
                        _c("el-tab-pane", {
                          attrs: { label: "区域公用品牌", name: "3" },
                        }),
                        _c("el-tab-pane", {
                          attrs: { label: "其他品牌", name: "4" },
                        }),
                      ]
                    : _vm._e(),
                ],
                2
              )
            : _vm._e(),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _setup.loading,
                  expression: "loading",
                },
              ],
              ref: "tableRef",
              attrs: {
                data: _setup.tableData,
                "show-header": false,
                "row-key": "id",
              },
            },
            [
              _c("el-table-column", {
                attrs: { width: "55" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c("el-checkbox", {
                          attrs: { disabled: _setup.disabledCheck(row) },
                          on: {
                            change: function ($event) {
                              return _setup.changeCHecked(row)
                            },
                          },
                          model: {
                            value: row.checked,
                            callback: function ($$v) {
                              _vm.$set(row, "checked", $$v)
                            },
                            expression: "row.checked",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c(
                          "div",
                          { staticClass: "brand-content" },
                          [
                            row.brandLogo
                              ? _c("el-image", {
                                  staticClass: "image",
                                  attrs: {
                                    src: row.brandLogo,
                                    "preview-src-list": [row.brandLogo],
                                    fit: "contain",
                                  },
                                })
                              : _vm._e(),
                            !row.brandLogo
                              ? _c("img", {
                                  staticClass: "image",
                                  attrs: {
                                    src: "https://wsnbh.oss-cn-hangzhou.aliyuncs.com/default/1660282544838.png",
                                    alt: "",
                                  },
                                })
                              : _vm._e(),
                            _c("div", { staticClass: "margin-r20" }, [
                              _vm._v(" " + _vm._s(row.brandName) + " "),
                            ]),
                            _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: {
                                  effect: "dark",
                                  content: "该品牌已被系统禁用",
                                  placement: "top",
                                },
                              },
                              [
                                row.brandState == 1
                                  ? _c(
                                      "span",
                                      { staticClass: "disabled margin-r20" },
                                      [_vm._v("已禁用")]
                                    )
                                  : _vm._e(),
                              ]
                            ),
                            _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: {
                                  effect: "dark",
                                  content: "请先设置品牌logo和名称",
                                  placement: "top",
                                },
                              },
                              [
                                !row.brandLogo
                                  ? _c(
                                      "span",
                                      { staticClass: "disabled margin-r20" },
                                      [_vm._v("未设置")]
                                    )
                                  : _vm._e(),
                              ]
                            ),
                            _setup.activeTab === "2"
                              ? [
                                  _c(
                                    "el-button",
                                    {
                                      staticClass: "margin-r20",
                                      attrs: { type: "text" },
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          return _setup.editBrand(row)
                                        },
                                      },
                                    },
                                    [
                                      _setup.brandType == "1" &&
                                      _setup.people === "gys"
                                        ? _c("i", {
                                            staticClass: "el-icon-edit",
                                          })
                                        : _vm._e(),
                                    ]
                                  ),
                                  _c(
                                    "el-button",
                                    {
                                      staticClass: "margin-r20",
                                      attrs: { type: "text" },
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          return _setup.deteleBrand(row)
                                        },
                                      },
                                    },
                                    [
                                      _setup.brandType == "1" &&
                                      _setup.people === "gys"
                                        ? _c("i", {
                                            staticClass: "el-icon-delete",
                                          })
                                        : _vm._e(),
                                    ]
                                  ),
                                ]
                              : _vm._e(),
                            (_setup.activeTab === "3" ||
                              _setup.activeTab === "4") &&
                            row.isMustAuth === 1
                              ? [
                                  _c(
                                    "el-tooltip",
                                    {
                                      staticClass: "item",
                                      attrs: {
                                        effect: "dark",
                                        content: "请先上传品牌证明",
                                        placement: "top",
                                      },
                                    },
                                    [
                                      !row.authorizeAuthDto
                                        ? _c(
                                            "el-button",
                                            {
                                              staticClass: "margin-r20",
                                              attrs: { type: "text" },
                                              on: {
                                                click: function ($event) {
                                                  $event.stopPropagation()
                                                  return _setup.addProve(row)
                                                },
                                              },
                                            },
                                            [_vm._v(" 上传证明 ")]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                  row.authorizeAuthDto
                                    ? _c(
                                        "el-button",
                                        {
                                          staticClass: "margin-r20",
                                          attrs: { type: "text" },
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                              return _setup.addProve(row)
                                            },
                                          },
                                        },
                                        [_vm._v(" 查看证明 ")]
                                      )
                                    : _vm._e(),
                                ]
                              : _vm._e(),
                          ],
                          2
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c("el-pagination", {
            attrs: {
              small: "",
              layout: "prev, pager, next",
              total: _setup.total,
            },
            on: { "current-change": _setup.currentChange },
          }),
        ],
        1
      ),
      _c(
        "span",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            { attrs: { size: "small" }, on: { click: _setup.clearDialog } },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "small" },
              on: { click: _setup.onSubmit },
            },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
      _c(_setup.DetailDialog, {
        attrs: {
          id: _setup.detailId,
          "suppliers-id": _vm.suppliersId,
          show: _setup.showDetail,
        },
        on: {
          clear: function ($event) {
            _setup.showDetail = false
          },
          success: _setup.getList,
        },
      }),
      _c(_setup.ProveDialog, {
        attrs: { prove: _setup.proveData, show: _setup.showProve },
        on: {
          clear: function ($event) {
            _setup.showProve = false
          },
          submit: _setup.submitProve,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }