<template>
  <div class="brand_model margin-b50">
    <h1 class="title">
      商品品牌
    </h1>
    <el-form label-width="120px">
      <el-form-item label="自主品牌(单选)：">
        <div>
          <span v-if="!selfBrand.length">/</span>
          <span
            v-for="(item,i) in selfBrand"
            :key="i"
            class="margin-r10"
          >
            <BrandTag
              :item="item"
              :disabled="disabled"
              @close="closeSelfBrand"
            />
            <el-button
              v-if="item.isPublic === 1"
              type="text"
              size="small"
              @click="onShowDetail(item)"
            >查看证明</el-button>
          </span>
        </div>
        <el-button
          v-if="!disabled"
          type="primary"
          size="small"
          @click="setBrand(1)"
        >
          关联品牌
        </el-button>
      </el-form-item>
      <el-form-item
        v-if="showAuthorization"
        label="授权品牌(多选)："
      >
        <div>
          <span v-if="!authorizationList.length">/</span>
          <span
            v-for="(item,i) in authorizationList"
            :key="i"
            class="margin-r10"
          >
            <BrandTag
              :item="item"
              :disabled="disabled"
              @close="closeAuthorizationBrand"
            />
            <el-button
              v-if="item.authorizeAuthDto"
              type="text"
              size="small"
              @click="showProw(item)"
            >查看证明</el-button>
          </span>
        </div>
        <el-button
          v-if="!disabled"
          type="primary"
          size="small"
          @click="setBrand(2)"
        >
          关联品牌
        </el-button>
      </el-form-item>
    </el-form>

    <SelectDialog
      :list="selectList"
      :show="showSelect"
      :brand-show-type="brandShowType"
      :backend-category-id="backendCategoryId"
      :suppliers-id="suppliersId"
      :max="maxNum"
      @clear="onClear"
      @submit="submit"
    />

    <!-- 详情弹窗 -->
    <DetailDialog
      :id="detailId"
      disabled
      :show="showDetail"
      @clear="showDetail = false"
    />

    <!-- 证明弹窗 -->
    <ProveDialog
      disabled
      :prove="proveData"
      :show="showProve"
      @clear="showProve = false"
    />
  </div>
</template>

<script setup>
import { ref, getCurrentInstance, watchEffect } from 'vue';
import BrandTag from './components/brand/BrandTag.vue';
import SelectDialog from './components/brand/SelectDialog.vue';
import DetailDialog from './components/brand/DetailDialog.vue';
import ProveDialog from './components/brand/ProveDialog.vue';

const { proxy } = getCurrentInstance();

const props = defineProps({
  goodsDetailBrandInfo: {
    type: Object,
    default: () => {},
  },
  // 展示授权品牌
  showAuthorization: {
    type: Boolean,
    default: false,
  },
  backendCategoryId: {
    type: String,
    default: '',
  },
  suppliersId: {
    type: String,
    default: '',
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(['change']);

const selfBrand = ref([]);
const authorizationList = ref([]); // 授权列表

const showSelect = ref(false);
const brandShowType = ref(null);
const maxNum = ref(1);
const selectList = ref([]);

// 查看详情
const showDetail = ref(false);
const detailId = ref();
// 查看证明
const proveData = ref({});
const showProve = ref(false);

const onShowDetail = ({ id }) => {
  showDetail.value = true;
  detailId.value = id;
};
const onClear = () => {
  showSelect.value = false;
  brandShowType.value = null;
};
const emitData = () => {
  // 处理并输出数据
  const obj = {
    goodsDefaultBrand: null,
    goodsAuthorizeBrands: null,
  };
  if (selfBrand.value.length) {
    [obj.goodsDefaultBrand] = selfBrand.value;
  }
  if (authorizationList.value.length) {
    obj.goodsAuthorizeBrands = authorizationList.value;
  }
  console.log('🚀 ~ file: brandInfo.vue:115 ~ submit ~ obj:', obj);
  emit('change', obj);
};
const submit = (list) => {
  if (brandShowType.value === 1) {
    selfBrand.value = list;
  } else {
    authorizationList.value = list;
  }
  emitData();
};
const setBrand = (type) => {
  showSelect.value = true;
  brandShowType.value = type;
  maxNum.value = type === 1 ? 1 : 5;
  selectList.value = type === 1 ? selfBrand.value : authorizationList.value;
};
const showProw = (row) => {
  showProve.value = true;
  proveData.value = {
    ...row.authorizeAuthDto,
  };
  proveData.value.brandLogo = row.brandLogo;
  proveData.value.brandName = row.brandName;
};
const closeSelfBrand = async (item) => {
  await proxy.$confirm('是否确认清除？');
  const index = selfBrand.value.findIndex((fin) => fin.id === item.id);
  selfBrand.value.splice(index, 1);
  brandShowType.value = null;
  // 设置输入数据
  emitData();
};
const closeAuthorizationBrand = async (item) => {
  await proxy.$confirm('是否确认清除？');
  const index = authorizationList.value.findIndex((fin) => fin.id === item.id);
  authorizationList.value.splice(index, 1);
  brandShowType.value = null;
  // 设置输入数据
  emitData();
};

watchEffect(() => {
  console.log('监听goodsDetailBrandInfo变化==========================');
  if (props.goodsDetailBrandInfo) {
    if (props.goodsDetailBrandInfo.goodsDefaultBrand) {
      // 自选
      selfBrand.value = [props.goodsDetailBrandInfo.goodsDefaultBrand];
    }
    if (props.goodsDetailBrandInfo.goodsAuthorizeBrands) {
      authorizationList.value = props.goodsDetailBrandInfo.goodsAuthorizeBrands;
    }
  }
});
</script>

<style scoped lang="scss">
.title {
  margin-bottom: 20px;
  font-weight: bold;
}
</style>
