var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "el-tag",
    {
      attrs: {
        closable: !_vm.disabled,
        effect: "plain",
        "disable-transitions": "",
      },
      on: { close: _setup.close },
    },
    [
      _c("el-image", {
        staticClass: "tag-image",
        attrs: {
          src: _vm.item.brandLogo,
          "preview-src-list": [_vm.item.brandLogo],
        },
      }),
      _c("span", [_vm._v(_vm._s(_vm.item.brandName))]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }