<template>
  <div class="supplier_info mb50">
    <h1 class="title">
      关联供应商
    </h1>
    <el-card>
      <el-form
        ref="ruleForm"
        :model="ruleForm"
        :rules="rules"
        label-width="110px"
        class="demo-ruleForm"
      >
        <el-form-item
          v-if="people === 'gys'"
          label="供应商货品："
          prop="radio"
        >
          <el-radio-group
            v-model="ruleForm.radio"
            :disabled="disabled || supply.boolean"
            @change="ruleForm.name = ''"
          >
            <el-radio :label="0">
              否
            </el-radio>
            <el-radio :label="1">
              是
            </el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          label="所属供应商："
          prop="name"
          :rules="[
            { required: ruleForm.radio === 1, message: '请输入供应商名称', trigger: 'change' }
          ]"
        >
          <el-input
            v-model.trim="ruleForm.name"
            class="width360"
            size="mini"
            maxlength="30"
            show-word-limit
            placeholder="请输入所属供应商"
            :readonly="disabled || people === 'admin' || supply.boolean"
          />
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>

<script>
export default {
  name: '',
  components: {},
  props: {
    people: {
      type: String,
      default: 'admin',
    },
    tradeGoodsDto: {
      type: Object,
      default: () => {},
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    supply: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      ruleForm: {
        radio: 0,
        name: '',
      },
      rules: {
        radio: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
      },
    };
  },
  computed: {},
  watch: {
    tradeGoodsDto: {
      handler(val) {
        if (val && val.belongSupplierName) {
          this.ruleForm = {
            radio: 1,
            name: val.belongSupplierName,
          };
        }
      },
      immediate: true,
    },
    'ruleForm.name': {
      handler(val) {
        this.supply.name = val;
      },
      immediate: true,
    },
  },
  created() {},
  mounted() {

  },
  methods: {
    getForm() {
      // 完整验证
      return new Promise((resolve) => {
        this.$refs.ruleForm.validate((valid) => {
          if (valid) {
            resolve(this.ruleForm.radio === 1 ? this.ruleForm.name : '');
          } else {
            this.$message.error('请输入所属供应商');
          }
        });
      });
    },
  },
};
</script>

<style scoped lang="scss">
.supplier_info {
  width: 600px;
}
</style>
