<template>
  <el-form
    ref="ruleFormRef"
    :model="ruleForm"
    label-width="100px"
  >
    <el-form-item
      class="margin-b20"
      label="入选公用品牌"
    >
      <div class="flex wd-flex-align-center">
        <img
          v-if="brandImg"
          class="brand-img"
          :src="brandImg"
          alt=""
        />
        <el-select
          v-model="ruleForm.brandId"
          placeholder="请选公用品牌"
          size="small"
          clearable
          class="w300"
          :disabled="disabled"
        >
          <el-option
            v-for="(item,i) in brandList"
            :key="i"
            :label="item.brandName"
            :value="item.id"
          >
            <div class="flex">
              <img
                class="brand-img"
                :src="item.brandLogo"
                alt=""
              />
              <span>{{ item.brandName }}</span>
            </div>
          </el-option>
        </el-select>
      </div>
    </el-form-item>
    <template v-if="ruleForm.brandId">
      <el-form-item
        class="margin-b20"
        label="证明图片"
        prop="imageList"
        :rules="[{
          type: 'array', required: true, message: '请上传图片类型', trigger: 'change',
        }]"
      >
        <UpImage
          :disabled="disabled"
          :image-list.sync="ruleForm.imageList"
          :num="5"
        />
        <div>
          建议：800px*800px，2M以内；图片可拖动排序；限5张。
        </div>
      </el-form-item>
      <el-form-item
        class="margin-b20"
        label="有效期"
        prop="timeList"
        :rules="[{
          type: 'array', required: true, message: '请选择有效时间', trigger: 'change',
        }]"
      >
        <el-date-picker
          v-model="ruleForm.timeList"
          :disabled="disabled"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="timestamp"
          size="small"
          :default-time="['00:00:00', '23:59:59']"
        />
      </el-form-item>
    </template>
  </el-form>
</template>

<script setup>
import {
  ref, getCurrentInstance, watch, computed,
} from 'vue';
import UpImage from '@/components/common/upImage/index.vue';

const { proxy } = getCurrentInstance();

const props = defineProps({
  form: {
    type: Object,
    default: () => {},
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits('change');

const ruleFormRef = ref(null);
const ruleForm = ref({
  brandId: '',
  imageList: [],
  timeList: [],
});
const brandList = ref([]);

const brandImg = computed(() => {
  if (!brandList.value.length || !ruleForm.value.brandId) return null;
  const find = brandList.value.find((f) => f.id === Number(ruleForm.value.brandId));
  return find?.brandLogo;
});

const getBrandList = async () => {
  const { code, data } = await proxy.$axios.post(proxy.$api.brand.list, {
    currentPage: 1,
    pageSize: 50,
    brandDomains: [2],
    brandType: '0',
    isShow: 0,
    brandState: 0,
  });
  if (code !== 0) return;
  brandList.value = data.records;
};

getBrandList();

watch(() => props.form, (e) => {
  if (!e) return;
  ruleForm.value = JSON.parse(JSON.stringify(e));
}, {
  deep: true,
  immediate: true,
});
watch(ruleForm, (e) => {
  emit('change', JSON.parse(JSON.stringify(e)));
}, {
  deep: true,
});
</script>

<style scoped lang="scss">
.brand-img{
  width: 30px;
  height: 30px;
  object-fit: contain;
  margin-right: 5px;
}
</style>
