<template>
  <el-dialog
    title="品牌详情"
    :visible.sync="show"
    width="600px"
    :before-close="clearDialog"
    append-to-body
    @open="open"
  >
    <el-form
      ref="ruleFormRef"
      :model="ruleForm"
      label-width="100px"
    >
      <el-form-item
        label="品牌Logo"
        prop="imageList"
        :rules="[{ type: 'array',required: true, message: '请上传品牌logo', trigger: 'change' },]"
      >
        <UpImage
          :image-list.sync="ruleForm.imageList"
          :num="1"
          :disabled="disabled"
        />
        <div>
          （Logo图建议：200px*200px 1M以内 JPG/PNG）
        </div>
      </el-form-item>
      <el-form-item
        label="品牌名称"
        prop="brandName"
        :rules="[{ required: true, message: '请输入品牌名称', trigger: 'blur' },]"
      >
        <el-input
          v-model="ruleForm.brandName"
          :disabled="disabled"
          class="w400"
          maxlength="15"
          show-word-limit
          placeholder="请输入15字以内品牌名称"
          size="small"
        />
      </el-form-item>
      <CommonForm
        ref="CommonFormRef"
        :form="commonBrand"
        :disabled="disabled"
        @change="changeCommon"
      />
    </el-form>
    <span
      slot="footer"
    >
      <el-button
        size="small"
        @click="clearDialog"
      >取 消</el-button>
      <el-button
        v-if="!disabled"
        type="primary"
        size="small"
        @click="onSubmit"
      >确 定</el-button>
    </span>
  </el-dialog>
</template>

<script setup>
import {
  ref, getCurrentInstance,
} from 'vue';
import UpImage from '@/components/common/upImage/index';
import CommonForm from './CommonForm.vue';

const { proxy } = getCurrentInstance();

const props = defineProps({
  show: {
    type: Boolean,
    default: false,
  },
  id: {
    type: Number,
    default: null,
  },
  suppliersId: {
    type: String,
    default: '',
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(['clear', 'success']);

const CommonFormRef = ref(null);
const ruleFormRef = ref(null);
const ruleForm = ref({
  imageList: [],
  brandName: '',
  suppliersId: props.suppliersId,
  brandType: 1,
  brandDomain: 1,
});
// 输入数据
const commonBrand = ref({
  brandId: '',
  imageList: [],
  timeList: [],
});
// 输出数据
const commonBrandParams = ref({});

const clearDialog = () => {
  ruleForm.value = {
    imageList: [],
    brandName: '',
    suppliersId: props.suppliersId,
    brandType: 1,
    brandDomain: 1,
  };
  commonBrand.value = {
    brandId: '',
    imageList: [],
    timeList: [],
  };
  emit('clear');
};
const addOrEdit = async () => {
  const api = props.id ? proxy.$api.brand.edit : proxy.$api.brand.add;
  const params = {
    ...ruleForm.value,
    brandLogo: ruleForm.value.imageList.join(),
  };
  if (commonBrandParams.value.brandId) {
    params.isPublic = 1;
    params.tradeBrandAuthDto = {
      ...(ruleForm.value.tradeBrandAuthDto || {}),
      authBeginTime: commonBrandParams.value.timeList[0],
      authEndTime: commonBrandParams.value.timeList[1],
      authPic: commonBrandParams.value.imageList,
      publicBrandId: commonBrandParams.value.brandId,
      authType: 1,
    };
  } else {
    params.isPublic = 0;
    params.tradeBrandAuthDto = null;
  }
  const { code } = await proxy.$axios.post(api, params);
  if (code !== 0) return;
  proxy.$message.success('操作成功！');
  clearDialog();
  emit('success');
};
const onSubmit = async () => {
  const commonFormPromise = new Promise((resolve) => {
    if (!CommonFormRef.value) resolve();
    CommonFormRef.value.$refs.ruleFormRef.validate((valid) => {
      if (valid) resolve();
    });
  });
  const formPromise = new Promise((resolve) => {
    ruleFormRef.value.validate((valid) => {
      if (valid) resolve();
    });
  });

  await Promise.all([commonFormPromise, formPromise]);
  await proxy.$confirm('是否确认保存？');
  addOrEdit();
};
const changeCommon = (row) => {
  commonBrandParams.value = row;
};
const open = async () => {
  if (!props.id) return;
  const { code, data } = await proxy.$axios.get(proxy.$api.brand.detail, { params: { id: props.id } });
  if (code !== 0) return;
  ruleForm.value = {
    ...data,
    imageList: data.brandLogo ? [data.brandLogo] : [],
  };
  // 回填入选公共品牌
  if (data.isPublic === 1 && data.tradeBrandAuthDto) {
    const {
      publicBrandId, authPic, authBeginTime, authEndTime,
    } = data.tradeBrandAuthDto;
    commonBrand.value = {
      brandId: Number(publicBrandId),
      imageList: authPic,
      timeList: [authBeginTime, authEndTime],
    };
  } else {
    commonBrand.value = {
      brandId: '',
      imageList: [],
      timeList: [],
    };
  }
};
</script>

<style scoped lang="scss">
</style>
