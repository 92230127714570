<template>
  <div>
    <h1 class="title">
      商品基本信息
    </h1>
    <el-form
      ref="ruleForm"
      :model="ruleForm"
      :rules="rules"
      label-width="110px"
      class="demo-ruleForm"
    >
      <el-form-item label="商品名称：" :prop="(['1', '2', '5', '6'].includes(goodsType) || goodsBuyType) ? 'threeInput' : 'name'">
        <div v-if="['1', '2', '5', '6'].includes(goodsType) || goodsBuyType" class="base-three-input">
          <el-cascader
            ref="cascaderRef"
            class="base-select-area"
            v-model="regionCode"
            size="mini"
            clearable
            placeholder="请选择所属地"
            :disabled="disabled"
            :props="cascaderProps"
            :show-all-levels="false"
            @change="changeCascader"
          />
          <el-input
            v-model="ruleForm.productName"
            class="width450 product-name"
            size="mini"
            maxlength="10"
            show-word-limit
            placeholder="请输入产品名称"
            :readonly="disabled"
          />
          <el-input
            v-model="ruleForm.extraDesc"
            class="width450"
            size="mini"
            maxlength="10"
            show-word-limit
            placeholder="请输入补充说明"
            :readonly="disabled"
          />
          <div>
            商品名称预览：{{regionCodeStr}}{{ruleForm.productName}}{{ruleForm.extraDesc}}
          </div>
        </div>
        <el-input
          v-else
          v-model="ruleForm.name"
          class="width450"
          size="mini"
          maxlength="30"
          show-word-limit
          placeholder="请输入商品名称"
          :readonly="disabled"
        />
        <div style="color: #666;line-height: 26px">{{nameSuggestion}}</div>
      </el-form-item>
      <el-form-item
        label="商品卖点："
        prop="trait"
      >
        <el-input
          v-model="ruleForm.trait"
          class="width450"
          type="textarea"
          size="mini"
          maxlength="30"
          show-word-limit
          placeholder="请输入商品卖点"
          :readonly="disabled"
        />
        <div
          v-if="goodsType!='2'"
          style="color: #666;line-height: 26px"
        >
          例：纯天然无污染，爽脆可口
        </div>
        <div
          v-if="goodsType=='2'"
          style="color: #666;line-height: 26px"
        >
          例：超大落地窗 江景 巨屏投影
        </div>
      </el-form-item>

      <el-form-item
        required
        label="商品封面图："
      >
        <UpImage
          :disabled="disabled"
          :image-list.sync="ruleForm.coverImageList"
          :num="1"
        />
        <div>营销位显示图片，建议突出商品卖点。建议：800px*800px，2M以内。</div>
      </el-form-item>
      <el-form-item
        required
        label="商品图片："
      >
        <UpImage
          :disabled="disabled"
          :image-list.sync="ruleForm.imageUrlList"
          :num="10"
        />
        <div>
          <div v-if="+goodsType === 2">
            建议：720px*480px，2M以内；图片可拖动排序，限10张。
          </div>
          <div v-else>
            建议：800px*800px，2M以内；图片可拖动排序，限10张。
          </div>
        </div>
      </el-form-item>
      <el-form-item label="视频上传：">
        <UploadVideoOss
          :disabled="disabled"
          :url="ruleForm.videoUrl"
          @success="changeVideo"
        />
        <div>
          <div v-if="+goodsType === 2">
            建议：清晰度720p以上，画面比例3:2，3分钟以内，300M以内。
          </div>
          <div v-else>
            建议：清晰度720p以上，画面比例1:1，3分钟以内，300M以内。
          </div>
        </div>
      </el-form-item>

      <el-form-item
        v-if="isNeedAppendShop()"
        required
        label="关联门店："
      >
        <ChangeHomeShop
          :disabled="disabled"
          :shop-list-dto="shopList"
          @changeHomeShop="changeHomeShop"
        />
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import CutImgUp from '@/components/common/cutImgUp';
import UploadVideoOss from '@/components/common/uploadVideoOss/index.vue';
import MapModel from '@/components/common/mapModel.vue';
import UpImage from '@/components/common/upImage/index';
import LoadImgOssUp from '@/components/common/loadImgOssUp';
import ChangeHomeShop from './components/changeHomeShop';

export default {
  components: {
    CutImgUp,
    MapModel,
    UploadVideoOss,
    UpImage,
    LoadImgOssUp,
    ChangeHomeShop,
  },
  props: ['disabled', 'tradeGoodsDto', 'tradeShopGoodsRelationDtos'],
  data() {
    this.goodsBuyType = this.$route.query.goodsBuyType; // 是否组合商品

    const trim = str => typeof str === 'string' ? str.replace(/^\s+|\s+$/g, '') : str;
    const validName = (rule, value, callback) => {
      const noCode = this.regionCode.length < 2;
      const noName = !trim(this.ruleForm.productName);
      const noDesc = !trim(this.ruleForm.extraDesc);

      if (noCode && noName && noDesc) callback(new Error('请填写商品名称'));
      else if (noCode) callback(new Error('请选择区域'));
      else if (noName) callback(new Error('请输入产品名词'));
      else if (noDesc) callback(new Error('请输入补充说明'));
      else callback();
    };


    return {
      ruleForm: {
        name: '',
        trait: '',
        coverImageList: [], // 封面
        imageUrlList: [], // 商品图片
        videoUrl: '',
        address: '',
        longitude: '',
        latitude: '',
        productName: '',
        extraDesc: '',
      },
      shopList: [],
      rules: {
        name: [
          { required: true, message: '请输入商品名称', trigger: 'change' },
        ],
        trait: [
          { required: true, message: '请输入商品卖点', trigger: 'change' },
        ],
        address: [{ required: true, message: '请输入地址', trigger: 'change' }],
        longitude: [
          { required: true, message: '请输入经度', trigger: 'change' },
        ],
        latitude: [
          { required: true, message: '请输入纬度', trigger: 'change' },
        ],
        threeInput: [
          {required: true, validator: validName, trigger: 'blur'}
        ]
      },
      pageType: this.$route.query.type, // 页面类型
      goodsType: this.$route.query.goodsType, // 类型

      regionCode: [],
      regionCodeStr: '',
      cascaderProps: {
        expandTrigger: 'hover',
        lazy: true,
        checkStrictly: true,
        lazyLoad: async (node, resolve) => {
          const {level, value} = node;
          if (level === 0) {
            const shegns = await this.getShengs();
            resolve(shegns);
          } else if (level < 3) {
            const nexts = await this.getNextLevel(value, level === 2);
            resolve(nexts);
          } else {
            resolve();
          }
        }
      }
    };
  },
  computed: {
    nameSuggestion() {
      let result = '';
      switch(this.goodsType) {
        case '1': result = '建议：产品生产所属地+产品名称+补充说明（规格/产品优势），例：桐庐酒酿馒头香甜600g*2包'; break;
        case '2': result = '建议：产品生产所属地+产品名称+补充说明（产品优势），例：余杭径山抹香阁民宿体验禅茶文化'; break;
        case '5': 
        case '6': result = '建议：产品生产所属地+产品名称+补充说明（规格/产品优势），例：桐庐酒酿馒头香甜600g*2包、松阳杨梅采摘游玩'; break;
        case '7': result = '建议：产品生产所属地+产品名称+补充说明（规格/产品优势），例：桐庐老赵养蜂场蜂箱野生土蜂蜜'; break;
        case '8': result = '建议：产品生产所属地+产品名称+补充说明（产品优势），例：海宁名都观潮套餐精品优选'; break;
        case '9': result = '建议：产品生产所属地+产品名称+补充说明（产品优势），例：松阳杨梅采摘游玩'; break;
        default: result = '建议：商品名称+规格，例：梅饴馆黑糖老梅干（典藏系列）55g装*6盒';
      }
      return result;
    }
  },
  watch: {
    tradeGoodsDto(row) {
      if (this.pageType == 'edit' || this.pageType == 'show') {
        this.$nextTick(() => {
          if (row.provinceRegionCode) this.$set(this.regionCode, 0, row.provinceRegionCode);
          if (row.cityRegionCode) this.$set(this.regionCode, 1, row.cityRegionCode);
          if (row.districtRegionCode) this.$set(this.regionCode, 2, row.districtRegionCode);

          this.regionCodeStr = row.districtRegionName || row.cityRegionName || row.provinceRegionName;
          
          this.ruleForm = {
            ...row,
            imageUrlList: row.imageUrls ? row.imageUrls.split(',') : [],
            coverImageList: row.coverImgUrl ? [row.coverImgUrl] : [],
          };
        }, 50);
      }
    },
    tradeShopGoodsRelationDtos(row) {
      if (row) {
        this.shopList = row;
      }
    },
  },
  methods: {
    changeCascader() {
      const selects = this.$refs.cascaderRef.getCheckedNodes();
      this.regionCodeStr = selects[0]?.label || '';
    },
    getShengs() {
      return new Promise(async resolve => {
        this.$axios.get(this.$api.provinceListApi).then((res) => {
          if (res.code === 0) {
            const list = res.data || [];
            resolve(list.map(item => ({label: item.regionName, value: item.regionCode, leaf: false, disabled: true})));
          }
        })
        .catch(() => {});
      });
    },
    getNextLevel(regionCode, isEnd) {
      return new Promise(async resolve => {
        this.$axios.get(this.$api.regionShortNameApi, {params: {regionCode}}).then((res) => {
          if (res.code === 0) {
            const list = res.data || [];
            resolve(list.map(item => ({label: item.regionShortName, value: item.regionCode, leaf: isEnd})));
          }
        })
        .catch(() => {});
      });
    },
    getMapData(row) {
      this.ruleForm.address = row.name;
      this.ruleForm.longitude = row.lng;
      this.ruleForm.latitude = row.lat;
    },
    changeHomeShop(list) {
      this.shopList = list.map((item) => {
        return {
          name: item.name,
          shopId: item.id,
          goodsId: this.$route.query.goodsId ? this.$route.query.goodsId : '',
        };
      });
      // this.ruleForm.shopId = item.id;
      // this.ruleForm.shopName = item.name;
    },
    getForm() {
      // 完整验证
      return new Promise((resolve) => {
        this.$refs.ruleForm.validate((valid) => {
          if (valid) {
            if (this.ruleForm.coverImageList.length === 0) {
              this.$message({
                type: 'error',
                message: '请上传商品封面图片（商品基本信息）',
              });
              return;
            }
            if (this.ruleForm.imageUrlList.length === 0) {
              this.$message({
                type: 'error',
                message: '请上传商品图片（商品基本信息）',
              });
              return;
            }
            if (!this.shopList.length && this.isNeedAppendShop()) {
              this.$message({
                type: 'error',
                message: '请关联门店（商品基本信息）',
              });
              return;
            }

            const [provinceRegionCode, cityRegionCode, districtRegionCode] = this.regionCode;

            resolve({
              baseResolve: {
                ...this.ruleForm,
                imageUrls: this.ruleForm.imageUrlList.join(','),
                coverImgUrl: this.ruleForm.coverImageList[0],
                ancestryCategoryId: this.$route.query.goodsType,
                backendCategoryId: this.$route.query.backendCategoryId,
                storeId: this.$route.query.storeId,
                goodsType: this.$route.query.goodsBuyType, // 是否组合商品
                provinceRegionCode,
                cityRegionCode,
                districtRegionCode
              },
              shopResolve: this.shopList,
            });
          } else {
            if (!this.ruleForm.name) {
              this.$message({
                type: 'error',
                message: '请输入商品名称（商品基本信息）',
              });
              return;
            }
            if (!this.ruleForm.trait) {
              this.$message({
                type: 'error',
                message: '请输入商品卖点（商品基本信息）',
              });
            }
          }
        });
      });
    },
    // 视屏
    changeVideo(e) {
      this.ruleForm.videoUrl = e;
      console.log(this.ruleForm.videoUrl, 99999);
    },
    // 是否需要关联门店
    isNeedAppendShop() {
      return [2, 5, 6, 9].includes(+this.goodsType);
    },
  },
};
</script>

<style lang="scss">
.video-wrap {
  padding-left: 0px;
  .video-box {
    overflow: hidden;
    padding: 0;
    margin: 0;
    .mvGo {
      width: 100px;
      height: 100px;
      float: left;
      border: 1px dashed #c0ccda;
      position: relative;
      text-align: center;
      line-height: 100px;
      border-radius: 4px;
      list-style-type: none;
      input[type="file"] {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 1;
        opacity: 0;
        cursor: pointer;
      }
    }
    .haveVd {
      width: 100px;
      height: 100px;
      border: 1px solid #c0ccda;
      position: relative;
      text-align: center;
      line-height: 100px;
      border-radius: 4px;
      background: #898989;
      overflow: hidden;
      list-style-type: none;
      float: left;
      span {
        color: #fff;
      }
      button {
        position: absolute;
        bottom: 0;
        left: 0;
        background: #bfcbd9;
        cursor: pointer;
        height: 20px;
        width: 100%;
        color: #fff;
        z-index: 10;
      }
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
.base-three-input{
  .width450{
    width: 300px;
  }
  .product-name{
    margin: 0 10px;
  }
}
.base-select-area{
  &.is-disabled{
    .el-input__inner{
      background-color: transparent;
      color: inherit;
    }
  }
}
</style>
