var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "showAttr" },
    _vm._l(_vm.inputList, function (item, i) {
      return _c(
        "el-form-item",
        {
          key: i,
          attrs: {
            label: item.name + "：",
            required: item.isRequire == 1 ? true : false,
          },
        },
        [
          item.valueType == "1"
            ? _c(
                "el-input",
                {
                  attrs: {
                    size: "mini",
                    "show-word-limit": "",
                    placeholder: "请输入" + item.name,
                    readonly: _vm.disabled,
                    disabled: item.status === 0,
                  },
                  on: {
                    input: function ($event) {
                      return _vm.onInputUnit(item.id, item.tradeUnitDto)
                    },
                  },
                  model: {
                    value: _vm.childreanRuleForm[item.id],
                    callback: function ($$v) {
                      _vm.$set(_vm.childreanRuleForm, item.id, $$v)
                    },
                    expression: "childreanRuleForm[item.id]",
                  },
                },
                [
                  item.unitName
                    ? _c(
                        "span",
                        { attrs: { slot: "append" }, slot: "append" },
                        [_vm._v(" " + _vm._s(item.unitName) + " ")]
                      )
                    : _vm._e(),
                  item.unitName && item.belongType == 2 && !_vm.disabled
                    ? _c("div", {
                        staticStyle: {
                          display: "inline-block",
                          margin: "0 10px",
                          width: "1px",
                          background: "#ccc",
                          "margin-right": "10px",
                          height: "10px",
                        },
                        attrs: { slot: "append" },
                        slot: "append",
                      })
                    : _vm._e(),
                  item.belongType == 2 && !_vm.disabled
                    ? _c(
                        "span",
                        { attrs: { slot: "append" }, slot: "append" },
                        [
                          _c("el-button", {
                            attrs: { icon: "el-icon-edit", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.editAttr(
                                  item,
                                  _vm.childreanRuleForm[item.id]
                                )
                              },
                            },
                          }),
                          _c("el-button", {
                            attrs: { icon: "el-icon-delete", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.deteleAttr(item)
                              },
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              )
            : _vm._e(),
          item.valueType == "2"
            ? _c(
                "el-radio-group",
                {
                  attrs: { size: "mini", disabled: _vm.disabled },
                  model: {
                    value: _vm.childreanRuleForm[item.id],
                    callback: function ($$v) {
                      _vm.$set(_vm.childreanRuleForm, item.id, $$v)
                    },
                    expression: "childreanRuleForm[item.id]",
                  },
                },
                _vm._l(item.tradeAttributeValueVoList, function (value, index) {
                  return _c("el-radio", {
                    key: index,
                    attrs: { label: value.value },
                  })
                }),
                1
              )
            : _vm._e(),
          item.valueType == "3"
            ? _c(
                "el-checkbox-group",
                {
                  attrs: { size: "mini", disabled: _vm.disabled },
                  model: {
                    value: _vm.childreanRuleForm[item.id],
                    callback: function ($$v) {
                      _vm.$set(_vm.childreanRuleForm, item.id, $$v)
                    },
                    expression: "childreanRuleForm[item.id]",
                  },
                },
                _vm._l(item.tradeAttributeValueVoList, function (value, index) {
                  return _c("el-checkbox", {
                    key: index,
                    attrs: { label: value.value },
                  })
                }),
                1
              )
            : _vm._e(),
          item.valueType == "4"
            ? _c(
                "el-select",
                {
                  attrs: {
                    size: "mini",
                    disabled: _vm.disabled,
                    placeholder: "请选择" + item.name,
                    clearable: "",
                  },
                  model: {
                    value: _vm.childreanRuleForm[item.id],
                    callback: function ($$v) {
                      _vm.$set(_vm.childreanRuleForm, item.id, $$v)
                    },
                    expression: "childreanRuleForm[item.id]",
                  },
                },
                _vm._l(item.tradeAttributeValueVoList, function (value, index) {
                  return _c("el-option", {
                    key: index,
                    attrs: { label: value.value, value: value.id },
                  })
                }),
                1
              )
            : _vm._e(),
          item.valueType == "5"
            ? _c("el-input", {
                attrs: { size: "mini", readonly: "" },
                model: {
                  value: _vm.childreanRuleForm[item.id],
                  callback: function ($$v) {
                    _vm.$set(_vm.childreanRuleForm, item.id, $$v)
                  },
                  expression: "childreanRuleForm[item.id]",
                },
              })
            : _vm._e(),
          item.valueType == "6"
            ? _c("el-input", {
                staticClass: "my_textarea",
                attrs: {
                  type: "textarea",
                  size: "mini",
                  autosize: { minRows: 2, maxRows: 4 },
                  maxlength: "1000",
                  "show-word-limit": "",
                  placeholder: "请输入" + item.name,
                  readonly: _vm.disabled,
                },
                model: {
                  value: _vm.childreanRuleForm[item.id],
                  callback: function ($$v) {
                    _vm.$set(_vm.childreanRuleForm, item.id, $$v)
                  },
                  expression: "childreanRuleForm[item.id]",
                },
              })
            : _vm._e(),
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }