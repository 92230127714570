var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "证明管理",
        visible: _vm.show,
        width: "600px",
        "before-close": _setup.clearDialog,
        "append-to-body": "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.show = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "ruleFormRef",
          attrs: { model: _setup.ruleForm, "label-width": "100px" },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "品牌信息" } },
            [
              _setup.ruleForm.brandLogo
                ? _c("el-image", {
                    staticClass: "image",
                    attrs: {
                      src: _setup.ruleForm.brandLogo,
                      "preview-src-list": [_setup.ruleForm.brandLogo],
                      fit: "contain",
                    },
                  })
                : _vm._e(),
              _c("span", [_vm._v(_vm._s(_setup.ruleForm.brandName))]),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "认证编号",
                prop: "authNo",
                rules: [
                  {
                    required: true,
                    message: "请输入认证编号",
                    trigger: "blur",
                  },
                ],
              },
            },
            [
              _c("el-input", {
                staticClass: "w400",
                attrs: {
                  disabled: _vm.disabled,
                  maxlength: "20",
                  "show-word-limit": "",
                  placeholder: "请输入认证编号",
                  size: "small",
                },
                model: {
                  value: _setup.ruleForm.authNo,
                  callback: function ($$v) {
                    _vm.$set(_setup.ruleForm, "authNo", $$v)
                  },
                  expression: "ruleForm.authNo",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "有效期",
                prop: "timeList",
                rules: [
                  {
                    type: "array",
                    required: true,
                    message: "请选择有效时间",
                    trigger: "change",
                  },
                ],
              },
            },
            [
              _c("el-date-picker", {
                attrs: {
                  disabled: _vm.disabled,
                  type: "daterange",
                  "range-separator": "至",
                  "start-placeholder": "开始日期",
                  "end-placeholder": "结束日期",
                  "value-format": "timestamp",
                  size: "small",
                  "default-time": ["00:00:00", "23:59:59"],
                },
                model: {
                  value: _setup.ruleForm.timeList,
                  callback: function ($$v) {
                    _vm.$set(_setup.ruleForm, "timeList", $$v)
                  },
                  expression: "ruleForm.timeList",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "证明图片",
                prop: "imageList",
                rules: [
                  {
                    type: "array",
                    required: true,
                    message: "请上传图片",
                    trigger: "change",
                  },
                ],
              },
            },
            [
              _c(_setup.UpImage, {
                attrs: {
                  disabled: _vm.disabled,
                  "image-list": _setup.ruleForm.imageList,
                  num: 5,
                },
              }),
              _c("div", [
                _vm._v(" 建议：800px*800px，2M以内；图片可拖动排序；限5张。 "),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            { attrs: { size: "small" }, on: { click: _setup.clearDialog } },
            [_vm._v("取 消")]
          ),
          !_vm.disabled
            ? _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "small" },
                  on: { click: _setup.onSubmit },
                },
                [_vm._v("确 定")]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }