// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/.pnpm/css-loader@3.6.0_webpack@4.47.0/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/* Variables */\n\n/* Mixins */\n.video-wrap {\n  padding-left: 0px;\n}\n.video-wrap .video-box {\n  overflow: hidden;\n  padding: 0;\n  margin: 0;\n}\n.video-wrap .video-box .mvGo {\n  width: 100px;\n  height: 100px;\n  float: left;\n  border: 1px dashed #c0ccda;\n  position: relative;\n  text-align: center;\n  line-height: 100px;\n  border-radius: 4px;\n  list-style-type: none;\n}\n.video-wrap .video-box .mvGo input[type=file] {\n  width: 100%;\n  height: 100%;\n  position: absolute;\n  left: 0;\n  top: 0;\n  z-index: 1;\n  opacity: 0;\n  cursor: pointer;\n}\n.video-wrap .video-box .haveVd {\n  width: 100px;\n  height: 100px;\n  border: 1px solid #c0ccda;\n  position: relative;\n  text-align: center;\n  line-height: 100px;\n  border-radius: 4px;\n  background: #898989;\n  overflow: hidden;\n  list-style-type: none;\n  float: left;\n}\n.video-wrap .video-box .haveVd span {\n  color: #fff;\n}\n.video-wrap .video-box .haveVd button {\n  position: absolute;\n  bottom: 0;\n  left: 0;\n  background: #bfcbd9;\n  cursor: pointer;\n  height: 20px;\n  width: 100%;\n  color: #fff;\n  z-index: 10;\n}\n.video-wrap .video-box .haveVd img {\n  width: 100%;\n  height: 100%;\n}\n.base-three-input .width450 {\n  width: 300px;\n}\n.base-three-input .product-name {\n  margin: 0 10px;\n}\n.base-select-area.is-disabled .el-input__inner {\n  background-color: transparent;\n  color: inherit;\n}", ""]);
// Exports
exports.locals = {
	"theme": "#30B08F",
	"menuBg": "#304156",
	"menuText": "#bfcbd9",
	"menuActiveText": "#409EFF"
};
module.exports = exports;
