<!-- 发货时间 -->
<script setup>
import { reactive } from 'vue';

const props = defineProps({
  freightTime: {
    type: Object,
    default: () => {},
    required: true,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});

const freightTime = reactive(props.freightTime);
</script>

<template>
  <div class="">
    <h1 class="add_goods_title">
      发货时间
    </h1>
    <el-form
      label-width="110px"
    >
      <el-form-item
        label="发货时间："
        required
      >
        <el-radio-group v-model="freightTime.deliveryTimeType">
          <el-radio :label="0">
            24小时发货
          </el-radio>
          <el-radio :label="1">
            48小时发货
          </el-radio>
          <el-radio :label="2">
            大于48小时发货
            <el-input-number
              v-model="freightTime.deliveryDays"
              class="margin-l20 margin-r10"
              size="mini"
              :precision="0"
              :controls="false"
              :min="3"
              :max="90"
              placeholder="3~90天"
            />天内发货
          </el-radio>
        </el-radio-group>
      </el-form-item>
    </el-form>
  </div>
</template>

<style scoped lang="scss">

</style>
