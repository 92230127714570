var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {},
    [
      _c(
        "h1",
        { staticClass: "title" },
        [
          _vm._v(" 售卖时间 "),
          _c(
            "el-tooltip",
            {
              staticClass: "item",
              attrs: {
                effect: "dark",
                content:
                  "售卖时间：商品上架期间仅允许消费者在售卖时间期间购买。",
                placement: "right",
              },
            },
            [
              _c("i", {
                staticClass: "el-icon-warning-outline",
                staticStyle: { "font-size": "14px", "margin-left": "5px" },
              }),
            ]
          ),
        ],
        1
      ),
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "el-radio-group",
            {
              model: {
                value: _vm.radio,
                callback: function ($$v) {
                  _vm.radio = $$v
                },
                expression: "radio",
              },
            },
            [
              _c(
                "div",
                { staticClass: "box-card_radio" },
                [
                  _c(
                    "el-radio",
                    {
                      class:
                        _vm.radio === 0 ? "goods-sell-time-model-box-card" : "",
                      attrs: {
                        label: 0,
                        disabled: _vm.disabled && _vm.radio !== 0,
                      },
                    },
                    [_vm._v(" 上架期间均正常售卖 ")]
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "box-card_radio" },
                [
                  _c(
                    "el-radio",
                    {
                      class:
                        _vm.radio === 1 ? "goods-sell-time-model-box-card" : "",
                      attrs: {
                        label: 1,
                        disabled: _vm.disabled && _vm.radio !== 1,
                      },
                    },
                    [
                      _vm._v(" 指定时间区间售卖 "),
                      _c(
                        "el-tooltip",
                        {
                          staticClass: "item",
                          attrs: {
                            effect: "dark",
                            content:
                              "例如需要在 1月1日 10点 至 2月1日20点 可售，日期设置：1月1日 10:00 -2月1日 20:00",
                            placement: "right",
                          },
                        },
                        [
                          _c("i", {
                            staticClass: "el-icon-warning-outline",
                            staticStyle: {
                              "font-size": "14px",
                              "margin-left": "5px",
                            },
                          }),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm.radio === 1
                    ? _c(
                        "div",
                        { staticStyle: { "margin-top": "10px" } },
                        [
                          _c("el-date-picker", {
                            attrs: {
                              readonly: _vm.disabled,
                              type: "datetimerange",
                              "range-separator": "至",
                              "start-placeholder": "开始日期",
                              "end-placeholder": "结束日期",
                              size: "small",
                              format: "yyyy-MM-dd HH:mm",
                              "value-format": "yyyy-MM-dd HH:mm",
                            },
                            model: {
                              value: _vm.dayTimeSection,
                              callback: function ($$v) {
                                _vm.dayTimeSection = $$v
                              },
                              expression: "dayTimeSection",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "div",
                {},
                [
                  _c(
                    "el-radio",
                    {
                      class:
                        _vm.radio === 2 ? "goods-sell-time-model-box-card" : "",
                      attrs: {
                        label: 2,
                        disabled: _vm.disabled && _vm.radio !== 2,
                      },
                    },
                    [
                      _vm._v(" 指定时间段内固定时间区间售卖 "),
                      _c(
                        "el-tooltip",
                        {
                          staticClass: "item",
                          attrs: {
                            effect: "dark",
                            content:
                              "例如需要在1月1日至2月1日的每天10点至20点可售，日期设置：1月1日-2月1日，时间设置：10:00-20:00",
                            placement: "right",
                          },
                        },
                        [
                          _c("i", {
                            staticClass: "el-icon-warning-outline",
                            staticStyle: {
                              "font-size": "14px",
                              "margin-left": "5px",
                            },
                          }),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm.radio === 2
                    ? _c(
                        "div",
                        { staticStyle: { "margin-top": "10px" } },
                        [
                          _c("span", { staticStyle: { "font-size": "16px" } }, [
                            _vm._v("日期： "),
                          ]),
                          _c("el-date-picker", {
                            staticStyle: { "margin-right": "10px" },
                            attrs: {
                              readonly: _vm.disabled,
                              type: "daterange",
                              "range-separator": "至",
                              "start-placeholder": "开始日期",
                              "end-placeholder": "结束日期",
                              size: "small",
                              "value-format": "yyyy-MM-dd",
                            },
                            model: {
                              value: _vm.daySection,
                              callback: function ($$v) {
                                _vm.daySection = $$v
                              },
                              expression: "daySection",
                            },
                          }),
                          _c("span", { staticStyle: { "font-size": "16px" } }, [
                            _vm._v("时间： "),
                          ]),
                          _c("el-time-picker", {
                            attrs: {
                              "is-range": "",
                              readonly: _vm.disabled,
                              "range-separator": "至",
                              "start-placeholder": "开始时间",
                              "end-placeholder": "结束时间",
                              placeholder: "选择时间范围",
                              size: "small",
                              format: "HH:mm",
                              "value-format": "HH:mm",
                            },
                            model: {
                              value: _vm.timeSection,
                              callback: function ($$v) {
                                _vm.timeSection = $$v
                              },
                              expression: "timeSection",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }