var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "品牌详情",
        visible: _vm.show,
        width: "600px",
        "before-close": _setup.clearDialog,
        "append-to-body": "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.show = $event
        },
        open: _setup.open,
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "ruleFormRef",
          attrs: { model: _setup.ruleForm, "label-width": "100px" },
        },
        [
          _c(
            "el-form-item",
            {
              attrs: {
                label: "品牌Logo",
                prop: "imageList",
                rules: [
                  {
                    type: "array",
                    required: true,
                    message: "请上传品牌logo",
                    trigger: "change",
                  },
                ],
              },
            },
            [
              _c(_setup.UpImage, {
                attrs: {
                  "image-list": _setup.ruleForm.imageList,
                  num: 1,
                  disabled: _vm.disabled,
                },
                on: {
                  "update:imageList": function ($event) {
                    return _vm.$set(_setup.ruleForm, "imageList", $event)
                  },
                  "update:image-list": function ($event) {
                    return _vm.$set(_setup.ruleForm, "imageList", $event)
                  },
                },
              }),
              _c("div", [
                _vm._v(" （Logo图建议：200px*200px 1M以内 JPG/PNG） "),
              ]),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "品牌名称",
                prop: "brandName",
                rules: [
                  {
                    required: true,
                    message: "请输入品牌名称",
                    trigger: "blur",
                  },
                ],
              },
            },
            [
              _c("el-input", {
                staticClass: "w400",
                attrs: {
                  disabled: _vm.disabled,
                  maxlength: "15",
                  "show-word-limit": "",
                  placeholder: "请输入15字以内品牌名称",
                  size: "small",
                },
                model: {
                  value: _setup.ruleForm.brandName,
                  callback: function ($$v) {
                    _vm.$set(_setup.ruleForm, "brandName", $$v)
                  },
                  expression: "ruleForm.brandName",
                },
              }),
            ],
            1
          ),
          _c(_setup.CommonForm, {
            ref: "CommonFormRef",
            attrs: { form: _setup.commonBrand, disabled: _vm.disabled },
            on: { change: _setup.changeCommon },
          }),
        ],
        1
      ),
      _c(
        "span",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            { attrs: { size: "small" }, on: { click: _setup.clearDialog } },
            [_vm._v("取 消")]
          ),
          !_vm.disabled
            ? _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "small" },
                  on: { click: _setup.onSubmit },
                },
                [_vm._v("确 定")]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }