var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "el-form",
    {
      ref: "ruleFormRef",
      attrs: { model: _setup.ruleForm, "label-width": "100px" },
    },
    [
      _c(
        "el-form-item",
        { staticClass: "margin-b20", attrs: { label: "入选公用品牌" } },
        [
          _c(
            "div",
            { staticClass: "flex wd-flex-align-center" },
            [
              _setup.brandImg
                ? _c("img", {
                    staticClass: "brand-img",
                    attrs: { src: _setup.brandImg, alt: "" },
                  })
                : _vm._e(),
              _c(
                "el-select",
                {
                  staticClass: "w300",
                  attrs: {
                    placeholder: "请选公用品牌",
                    size: "small",
                    clearable: "",
                    disabled: _vm.disabled,
                  },
                  model: {
                    value: _setup.ruleForm.brandId,
                    callback: function ($$v) {
                      _vm.$set(_setup.ruleForm, "brandId", $$v)
                    },
                    expression: "ruleForm.brandId",
                  },
                },
                _vm._l(_setup.brandList, function (item, i) {
                  return _c(
                    "el-option",
                    {
                      key: i,
                      attrs: { label: item.brandName, value: item.id },
                    },
                    [
                      _c("div", { staticClass: "flex" }, [
                        _c("img", {
                          staticClass: "brand-img",
                          attrs: { src: item.brandLogo, alt: "" },
                        }),
                        _c("span", [_vm._v(_vm._s(item.brandName))]),
                      ]),
                    ]
                  )
                }),
                1
              ),
            ],
            1
          ),
        ]
      ),
      _setup.ruleForm.brandId
        ? [
            _c(
              "el-form-item",
              {
                staticClass: "margin-b20",
                attrs: {
                  label: "证明图片",
                  prop: "imageList",
                  rules: [
                    {
                      type: "array",
                      required: true,
                      message: "请上传图片类型",
                      trigger: "change",
                    },
                  ],
                },
              },
              [
                _c(_setup.UpImage, {
                  attrs: {
                    disabled: _vm.disabled,
                    "image-list": _setup.ruleForm.imageList,
                    num: 5,
                  },
                  on: {
                    "update:imageList": function ($event) {
                      return _vm.$set(_setup.ruleForm, "imageList", $event)
                    },
                    "update:image-list": function ($event) {
                      return _vm.$set(_setup.ruleForm, "imageList", $event)
                    },
                  },
                }),
                _c("div", [
                  _vm._v(
                    " 建议：800px*800px，2M以内；图片可拖动排序；限5张。 "
                  ),
                ]),
              ],
              1
            ),
            _c(
              "el-form-item",
              {
                staticClass: "margin-b20",
                attrs: {
                  label: "有效期",
                  prop: "timeList",
                  rules: [
                    {
                      type: "array",
                      required: true,
                      message: "请选择有效时间",
                      trigger: "change",
                    },
                  ],
                },
              },
              [
                _c("el-date-picker", {
                  attrs: {
                    disabled: _vm.disabled,
                    type: "daterange",
                    "range-separator": "至",
                    "start-placeholder": "开始日期",
                    "end-placeholder": "结束日期",
                    "value-format": "timestamp",
                    size: "small",
                    "default-time": ["00:00:00", "23:59:59"],
                  },
                  model: {
                    value: _setup.ruleForm.timeList,
                    callback: function ($$v) {
                      _vm.$set(_setup.ruleForm, "timeList", $$v)
                    },
                    expression: "ruleForm.timeList",
                  },
                }),
              ],
              1
            ),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }