var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { "margin-bottom": "70px" } },
    [
      _c("h1", { staticClass: "title" }, [_vm._v(" 发货次数 ")]),
      _c(
        "el-form",
        {
          ref: "ruleForm",
          staticClass: "demo-ruleForm",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            "label-width": "120px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "固定发货次数：", prop: "shipments" } },
            [
              _c("el-input-number", {
                staticStyle: { width: "150px" },
                attrs: {
                  size: "mini",
                  min: 1,
                  max: 999,
                  readonly: _vm.disabled,
                },
                on: { blur: _vm.changeNum, change: _vm.onChange },
                model: {
                  value: _vm.ruleForm.shipments,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "shipments", $$v)
                  },
                  expression: "ruleForm.shipments",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }